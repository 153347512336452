import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SignupService } from '../../services/signup.service';
import { ToastService } from '../../services/toast.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/confirmDialog/confirmDialog.component';
import { Signup } from 'src/app/models/signup.model';
import { AuthService } from 'src/app/services';
import { TimeZoneService } from 'src/app/services/timeZone.service';
import { GridBaseComponent } from 'src/app/shared/grid/grid.component';

@Component({
    selector: 'app-pre-signup-user-list',
    templateUrl: './preSignupUserList.component.html',
    styleUrls: ['./preSignupUserList.component.css']
})
export class PreSignupUserListComponent extends GridBaseComponent<Signup> implements OnInit, AfterViewInit {

    constructor(protected signupService: SignupService,
        toastService: ToastService,
        authService: AuthService,
        timeZoneService: TimeZoneService,
        dialog: MatDialog
    ) {
        super(toastService, authService, timeZoneService, dialog);
        this.gridService = signupService;
        this.displayedColumns = ['id', 'status', 'firstName', 'lastName', 'emailId', 'companyName', 'mobileNumber', 'action'];
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    approveUser(selectedUser: any) {
        this.isLoading = true;
        this.signupService.approveUser(selectedUser.id)
            .subscribe(
                response => {
                    this.toastService.showSuccess('User account has been setup successfully!');
                    this.isLoading = false;
                    this.loadPage();
                },
                error => {
                    console.log(error);
                    this.toastService.showError(error, 'User account setup failed.');
                    this.isLoading = false;
                });
    }

    declineUser(selectedUser: any) {
        this.openDeleteDialog(
            ConfirmDialogComponent,
            {
                title: 'Decline Signup Request',
                message: `Are you sure you want to decline signup request from ${selectedUser.emailId}?`,
                primaryButtonText: 'Decline',
                primaryCallback: () => this.signupService.declineUser(selectedUser.id),
                successMessage: 'Signup request declined successfully!',
                secondaryButtonText: 'Cancel'
            }
        );
    }
}
