export class SensorRecord {
    timestamp: number;
    dateTime: Date;
    deviceId: string;
    statusId: number;
    hum: number;
    lat: number;
    lon: number;
    pm1: number;
    pm2_5: number;
    pm10: number;
    rssi: number;
    tempC: number;
    hdop: number;
    endPointName: string;
    deviceName: string;
    respirablePm: number;
    lavg: number;
    ozone: number;
    nO2: number;
    co: number;
    sO2: number;
    tvoc: number;
    cO2: number;
}
