import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DeviceShadowService } from 'src/app/services/deviceShadow.service';
import { ToastService } from 'src/app/services/toast.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviceShadow } from 'src/app/models/deviceShadow.model';
import { FieldTypeEnum } from 'src/app/enums/fieldType.enum';
import { FieldStatusEnum } from 'src/app/enums/fieldStatus.enum';
import { AuthService } from 'src/app/services';
import { SecurityProfileEnum } from 'src/app/enums/securityProfile.enum';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { ErrorCodeEnum } from 'src/app/enums/errorCode.enum';

export interface ConfigureSensorDialogData {
    id: string;
}

@Component({
    selector: 'app-configure-sensor-dialog',
    templateUrl: 'configureSensorDialog.component.html',
    styleUrls: ['configureSensorDialog.component.css']
})
export class ConfigureSensorDialogComponent extends TrackableComponent implements OnInit, OnDestroy {

    isFormLoading = false;
    isLoading = false;
    deviceShadow: DeviceShadow;
    isSuperAdmin = false;
    FieldTypeEnum = FieldTypeEnum;
    FieldStatusEnum = FieldStatusEnum;

    constructor(public dialogRef: MatDialogRef<ConfigureSensorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfigureSensorDialogData,
        private deviceShadowService: DeviceShadowService,
        private authService: AuthService,
        private toastService: ToastService,
        featureUsageService: FeatureUsageService) {

        super(featureUsageService, FeatureUsagePageEnum.ConfigureSensorDialog);

        this.deviceShadow = new DeviceShadow();
        this.isSuperAdmin = this.authService.getCurrentUser().userType === SecurityProfileEnum.SuperAdmin;
    }

    ngOnInit() {
        this.isFormLoading = true;
        this.deviceShadowService.getDeviceShadow(this.data.id)
            .subscribe(
                result => {
                    this.deviceShadow = result;
                    this.isFormLoading = false;
                },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isFormLoading = false;
                });
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    save() {
        if (this.validate() === false) {
            return;
        }
        this.isLoading = true;
        this.deviceShadowService.updateDeviceShadow(this.deviceShadow)
            .subscribe(
                result => {
                    this.toastService.showSuccess('Device configuration saved successfully.');
                    this.isLoading = false;
                    this.dialogRef.close();
                }, error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isLoading = false;
                });

        this.recordAction(FeatureUsageActionEnum.UpdateSensorConfigurationClick);
    }

    validate(): boolean {
        let valid = true;
        this.deviceShadow.fields.forEach(field => {
            if ((field.fieldTypeId === FieldTypeEnum.Float || field.fieldTypeId === FieldTypeEnum.Integer)
                && Number.isNaN(Number(field.value))) {
                valid = false;
                const message = `Invalid number value in field "${field.name}".`;
                this.toastService.showError(message);
                this.recordError(ErrorCodeEnum.Validation, 'Validation error, ' + message);
            }
        });
        return valid;
    }

    onClose() {
        this.recordAction(FeatureUsageActionEnum.CancelUpdateSensorConfigurationClick);
    }
}
