<body class="dashboard dashboard-bg">
    <app-header></app-header>

    <div class="body-container collapsed">
        <app-sidebar></app-sidebar>

        <div class="body-content">
            <div class="row">
                <div class="col-md-8 p-0">
                    <app-page-header title="Notification Settings" iconClass="fas fa-bell"></app-page-header>
                </div>

                <div class="col-md-4 p-0">
                    <div class="text-right grid-button-container">
                        <button type="button" class="btn button-primary" mat-button (click)="add()">
                            Create Notification
                        </button>
                    </div>
                </div>
            </div>

            <app-progress-spinner [show]="isLoading || (dataSource.isLoading$ | async)"></app-progress-spinner>

            <div class="white-box">
                <mat-form-field>
                    <mat-label>Search</mat-label>
                    <input matInput #searchField />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-search"></i>
                    </mat-icon>
                </mat-form-field>

                <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc"
                    matSortDisableClear>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                        <td mat-cell *matCellDef="let notificationSettings" [class.text-muted]="isTextMuted(notificationSettings)">{{notificationSettings.name}}</td>
                    </ng-container>

                    <ng-container matColumnDef="isEnabled">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Notification Status</th>
                        <td mat-cell *matCellDef="let notificationSettings" [class.text-muted]="isTextMuted(notificationSettings)">{{notificationSettings.isEnabled ? 'Active' : 'Inactive'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="parameterType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Parameter Type</th>
                        <td mat-cell *matCellDef="let notificationSettings" [class.text-muted]="isTextMuted(notificationSettings)">{{getParameterTypeName(notificationSettings.parameterType)}}</td>
                    </ng-container>

                    <ng-container matColumnDef="averagingType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Averaging Type</th>
                        <td mat-cell *matCellDef="let notificationSettings" [class.text-muted]="isTextMuted(notificationSettings)">{{getAveragingTypeName(notificationSettings.averagingType)}}</td>
                    </ng-container>

                    <ng-container matColumnDef="threshold">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Threshold</th>
                        <td mat-cell *matCellDef="let notificationSettings" [class.text-muted]="isTextMuted(notificationSettings)">{{notificationSettings.threshold}} [{{getParameterUnit(notificationSettings.parameterType)}}]</td>
                    </ng-container>

                    <ng-container matColumnDef="subscribed">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Subscribed</th>
                        <td mat-cell *matCellDef="let notificationSettings">
                            <mat-icon *ngIf="!isTextMuted(notificationSettings)">
                                <i class="fas fa-check-circle color-secondary"></i>
                            </mat-icon>
                            <mat-icon *ngIf="isTextMuted(notificationSettings)">
                                <i class="fas fa-minus-circle color-accent"></i>
                            </mat-icon>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th class="action-column" mat-header-cell *matHeaderCellDef>Action</th>
                        <td class="action-column" mat-cell *matCellDef="let notificationSettings">
                            <mat-icon class="action-icon color-primary-light" title="Assign" (click)="assign(notificationSettings)">
                                <i class="fas fa-user-plus"></i>
                            </mat-icon>

                            <mat-icon class="action-icon" title="Edit" (click)="edit(notificationSettings)">
                                <i class="fas fa-edit"></i>
                            </mat-icon>

                            <mat-icon class="action-icon" title="Clone" (click)="clone(notificationSettings)">
                                <i class="fas fa-clone"></i>
                            </mat-icon>

                            <mat-icon class="action-icon color-accent" title="Delete" (click)="delete(notificationSettings)">
                                <i class="fas fa-trash"></i>
                            </mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; let even = even; columns: displayedColumns;"
                        [class.even-row]="even"></tr>
                </table>

                <mat-paginator [length]="dataSource.count$ | async" [pageSizeOptions]="[5, 10, 20]" [pageSize]="10"
                    showFirstLastButtons></mat-paginator>
            </div>

            <div class="row">
                <div class="col-lg-3 offset-lg-9">
                    <mat-form-field>
                        <mat-label>Time Zone</mat-label>
                        <input matInput [(ngModel)]="timeZoneLabel" readonly />
                        <mat-icon matPrefix class="mr-3">
                            <i class="fas fa-globe"></i>
                        </mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div class="clearfix"></div>
            <app-footer></app-footer>
        </div>

        <div class="clearfix"></div>
    </div>
</body>
