import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services';
import { SecurityProfileEnum } from '../enums/securityProfile.enum';

@Injectable({
    providedIn: 'root'
})
export class AdminOrSuperAdminGuard implements CanActivate {

    constructor(private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authService.getCurrentUser();

        if (currentUser && (currentUser.userType === SecurityProfileEnum.Admin ||
            currentUser.userType === SecurityProfileEnum.SuperAdmin)) {
            return true;
        }

        return false;
    }
}
