<app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

<table class="table">
    <tbody>
        <tr>
            <td>Site Name</td>
            <td>{{observation?.siteName}}</td>
        </tr>
        <tr>
            <td>Date&Time</td>
            <td>{{observation?.utc | date:"medium"}}</td>
        </tr>
        <tr *ngFor="let o of observations">
            <td>{{o.parameter}} [{{o.unit}}]</td>
            <td>{{o.rawConcentration}}</td>
        </tr>
        <tr>
            <td>Latitude [°]</td>
            <td>{{observation?.latitude}}</td>
        </tr>
        <tr>
            <td>Longitude [°]</td>
            <td>{{observation?.longitude}}</td>
        </tr>
    </tbody>
</table>

<div align="end">
    <button class="btn button-primary" mat-button (click)="assign()" [class.button-spinner]="isLoading"
        [disabled]="isFormLoading || isLoading">
        Assign
    </button>
</div>
