import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { ChangeUserPassword } from 'src/app/models/changePassword.model';
import { ToastService } from 'src/app/services/toast.service';
import { Router } from '@angular/router';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';

@Component({
    selector: 'app-changeuserpassword',
    templateUrl: './changeuserpassword.component.html',
    styleUrls: ['./changeuserpassword.component.css']
})
export class ChangeuserpasswordComponent extends TrackableComponent implements OnInit, OnDestroy {

    isFormLoading: boolean;
    isLoading: boolean;
    userInfo: ChangeUserPassword;

    constructor(private accountService: AccountService,
        private router: Router,
        private toastService: ToastService,
        featureUsageService: FeatureUsageService) {

        super(featureUsageService, FeatureUsagePageEnum.ChangePasswordPage);

        this.isFormLoading = true;
        this.userInfo = new ChangeUserPassword();
    }

    ngOnInit() {
        this.isFormLoading = false;
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    cancel() {
        this.recordAction(FeatureUsageActionEnum.ChangePasswordCancelClick);
        this.router.navigate(['/dashboard']);
    }

    save() {
        this.isLoading = true;

        this.accountService.changeUserPassword(this.userInfo)
            .subscribe((response: any) => {
                this.toastService.showSuccess('Your password has been changed.');
                this.isLoading = false;
                location.reload();
            },
                error => {
                    console.log(error);
                    this.isLoading = false;
                    this.toastService.showError(error, 'Failed to update password. Please enter the correct old password.');
                });

        this.recordAction(FeatureUsageActionEnum.ChangePasswordSaveClick);
    }
}
