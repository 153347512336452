import { OnInit, Component, AfterViewInit } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { DeviceVersionService } from 'src/app/services/deviceVersionService';
import { MatDialog } from '@angular/material/dialog';
import { DeviceVersion } from 'src/app/models/deviceVersion.model';
import { CreateDeviceVersionDialogComponent } from '../createDeviceVersionDialog/createDeviceVersionDialog.component';
import { EditDeviceVersionDialogComponent } from '../editDeviceVersionDialog/editDeviceVersionDialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/confirmDialog/confirmDialog.component';
import { TimeZoneService } from 'src/app/services/timeZone.service';
import { AuthService } from 'src/app/services';
import { GridBaseComponent } from 'src/app/shared/grid/grid.component';

@Component({
    selector: 'app-device-version-list',
    templateUrl: 'deviceVersionList.component.html',
    styleUrls: ['deviceVersionList.component.css']
})
export class DeviceVersionListComponent extends GridBaseComponent<DeviceVersion> implements OnInit, AfterViewInit {

    constructor(protected deviceVersionService: DeviceVersionService,
        toastService: ToastService,
        authService: AuthService,
        timeZoneService: TimeZoneService,
        dialog: MatDialog
    ) {
        super(toastService, authService, timeZoneService, dialog);
        this.gridService = deviceVersionService;
        this.displayedColumns = ['version', 'action'];
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    add() {
        this.openDialog(CreateDeviceVersionDialogComponent, {});
    }

    edit(deviceVersion: DeviceVersion) {
        this.openDialog(EditDeviceVersionDialogComponent, { id: deviceVersion.id });
    }

    delete(deviceVersion: DeviceVersion) {
        this.openDeleteDialog(
            ConfirmDialogComponent,
            {
                title: 'Delete Device Version',
                message: `Are you sure you want to delete device version ${deviceVersion.version}?`,
                primaryButtonText: 'Delete',
                primaryCallback: () => this.deviceVersionService.delete(deviceVersion.id),
                successMessage: 'Device version deleted successfully!',
                secondaryButtonText: 'Cancel'
            }
        );
    }
}
