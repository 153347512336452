import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { SensorService } from 'src/app/services/sensor.service';
import { MatDialog } from '@angular/material/dialog';
import { EditSensorDialogComponent } from '../editSensorDialog/editSensorDialog.component';
import { Sensor, SensorModel } from 'src/app/models/sensor.model';
import { ConfigureSensorDialogComponent } from '../configureSensorDialog/configureSensorDialog.component';
import { AuthService } from 'src/app/services';
import { Router } from '@angular/router';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { TimeZoneService } from 'src/app/services/timeZone.service';
import { TrackableGridBaseComponent } from 'src/app/shared/grid/trackableGrid.component';

@Component({
    selector: 'app-sensor-list',
    templateUrl: './sensorList.component.html',
    styleUrls: ['./sensorList.component.css']
})
export class SensorListComponent extends TrackableGridBaseComponent<SensorModel> implements OnInit, AfterViewInit, OnDestroy {

    canUpdateDeviceShadow = false;

    constructor(protected sensorService: SensorService,
        toastService: ToastService,
        private authService: AuthService,
        timeZoneService: TimeZoneService,
        featureUsageService: FeatureUsageService,
        dialog: MatDialog,
        private router: Router
    ) {
        super(toastService, authService, timeZoneService, dialog, featureUsageService, FeatureUsagePageEnum.SensorsPage);
        this.gridService = sensorService;
        this.displayedColumns = ['name', 'id', 'action'];
    }

    ngOnInit() {
        this.canUpdateDeviceShadow = this.authService.getCurrentUser().tenantPermissions.canUpdateDeviceShadow;
        super.ngOnInit();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    setSensorId(sensor: Sensor) {
        const encSensorId = window.btoa(sensor.id);
        localStorage.setItem('deviceName', encSensorId);
        this.recordAction(FeatureUsageActionEnum.SensorsSensorClick);
        this.router.navigate(['/sensor']);
    }

    edit(sensor: Sensor) {
        this.openDialog(EditSensorDialogComponent, {
            id: sensor.id,
            name: sensor.name
        },
            undefined,
            FeatureUsageActionEnum.SensorsEditClick);
    }

    configure(sensor: Sensor) {
        this.openDialog(ConfigureSensorDialogComponent, { id: sensor.id }, undefined, FeatureUsageActionEnum.SensorsConfigureClick);
    }
}
