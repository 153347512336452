<h4 class="dialog-title text-center">{{data.tenantName}}</h4>

<hr />

<app-progress-spinner [show]="isLoading"></app-progress-spinner>

<div class="mb-3">
    <div mat-dialog-content>
        <mat-form-field>
            <mat-label>Search</mat-label>
            <input matInput (keyup)="filterUsers($event.target.value)" />
            <mat-icon matPrefix class="mr-3">
                <i class="fas fa-search"></i>
            </mat-icon>
        </mat-form-field>

        <table mat-table [dataSource]="dataSource" matSort matSortActive="securityProfile" matSortDirection="asc"
            matSortDisableClear>
            <ng-container matColumnDef="userName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Username</th>
                <td mat-cell *matCellDef="let user">{{user.userName}}</td>
            </ng-container>

            <ng-container matColumnDef="securityProfile">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
                <td mat-cell *matCellDef="let user">{{user.securityProfile}}</td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let user">
                    <mat-icon class="action-icon" title="Impersonate" (click)="impersonateUser(user.id)">
                        <i class="fas fa-user-secret"></i>
                    </mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; let even = even; columns: displayedColumns;" [class.even-row]="even"></tr>
        </table>
    </div>

    <div mat-dialog-actions align="end" class="mt-4">
        <button mat-button class="btn button-accent" mat-dialog-close>Close</button>
    </div>
</div>
