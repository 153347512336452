<body class="dashboard dashboard-bg">
    <app-header></app-header>

    <div class="body-container collapsed">
        <app-sidebar></app-sidebar>

        <div class="body-content">
            <div class="row">
                <div class="col-md-8 p-0">
                    <app-page-header title="Notifications" iconClass="fas fa-bell"></app-page-header>
                </div>
            </div>

            <app-progress-spinner [show]="isLoading || (dataSource.isLoading$ | async)"></app-progress-spinner>

            <div class="white-box">
                <mat-form-field>
                    <mat-label>Search</mat-label>
                    <input matInput #searchField />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-search"></i>
                    </mat-icon>
                </mat-form-field>

                <table mat-table [dataSource]="dataSource" matSort matSortActive="notificationSentDate"
                    matSortDirection="desc" matSortDisableClear>
                    <ng-container matColumnDef="notificationSentDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Sent</th>
                        <td mat-cell *matCellDef="let notification">
                            <div class="grid-link" (click)="showOnGraph(notification)">
                                {{notification.notificationSentDate | date:"medium"}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="NotificationSettings.Name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Notification Name</th>
                        <td mat-cell *matCellDef="let notification">{{notification.notificationName}}</td>
                    </ng-container>

                    <ng-container matColumnDef="parameterType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Parameter Type</th>
                        <td mat-cell *matCellDef="let notification">{{getParameterTypeName(notification.parameterType)}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="averagingType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Averaging Type</th>
                        <td mat-cell *matCellDef="let notification">{{getAveragingTypeName(notification.averagingType)}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="value">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Value</th>
                        <td mat-cell *matCellDef="let notification">{{notification.value}}
                            [{{getParameterUnit(notification.parameterType)}}]</td>
                    </ng-container>

                    <ng-container matColumnDef="sensor">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sensor</th>
                        <td mat-cell *matCellDef="let notification">{{notification.sensor || 'N/A'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="endPoint">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>End Point</th>
                        <td mat-cell *matCellDef="let notification">{{notification.endPoint || 'N/A'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th class="action-column" mat-header-cell *matHeaderCellDef>Action</th>
                        <td class="action-column" mat-cell *matCellDef="let notification">
                            <mat-icon class="action-icon" title="Show on Graph" (click)="showOnGraph(notification)">
                                <i class="fas fa-chart-line"></i>
                            </mat-icon>

                            <mat-icon class="action-icon color-primary-light" title="View" (click)="view(notification)">
                                <i class="fas fa-info ml-2"></i>
                            </mat-icon>

                            <mat-icon class="action-icon color-accent" title="Delete" (click)="delete(notification)">
                                <i class="fas fa-trash"></i>
                            </mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; let even = even; columns: displayedColumns;"
                        [class.even-row]="even"></tr>
                </table>

                <mat-paginator [length]="dataSource.count$ | async" [pageSizeOptions]="[5, 10, 20]" [pageSize]="10"
                    showFirstLastButtons></mat-paginator>
            </div>

            <div class="row">
                <div class="col-lg-3 offset-lg-9">
                    <mat-form-field>
                        <mat-label>Time Zone</mat-label>
                        <input matInput [(ngModel)]="timeZoneLabel" readonly />
                        <mat-icon matPrefix class="mr-3">
                            <i class="fas fa-globe"></i>
                        </mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div class="clearfix"></div>
            <app-footer></app-footer>
        </div>

        <div class="clearfix"></div>
    </div>
</body>
