export enum FeatureUsageActionEnum {
    None = 0,

    // Sidebar
    SidebarDashboardClick = 1,
    SidebarSensorAssignmentsClick = 2,
    SidebarUsersClick = 3,
    SidebarSensorsClick = 4,
    SidebarDataAnalysisClick = 5,
    SidebarDetailsClick = 6,
    SidebarNotificationSettingsClick = 83,
    SidebarEndPointsClick = 107,

    // Header
    HeaderMenuClick = 7,
    HeaderEditProfileClick = 8,
    HeaderChangePasswordClick = 9,
    HeaderLogoutClick = 10,
    NewNotificationCountClick = 99,

    // Dashboard
    DashboardUsersToggle = 11,
    DashboardSensorsToggle = 12,
    DashboardUserClick = 13,
    DashboardSensorClick = 14,
    DashboardUserSearch = 15,
    DashboardSensorSearch = 16,

    // Sensor Assignments
    SensorAssignmentsAddClick = 17,
    SensorAssignmentsEditClick = 18,
    SensorAssignmentsDeleteClick = 19,
    AddSensorAssignmentClick = 20,
    CancelAddSensorAssignmentClick = 21,
    UpdateSensorAssignmentClick = 22,
    CancelUpdateSensorAssignmentClick = 23,
    DeleteSensorAssignmentClick = 24,
    CancelDeleteSensorAssignmentClick = 25,

    // Users
    UsersAddClick = 26,
    UsersEditClick = 27,
    UsersDeleteClick = 28,
    UsersUserClick = 29,
    AddUserClick = 30,
    CancelAddUserClick = 31,
    UpdateUserClick = 32,
    CancelUpdateUserClick = 33,
    DeleteUserClick = 34,
    CancelDeleteUserClick = 35,

    // Sensors
    SensorsEditClick = 36,
    SensorsConfigureClick = 37,
    SensorsSensorClick = 38,
    UpdateSensorClick = 39,
    CancelUpdateSensorClick = 40,
    UpdateSensorConfigurationClick = 41,
    CancelUpdateSensorConfigurationClick = 42,

    // Data Analysis
    DataAnalysisPlotClick = 43,
    DataAnalysisExportDataClick = 44,

    // Edit Profile
    EditProfileSaveClick = 45,
    EditProfileCancelClick = 46,

    // Change Password
    ChangePasswordSaveClick = 47,
    ChangePasswordCancelClick = 48,

    // Device Page
    DevicePageSensorClick = 49,
    DevicePagePlotClick = 50,
    DevicePageExportClick = 51,
    DevicePage8hClick = 52,
    DevicePage3dClick = 53,
    DevicePage1mClick = 54,
    DevicePageAllClick = 55,

    // User Page
    UserPageUserClick = 56,
    UserPagePlotClick = 57,
    UserPageExportClick = 58,
    UserPage8hClick = 59,
    UserPage3dClick = 60,
    UserPage1mClick = 61,
    UserPageAllClick = 62,

    // Login
    Login = 63,

    // Chart
    ChartHorizontalZoom = 64,
    ChartVerticalZoom = 65,
    ChartBulletClick = 66,

    // Map
    MapInteraction = 67,
    MarkerClick = 68,
    MarkerSensorClick = 69,

    // DateFilters
    DateFilterClick = 70,

    // Graph Save
    ChartPngExport = 71,
    ChartJpgExport = 72,
    ChartSvgExport = 73,
    ChartPdfExport = 74,
    ChartJsonExport = 75,
    ChartCsvExport = 76,
    ChartXlsxExport = 77,
    ChartHtmlExport = 78,
    ChartPrint = 79,

    // Sensor Video
    PlayVideoClick = 80,
    PauseVideoClick = 81,
    DragVideoSlider = 82,

    // Notification Settings
    NotificationSettingsAddClick = 84,
    NotificationSettingsEditClick = 85,
    NotificationSettingsDeleteClick = 86,
    AddNotificationSettingsClick = 87,
    CancelAddNotificationSettingsClick = 88,
    UpdateNotificationSettingsClick = 89,
    CancelUpdateNotificationSettingsClick = 90,
    DeleteNotificationSettingsClick = 91,
    CancelDeleteNotificationSettingsClick = 92,
    CloneNotificationSettingsClick = 93,
    CancelCloneNotificationSettingsClick = 94,
    NotificationSettingsCloneClick = 95,
    NotificationSettingsAssignClick = 96,
    AssignNotificationSettingsClick = 97,
    CancelAssignNotificationSettingsClick = 98,

    // Notifications
    NotificationsViewClick = 100,
    CloseViewNotificationClick = 101,
    NotificationsShowOnGraphClick = 102,
    NotificationsDeleteClick = 103,
    DeleteNotificationClick = 104,
    CancelDeleteNotificationClick = 105,
    EmailNotificationLinkClick = 106,

    // End Points
    EndPointsCreateEndPointClick = 108,
    EndPointsAddClick = 109,
    EndPointsEditClick = 110,
    EndPointsDeleteClick = 111,
    EndPointsEditEndPointClick = 124,
    EndPointsDeleteEndPointClick = 125,

    // Custom Objects
    CreateCustomObjectClick = 112,
    CancelCreateCustomObjectClick = 113,
    UpdateCustomObjectClick = 114,
    CancelUpdateCustomObjectClick = 115,
    DeleteCustomObjectClick = 116,
    CancelDeleteCustomObjectClick = 117,

    // Custom Entities
    CreateCustomEntityClick = 118,
    CancelCreateCustomEntityClick = 119,
    UpdateCustomEntityClick = 120,
    CancelUpdateCustomEntityClick = 121,
    DeleteCustomEntityClick = 122,
    CancelDeleteCustomEntityClick = 123,
}
