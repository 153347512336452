import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EndpointService } from './endpoint.service';
import { IGridService } from '../shared/grid/grid.service';
import { GridModel } from '../shared/grid/grid.model';
import { ToastService } from './toast.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Lookup } from '../models/lookup.model';
import { CustomEntity, CustomEntityModel } from '../models/customEntity.model';
import { CustomObjectLookup } from '../models/customObjectLookup.model';

@Injectable({
    providedIn: 'root'
})
export class CustomEntityService extends EndpointService implements IGridService<CustomEntity> {

    constructor(http: HttpClient,
        private toastService: ToastService) {
        super(http);
    }

    get(filter: string, orderBy: string, sort: string, page: number, pageSize: number, headers?: HttpHeaders) {
        return this.http.get<GridModel<CustomEntity>>(this.getApiUrl('CustomEntity/GetCustomEntities'), {
            params: this.createParams(filter, orderBy, sort, page, pageSize),
            headers: headers
        });
    }

    getCustomEntity(id: number) {
        return this.http.get<CustomEntity>(this.getApiUrl(`CustomEntity/GetCustomEntity?id=${id}`))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading custom entity.');
                    return of(null);
                })
            );
    }

    create(payload: CustomEntityModel) {
        return this.http.post<number>(this.getApiUrl('CustomEntity/CreateCustomEntity'), payload);
    }

    update(payload: CustomEntityModel) {
        return this.http.post(this.getApiUrl('CustomEntity/UpdateCustomEntity'), payload);
    }

    delete(id: number) {
        return this.http.delete(this.getApiUrl(`CustomEntity/DeleteCustomEntity?id=${id}`));
    }

    getEndPointLookupList() {
        return this.http.get<CustomObjectLookup[]>(this.getApiUrl('CustomEntity/GetEndPointLookupList'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading end points.');
                    return of([]);
                })
            );
    }

    getIconLookup(id: number) {
        return this.http.get<CustomObjectLookup>(this.getApiUrl(`CustomEntity/GetIconLookup?id=${id}`))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading entity.');
                    return of(new CustomObjectLookup());
                })
            );
    }
}
