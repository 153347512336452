import { Injectable } from '@angular/core';
import { EndpointService } from './endpoint.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastService } from './toast.service';
import { ShadowConstraint } from '../models/shadowConstraint.model';
import { Enum } from '../models/enum.model';
import { IGridService } from '../shared/grid/grid.service';
import { GridModel } from '../shared/grid/grid.model';

@Injectable({
    providedIn: 'root'
})
export class ShadowConstraintService extends EndpointService implements IGridService<ShadowConstraint> {

    constructor(httpClient: HttpClient,
        private toastService: ToastService) {
        super(httpClient);
    }

    get(filter: string, orderBy: string, sort: string, page: number, pageSize: number) {
        return this.http.get<GridModel<ShadowConstraint>>(this.getApiUrl('ShadowConstraint/GetShadowConstraints'), {
            params: this.createParams(filter, orderBy, sort, page, pageSize)
        });
    }

    getConstraintTypes() {
        return this.http.get<Enum[]>(this.getApiUrl('ShadowConstraint/GetConstraintTypes'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading constraint types.');
                    return of([]);
                })
            );
    }

    getOperatorTypes() {
        return this.http.get<Enum[]>(this.getApiUrl('ShadowConstraint/GetOperatorTypes'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading operator types.');
                    return of([]);
                })
            );
    }

    getShadowConstraint(id: number) {
        return this.http.get<ShadowConstraint>(this.getApiUrl(`ShadowConstraint/GetShadowConstraint?id=${id}`))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading shadow constraint.');
                    return of(new ShadowConstraint());
                })
            );
    }

    create(shadowConstraint: ShadowConstraint) {
        return this.http.post(this.getApiUrl('ShadowConstraint/CreateShadowConstraint'), shadowConstraint);
    }

    update(shadowConstraint: ShadowConstraint) {
        return this.http.post(this.getApiUrl('ShadowConstraint/UpdateShadowConstraint'), shadowConstraint);
    }

    delete(id: number) {
        return this.http.delete(this.getApiUrl(`ShadowConstraint/DeleteShadowConstraint?id=${id}`));
    }
}
