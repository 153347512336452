import { Injectable } from '@angular/core';
import { EndpointService } from './endpoint.service';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { Observable, of } from 'rxjs';
import { AppNotification } from '../models/notification';
import { catchError } from 'rxjs/operators';
import { IGridService } from '../shared/grid/grid.service';
import { GridModel } from '../shared/grid/grid.model';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends EndpointService implements IGridService<AppNotification> {

    constructor(http: HttpClient,
        private toastService: ToastService) {
        super(http);
    }

    get(filter: string, orderBy: string, sort: string, page: number, pageSize: number): Observable<GridModel<AppNotification>> {
        return this.http.get<GridModel<AppNotification>>(this.getApiUrl('Notification/GetNotifications'), {
            params: this.createParams(filter, orderBy, sort, page, pageSize)
        });
    }

    getNewNotificationCount(): Observable<number> {
        return this.http.get<number>(this.getApiUrl('Notification/GetNewNotificationCount'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading notification count.');
                    return of(0);
                })
            );
    }

    deleteNotification(id: number) {
        return this.http.delete(this.getApiUrl(`Notification/DeleteNotification?id=${id}`));
    }
}
