<body class="dashboard dashboard-bg">
    <app-header></app-header>

    <div class="body-container collapsed">
        <app-sidebar></app-sidebar>

        <div class="body-content">
            <div class="row">
                <div class="col-md-8 p-0">
                    <app-page-header title="Sensor Assignments" iconClass="fas fa-user-cog"></app-page-header>
                </div>

                <div class="col-md-4 p-0">
                    <div class="text-right grid-button-container">
                        <button type="button" class="btn button-primary" (click)="add()" mat-button>
                            Add Assignment
                        </button>
                    </div>
                </div>
            </div>

            <app-progress-spinner [show]="isLoading || (dataSource.isLoading$ | async)"></app-progress-spinner>

            <div class="white-box">
                <mat-form-field>
                    <mat-label>Search</mat-label>
                    <input matInput #searchField />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-search"></i>
                    </mat-icon>
                </mat-form-field>

                <table mat-table [dataSource]="dataSource" matSort matSortActive="EndPoint.Name" matSortDirection="asc"
                    matSortDisableClear>
                    <ng-container matColumnDef="EndPoint.Name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>End Point</th>
                        <td mat-cell *matCellDef="let sensorAssignment">
                            <div class="grid-link" (click)="viewEndPoint(sensorAssignment)">
                                {{sensorAssignment.endPointName}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Sensor.Name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sensor</th>
                        <td mat-cell *matCellDef="let sensorAssignment">
                            <div class="grid-link" (click)="setSensorId(sensorAssignment)">
                                {{sensorAssignment.sensorName}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="startDate">
                        <th class="date-column" mat-header-cell *matHeaderCellDef mat-sort-header>From Date</th>
                        <td class="date-column" mat-cell *matCellDef="let sensorAssignment">
                            {{sensorAssignment.startDate | date:"medium"}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="endDate">
                        <th class="date-column" mat-header-cell *matHeaderCellDef mat-sort-header>To Date</th>
                        <td class="date-column" mat-cell *matCellDef="let sensorAssignment">
                            {{sensorAssignment.endDate | date:"medium"}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="AssignedByUser.UserName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
                        <td mat-cell *matCellDef="let sensorAssignment">{{sensorAssignment.assignedBy}}</td>
                    </ng-container>

                    <ng-container matColumnDef="createdDate">
                        <th class="date-column" mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</th>
                        <td class="date-column" mat-cell *matCellDef="let sensorAssignment">
                            {{sensorAssignment.createdDate | date:"medium"}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th class="action-column" mat-header-cell *matHeaderCellDef>Action</th>
                        <td class="action-column" mat-cell *matCellDef="let sensorAssignment">
                            <mat-icon class="action-icon" title="Edit" (click)="edit(sensorAssignment)">
                                <i class="fas fa-edit"></i>
                            </mat-icon>

                            <mat-icon class="action-icon color-accent" title="Delete"
                                (click)="deleteSensorAssignment(sensorAssignment.id)">
                                <i class="fas fa-trash"></i>
                            </mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; let even = even; columns: displayedColumns;"
                        [class.even-row]="even"></tr>
                </table>

                <mat-paginator [length]="dataSource.count$ | async" [pageSizeOptions]="[5,10,20]" [pageSize]="10"
                    showFirstLastButtons></mat-paginator>
            </div>

            <div class="row">
                <div class="col-lg-3 offset-lg-9">
                    <mat-form-field>
                        <mat-label>Time Zone</mat-label>
                        <input matInput [(ngModel)]="timeZoneLabel" readonly />
                        <mat-icon matPrefix class="mr-3">
                            <i class="fas fa-globe"></i>
                        </mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div class="clearfix"></div>
            <app-footer></app-footer>
        </div>

        <div class="clearfix"></div>
    </div>
</body>
