import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { SensorService } from 'src/app/services/sensor.service';
import { DateFormatService } from 'src/app/services/dateFormat.service';
import { ToastService } from 'src/app/services/toast.service';
import { forkJoin } from 'rxjs';
import { SensorAssignment } from 'src/app/models/sensorAssignment.model';
import { Configuration } from 'src/app/models/configuration.model';
import { Sensor } from 'src/app/models/sensor.model';
import { TimeZoneService } from 'src/app/services/timeZone.service';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { ErrorCodeEnum } from 'src/app/enums/errorCode.enum';
import { AuthService } from 'src/app/services';
import { Lookup } from 'src/app/models/lookup.model';
import { CustomEntityService } from 'src/app/services/customEntity.service';

export interface EditAssignmentDialogData {
    id: number;
}

@Component({
    selector: 'app-edit-sensor-assignment-dialog',
    templateUrl: 'editSensorAssignmentDialog.component.html'
})
export class EditSensorAssignmentDialogComponent extends TrackableComponent implements OnInit, OnDestroy {

    isFormLoading = false;
    isLoading = false;
    sensors: Sensor[];
    endPoints: Lookup[];
    sensorAssignment: SensorAssignment;
    timeZone: string;

    constructor(public dialogRef: MatDialogRef<EditSensorAssignmentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EditAssignmentDialogData,
        private sensorAssignmentService: ConfigurationService,
        private customEntityService: CustomEntityService,
        private sensorService: SensorService,
        private dateFormatService: DateFormatService,
        private toastService: ToastService,
        timeZoneService: TimeZoneService,
        authService: AuthService,
        featureUsageService: FeatureUsageService) {

        super(featureUsageService, FeatureUsagePageEnum.EditSensorAssignmentDialog);

        this.sensorAssignment = new SensorAssignment();
        this.timeZone = timeZoneService.createTimeZoneLabel(authService.getCurrentUser().userTimeZone);
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    ngOnInit() {
        this.isFormLoading = true;
        forkJoin({
            sensorAssignment: this.sensorAssignmentService.getSensorAssignment(this.data.id),
            endPoints: this.customEntityService.getEndPointLookupList(),
            sensors: this.sensorService.getSensorLookupList()
        }).subscribe(
            result => {
                this.sensorAssignment = result.sensorAssignment;
                this.endPoints = result.endPoints;
                this.sensors = result.sensors;
                this.isFormLoading = false;
            },
            error => {
                console.log(error);
                this.toastService.showError(error);
                this.isFormLoading = false;
            }
        );
    }

    save() {
        if (this.sensorAssignment.startDate > this.sensorAssignment.endDate) {
            const message = 'Invalid date: From Date must be less than To Date.';
            this.toastService.showWarning(message);
            this.recordError(ErrorCodeEnum.Validation, message);
            return;
        }

        this.isLoading = true;

        const payload = new Configuration();
        payload.id = this.sensorAssignment.id;
        payload.startDate = this.dateFormatService.formatDateTimeToString(this.sensorAssignment.startDate);
        payload.endDate = this.dateFormatService.formatDateTimeToString(this.sensorAssignment.endDate);
        payload.sensorId = this.sensorAssignment.sensorId;
        payload.endPointId = this.sensorAssignment.endPointId;

        this.sensorAssignmentService.updateSensorAssignment(payload)
            .subscribe(_ => {
                this.isLoading = false;
                this.toastService.showSuccess('Sensor assignment saved successfully.');
                this.dialogRef.close(true);
            }, error => {
                this.isLoading = false;
                console.log(error);
                this.toastService.showError(error);
            });

        this.recordAction(FeatureUsageActionEnum.UpdateSensorAssignmentClick);
    }

    onClose() {
        this.recordAction(FeatureUsageActionEnum.CancelUpdateSensorAssignmentClick);
    }
}
