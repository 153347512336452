import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services';
import { ToastService } from '../../services/toast.service';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { ErrorCodeEnum } from 'src/app/enums/errorCode.enum';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MfaDialogComponent } from '../mfaDialog/mfaDialog.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent extends TrackableComponent implements OnInit, OnDestroy {

    loginForm: FormGroup;
    isLoading = false;
    returnUrl: string;
    rememberMe: boolean;
    show: boolean;

    constructor(private router: Router,
        private formBuilder: FormBuilder,
        private toastService: ToastService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private dialog: MatDialog,
        featureUsageService: FeatureUsageService) {

        super(featureUsageService, FeatureUsagePageEnum.LoginPage);

        this.rememberMe = this.authService.getRememberMe();
        const currentUser = this.authService.getCurrentUser();

        if (this.authService.isLoggedIn() && currentUser && this.rememberMe) {
            this.navigateToHome();
        } else {
            this.revokeTokens();
        }
    }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'];

        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]]
        });
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    onSubmit() {
        if (this.loginForm.invalid) {
            const message = 'Please provide valid username and password.';
            this.toastService.displayErrorMessage(message);
            this.recordError(ErrorCodeEnum.Validation, message);
            return;
        }

        this.loginUser();
    }

    loginUser() {
        this.isLoading = true;
        this.authService.login(this.loginForm.controls.username.value, this.loginForm.controls.password.value)
            .pipe(
                first(),
            )
            .subscribe(
                response => {
                    this.isLoading = false;
                    this.onLoginSuccess(response.ipAddress);
                },
                error => {
                    this.isLoading = false;

                    if (error && error.status === 403 && error.error === 'MFA') {
                        this.handleMfa();
                        return;
                    }

                    console.log(error);
                    if (error.error && error.error instanceof Array && error.error.length > 0) {
                        this.toastService.displayErrorMessage(error.error[0].description);
                    }                    
                });
    }

    handleMfa() {
        const dialogRef = this.dialog.open(MfaDialogComponent, {
            data: {
                username: this.loginForm.controls.username.value,
                isLogin: true
            },
            width: '450px',
            disableClose: true
        });

        dialogRef.afterClosed()
            .subscribe((result: any) => {
                if (result) {
                    this.onLoginSuccess(null);
                }
            });
    }

    onLoginSuccess(ipAddress: string) {
        this.authService.setRememberMe(this.rememberMe);
        if (this.returnUrl) {
            this.router.navigateByUrl(this.returnUrl);
        } else {
            this.navigateToHome();
        }
        this.toastService.showSuccess('Logged in.');
        this.recordAction(FeatureUsageActionEnum.Login, ipAddress);
    }

    navigateToHome() {
        this.router.navigate(['/dashboard']);
    }

    revokeTokens() {
        if (this.authService.isLoggedIn()) {
            this.authService.revokeTokens()
                .subscribe(
                    _ => _,
                    error => console.log(error)
                );
        }
    }
}
