import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/toast.service';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { Enum } from 'src/app/models/enum.model';
import { CustomObjectService } from 'src/app/services/customObject.service';
import { CustomObjectModel } from 'src/app/models/customObject.model';
import { IconList } from 'src/app/shared/iconList';

@Component({
    selector: 'app-create-custom-object-dialog',
    templateUrl: 'createCustomObjectDialog.component.html'
})
export class CreateCustomObjectDialogComponent extends TrackableComponent implements OnInit, OnDestroy {

    isFormLoading = false;
    isLoading = false;
    customObjectTypes: Enum[];
    isTypePreSet: boolean;
    icons: string[] = [];

    constructor(public dialogRef: MatDialogRef<CreateCustomObjectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public customObject: CustomObjectModel,
        private customObjectService: CustomObjectService,
        private toastService: ToastService,
        featureUsageService: FeatureUsageService
    ) {
        super(featureUsageService, FeatureUsagePageEnum.CreateCustomObjectDialog);
        this.icons.push(...IconList.icons.map(x => x.value));
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    ngOnInit() {
        this.isFormLoading = true;
        this.customObjectService.getCustomObjectTypes()
            .subscribe(
                result => {
                    this.customObjectTypes = result;
                    this.isTypePreSet = this.customObject && this.customObject.type !== undefined;
                    this.isFormLoading = false;
                },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isFormLoading = false;
                }
            );
    }

    create() {
        this.isLoading = true;
        this.customObjectService.create(this.customObject)
            .subscribe(coId => {
                this.isLoading = false;
                this.toastService.showSuccess(`${this.customObject.typeName} created successfully.`);
                this.dialogRef.close(coId);
            }, error => {
                this.isLoading = false;
                console.log(error);
                this.toastService.showError(error);
            });

        this.recordAction(FeatureUsageActionEnum.CreateCustomObjectClick);
    }

    onClose() {
        this.recordAction(FeatureUsageActionEnum.CancelCreateCustomObjectClick);
    }
}
