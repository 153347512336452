export class UserProfile {
    userName: string;
    fullName: string;
    companyName: string;
    phoneNumber: string;
    jobTitle: string;
    timeZoneId: number;
    temperatureUnitId: number;
    pmUnitId: number;
    showMap: boolean;
    notificationPreferences: number[];
    defaultMapLocation: string;
    twoFactorEnabled: boolean;
}
