import { NotificationAveragingEnum } from '../enums/notificationAveraging.enum';
import { ParameterTypeEnum } from '../enums/parameterType.enum';

export class AppNotification {
    id: number;
    notificationName: string;
    notificationSentDate: Date;
    shiftStartDate: Date;
    parameterType: ParameterTypeEnum;
    averagingType: NotificationAveragingEnum;
    value: number;
    sensor: string;
    endPoint: string;
    sensorId: string;
    endPointId: number;
}
