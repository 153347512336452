import { Component, OnInit, Inject } from '@angular/core';
import { TenantService } from 'src/app/services/tenant.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/toast.service';
import { environment } from 'src/environments/environment';

export class ImageContainer {
    constructor(public src: string, public file: File, public fileName: string) { }
}

export interface UploadLogoDialogData {
    id: number;
}

@Component({
    selector: 'app-upload-logo-dialog',
    templateUrl: 'uploadLogoDialog.component.html',
    styleUrls: ['uploadLogoDialog.component.css']
})
export class UploadLogoDialogComponent implements OnInit {

    isFormLoading = false;
    isLoading = false;
    selectedFile: ImageContainer;
    tenantLogoUrl: string;

    constructor(public dialogRef: MatDialogRef<UploadLogoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UploadLogoDialogData,
        private tenantService: TenantService,
        private toastService: ToastService) {
    }

    ngOnInit() {
        this.isFormLoading = true;
        this.tenantService.getTenantLogoUrl(this.data.id)
            .subscribe(
                logoUrl => {
                    if (logoUrl) {
                        this.tenantLogoUrl = environment.baseUrl + '/' + logoUrl;
                    }
                    this.isFormLoading = false;
                },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isFormLoading = false;
                });
    }

    previewImage(imageInput: any) {
        const file: File = imageInput.files[0];
        const reader = new FileReader();

        reader.addEventListener('load', (event: any) => {
            this.selectedFile = new ImageContainer(event.target.result, file, file.name);
        });

        reader.readAsDataURL(file);
    }

    save() {
        this.isLoading = true;
        this.tenantService.uploadLogoImage(this.data.id, this.selectedFile.file)
            .subscribe(
                result => {
                    this.toastService.showSuccess('Tenant logo saved successfully.');
                    this.isLoading = false;
                    this.dialogRef.close();
                },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isLoading = false;
                }
            );
    }
}
