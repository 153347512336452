import { Component, ChangeDetectionStrategy, AfterViewInit, NgZone, Injector, ComponentFactoryResolver, ApplicationRef, ComponentRef, OnDestroy } from '@angular/core';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { MarkerClusterer } from 'js-marker-clusterer/src/markerclusterer.js';
import { GoogleMap, LatLngLiteral } from '@agm/core/services/google-maps-types';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Sensor } from 'src/app/models/sensor.model';
import { SensorRecord } from 'src/app/models/sensorRecord.model';
import { TenantPermissions } from 'src/app/models/tenantPermissions.model';
import { SensorStatusEnum } from 'src/app/enums/status.enum';
import { AuthService } from 'src/app/services';
import { UserPreferences } from 'src/app/models/userPreferences.model';
import { TemperatureUnitEnum } from 'src/app/enums/temperatureUnit.enum';
import { PmUnitEnum } from 'src/app/enums/pmUnit.enum';
import { EventService } from 'src/app/services/event.service';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { Router } from '@angular/router';
import { MonitoringSite } from 'src/app/models/monitoringSite.model';
import { InfoWindowComponent } from 'src/app/components/info-window/info-window.component';

declare var google: any;
declare const MarkerClusterer;

@Component({
    selector: 'app-map-clusterer',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapClustererComponent implements AfterViewInit, OnDestroy {
    markerClusterer: MarkerClusterer;
    nativeMap: GoogleMap;
    currentInfoWindow: any;
    tenantPermissions: TenantPermissions;
    preferences: UserPreferences;
    defaultCenter: LatLngLiteral;

    compRef: ComponentRef<InfoWindowComponent>;

    constructor(private authService: AuthService,
        private mapWrapper: GoogleMapsAPIWrapper,
        private datePipe: DatePipe,
        private decimalPipe: DecimalPipe,
        private eventService: EventService,
        private router: Router,
        private zone: NgZone,
        private injector: Injector,
        private resolver: ComponentFactoryResolver,
        private appRef: ApplicationRef
    ) {
        const currentUser = this.authService.getCurrentUser();
        this.tenantPermissions = currentUser.tenantPermissions;
        this.preferences = currentUser.preferences;
        this.setDefaultLocation(this.preferences.defaultMapLocation);
    }

    setDefaultLocation(defaultMapLocation: string) {
        try {
            this.defaultCenter = {
                lat: 0,
                lng: 0
            };
            if (defaultMapLocation) {
                const coordinates = defaultMapLocation.split(',');
                this.defaultCenter.lat = parseFloat(coordinates[0]);
                this.defaultCenter.lng = parseFloat(coordinates[1]);
            }
        } catch (error) {
            console.log(error);
            console.log(`Invalid default map location ${defaultMapLocation}`);
        }
    }

    ngOnDestroy(): void {
        if (this.compRef) {
            this.compRef.destroy();
        }
    }

    updateMarkerClusterer(sensorRecords: SensorRecord[],
        sensors: Sensor[],
        page: FeatureUsagePageEnum,
        isFilteredByDate: boolean = false,
        monitoringSites: MonitoringSite[] = []): void {
        const isDashboardPage = page === FeatureUsagePageEnum.DashboardPage;
        const isUserPage = page === FeatureUsagePageEnum.UserPage;
        const filteredMarkers = this.filterSensorRecords(sensorRecords, sensors, isDashboardPage, isFilteredByDate);
        const markers: any[] = [];
        const bounds = new google.maps.LatLngBounds();
        const temperatureUnit = this.preferences.temperatureUnitId === TemperatureUnitEnum.Fahrenheit
            ? '°F'
            : '°C';
        const pmUnit = this.preferences.pmUnitId === PmUnitEnum.MgPerM3
            ? 'mg/m³'
            : 'µg/m³';
        filteredMarkers.forEach((data) => {
            const marker = new google.maps.Marker({
                position: {
                    lat: data.lat,
                    lng: data.lon,
                },
                icon: data.statusId === SensorStatusEnum.Critical
                    ? './assets/images/red_circle.png'
                    : data.statusId === SensorStatusEnum.Warning
                        ? './assets/images/orange_circle.png'
                        : './assets/images/green_circle.png'
            });
            bounds.extend(marker.getPosition());
            const infoWindow = new google.maps.InfoWindow({
                content: `<table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Device Name</td>
                                    <td>
                                        ${(isDashboardPage || isUserPage) && data.deviceName !== undefined
                        ? '<a id="sensorId" class="grid-link">'
                        : ''}
                                        ${data.deviceName !== undefined
                        ? data.deviceName
                        : 'N/A'}
                                        ${(isDashboardPage || isUserPage) && data.deviceName !== undefined
                        ? '</a>'
                        : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Device ID</td>
                                    <td>
                                        ${(isDashboardPage || isUserPage) && data.deviceName === undefined
                        ? '<a id="sensorId" class="grid-link">'
                        : ''}
                                        ${data.deviceId}
                                        ${(isDashboardPage || isUserPage) && data.deviceName === undefined
                        ? '</a>'
                        : ''}
                                    </td>
                                </tr>
                                ${isDashboardPage && data.endPointName !== null && data.endPointName !== undefined
                        ? '<tr><td>End Point Name</td><td>' + data.endPointName + '</td></tr>'
                        : ''}
                                <tr>
                                    <td>Date&Time</td>
                                    <td>${this.datePipe.transform(data.dateTime, 'medium')}</td>
                                </tr>
                                ${this.tenantPermissions.canViewPm1_0
                        ? '<tr><td>PM1.0 [' + pmUnit + ']</td><td>' + this.decimalPipe.transform(data.pm1, '1.0-2') + '</td></tr>'
                        : ''}
                                ${this.tenantPermissions.canViewPm2_5
                        ? '<tr><td>PM2.5 [' + pmUnit + ']</td><td>' + this.decimalPipe.transform(data.pm2_5, '1.0-2') + '</td></tr>'
                        : ''}
                                ${this.tenantPermissions.canViewPm10_0
                        ? '<tr><td>PM10.0 [' + pmUnit + ']</td><td>' + this.decimalPipe.transform(data.pm10, '1.0-2') + '</td></tr>'
                        : ''}
                                ${this.tenantPermissions.canViewRespirablePm
                        ? '<tr><td>Respirable PM [' + pmUnit + ']</td><td>' + this.decimalPipe.transform(data.respirablePm, '1.0-2') + '</td></tr>'
                        : ''}
                                <tr>
                                    <td>Latitude [°]</td>
                                    <td>${data.lat}</td>
                                </tr>
                                <tr>
                                    <td>Longitude [°]</td>
                                    <td>${data.lon}</td>
                                </tr>
                                <tr>
                                    <td>RSSI</td>
                                    <td>${data.rssi}</td>
                                </tr>
                                ${this.tenantPermissions.canViewEnvironmentalData
                        ? '<tr><td>Temperature [' + temperatureUnit + ']</td><td>' + this.decimalPipe.transform(data.tempC, '1.0-2') + '</td></tr>'
                        + '<tr><td>Humidity [%]</td><td>' + this.decimalPipe.transform(data.hum, '1.0-2') + '</td></tr>'
                        : ''}
                                ${this.tenantPermissions.canViewTVOC
                        ? '<tr><td>TVOC [PPM]</td><td>' + this.decimalPipe.transform(data.tvoc, '1.0-2') + '</td></tr>'
                        : ''}
                                ${this.tenantPermissions.canViewCO2
                        ? '<tr><td>CO2 [PPM]</td><td>' + this.decimalPipe.transform(data.cO2, '1.0-2') + '</td></tr>'
                        : ''}
                            </tbody>
                        </table>`
            });
            if (isDashboardPage || isUserPage) {
                google.maps.event.addListener(infoWindow, 'domready',
                    () => {
                        const sensorLink = $('#sensorId');
                        sensorLink.one('click', () => {
                            const encDeviceName = window.btoa(data.deviceId);
                            localStorage.setItem('deviceName', encDeviceName);
                            this.eventService.broadcast('markerClick', FeatureUsageActionEnum.MarkerSensorClick);
                            this.zone.run(() => this.router.navigate(['/sensor']));
                        });
                    }
                );
            }
            marker.addListener('click', () => {
                if (this.currentInfoWindow !== null && this.currentInfoWindow !== undefined) {
                    this.currentInfoWindow.close();
                }
                if (this.compRef) {
                    this.compRef.destroy();
                }
                infoWindow.open(this.nativeMap, marker);
                this.currentInfoWindow = infoWindow;
                this.eventService.broadcast('markerClick', FeatureUsageActionEnum.MarkerClick);
            });
            markers.push(marker);
        });

        monitoringSites.forEach((site) => {
            const marker = new google.maps.Marker({
                position: {
                    lat: site.latitude,
                    lng: site.longitude,
                },
                icon: './assets/images/green_triangle.png'
            });
            const infoWindow = new google.maps.InfoWindow();

            marker.addListener('click', (e) => {
                this.zone.run(() => this.onMarkerClick(marker, site, infoWindow, e));
                this.eventService.broadcast('markerClick', FeatureUsageActionEnum.MarkerClick);
            });
            infoWindow.addListener('closeclick', _ => {
                this.compRef.destroy();
            });
            markers.push(marker);
        });

        if (filteredMarkers.length > 0) {
            this.nativeMap.fitBounds(bounds);
            if (this.nativeMap.getZoom() > 19) {
                this.nativeMap.setZoom(19);
            }
        }

        if (!this.markerClusterer) {
            this.markerClusterer = this.createMarkerClusterer(markers, isDashboardPage);
        } else {
            this.markerClusterer.clearMarkers();
            this.markerClusterer.addMarkers(markers, false);
            this.markerClusterer.repaint();
        }
    }

    onMarkerClick(marker, site: MonitoringSite, infoWindow, e) {
        if (this.currentInfoWindow !== null && this.currentInfoWindow !== undefined) {
            this.currentInfoWindow.close();
        }
        if (this.compRef) {
            this.compRef.destroy();
        }

        const compFactory = this.resolver.resolveComponentFactory(InfoWindowComponent);
        this.compRef = compFactory.create(this.injector);

        this.compRef.instance.siteId = site.id;
        this.compRef.instance.latitude = site.latitude;
        this.compRef.instance.longitude = site.longitude;

        this.appRef.attachView(this.compRef.hostView);

        const div = document.createElement('div');
        div.appendChild(this.compRef.location.nativeElement);

        infoWindow.setContent(div);
        infoWindow.open(this.nativeMap, marker);
        this.currentInfoWindow = infoWindow;
    }

    private filterSensorRecords(sensorRecords: SensorRecord[], sensors: Sensor[], isDashboardPage: boolean, isFilteredByDate: boolean) {
        const filteredMarkers = sensorRecords.filter(marker =>
            (marker.lat !== 0 || marker.lon !== 0) && (marker.hdop === undefined || marker.hdop < 20) &&
            (marker.lon >= -180 && marker.lon <= 180) &&
            (marker.lat >= -90 && marker.lat <= 90) &&
            (!isDashboardPage || isFilteredByDate || marker.statusId !== SensorStatusEnum.Offline));

        const sensorDict: { [key: string]: string | undefined } = {};
        sensors.forEach(sensor =>
            sensorDict[sensor.id] = sensor.name);

        const markerMap = new Map<string, SensorRecord>();
        filteredMarkers.forEach(marker => {
            marker.deviceName = sensorDict[marker.deviceId] || marker.deviceId;
            markerMap.set(`${marker.lon}:${marker.lat}`, marker);
        });

        return Array.from(markerMap.values());
    }

    private createMarkerClusterer(markers, isDashboardPage: boolean) {
        const styles = [];
        [53, 53, 53, 53, 53].forEach(size => { // cluster image sizes
            styles.push({
                url: './assets/images/cluster.png',
                height: size,
                width: size
            });
        });
        const options = {
            maxZoom: 19,
            styles: styles
        };
        if (isDashboardPage) {
            options['minimumClusterSize'] = 100;
        }
        return new MarkerClusterer(this.nativeMap, markers, options);
    }

    ngAfterViewInit() {
        this.mapWrapper.getNativeMap().then(m => {
            m.setCenter({
                lat: this.defaultCenter.lat,
                lng: this.defaultCenter.lng
            });
            this.nativeMap = m;
        });
    }
}
