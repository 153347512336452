<h4 class="dialog-title text-center">Add Notification</h4>

<hr />

<app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput [(ngModel)]="data.name" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-bell"></i>
                    </mat-icon>
                    <mat-error>Name is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12 padding-y-10">
                <mat-icon class="mr-3">
                    <i class="fas fa-check-circle"></i>
                </mat-icon>
                <mat-label class="mr-3">Status</mat-label>
                <mat-slide-toggle color="primary" [(ngModel)]="data.isEnabled"></mat-slide-toggle>
                <hr class="field-underline" />
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Parameter Type</mat-label>
                    <mat-select [(ngModel)]="data.parameterType" required>
                        <mat-option *ngFor="let parameterType of parameterTypes" [value]="parameterType.id">
                            {{parameterType.value}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-chart-line"></i>
                    </mat-icon>
                    <mat-error>Parameter Type is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Averaging Type</mat-label>
                    <mat-select [(ngModel)]="data.averagingType" required>
                        <mat-option *ngFor="let averagingType of averagingTypes" [value]="averagingType.id">
                            {{averagingType.value}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-clock"></i>
                    </mat-icon>
                    <mat-error>Averaging Type is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Working Days</mat-label>
                    <mat-select [(ngModel)]="data.workingDays" multiple required>
                        <mat-option *ngFor="let dayOfWeek of daysOfWeek" [value]="dayOfWeek.id">
                            {{dayOfWeek.value}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-calendar-day"></i>
                    </mat-icon>
                    <mat-error>Working Days are required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <div class="padding-y-10 clickable" (click)="addEditInterval()" title="Add Shift">
                    <mat-icon [class.invalid-color]="areShiftsEmpty()" class="mr-3">
                        <i class="fas fa-business-time"></i>
                    </mat-icon>
                    <mat-label [class.invalid-color]="areShiftsEmpty()" class="mr-3">Shifts *:</mat-label>
                    <mat-icon matSuffix class="action-icon">
                        <i class="fas fa-plus"></i>
                    </mat-icon>
                    <mat-label>Add</mat-label>
                    <hr [class.invalid-field]="areShiftsEmpty()" class="hr-field-underline" />
                    <mat-label *ngIf="areShiftsEmpty()" class="invalid-field">Shifts are required.</mat-label>
                </div>
            </div>

            <div class="col-md-12 pl-4">
                <div *ngFor="let shiftInterval of data.shifts; let i = index">
                    <mat-form-field>
                        <mat-label>Shift {{i+1}}</mat-label>
                        <input matInput
                            value="{{shiftInterval.startDate | date:'shortTime'}} - {{shiftInterval.endDate | date:'shortTime'}}"
                            readonly />
                        <mat-icon matPrefix class="mr-3">
                            <i class="fas fa-user-clock"></i>
                        </mat-icon>
                        <mat-error>Shift interval is required.</mat-error>
                        <mat-icon matSuffix class="ml-3 action-icon" title="Edit" (click)="addEditInterval(shiftInterval)">
                            <i class="fas fa-edit"></i>
                        </mat-icon>
                        <mat-icon matSuffix class="ml-2 action-icon color-accent" title="Delete" (click)="deleteInterval(shiftInterval)">
                            <i class="fas fa-trash"></i>
                        </mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-md-12" *ngIf="data.averagingType !== 0">
                <mat-form-field>
                    <mat-label>Computation Method</mat-label>
                    <mat-select [(ngModel)]="data.shiftType" required>
                        <mat-option *ngFor="let shiftType of shiftTypes" [value]="shiftType.id">
                            {{shiftType.value}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-history"></i>
                    </mat-icon>
                    <mat-error>Computation Method is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Notification Limit</mat-label>
                    <input type="number" matInput [(ngModel)]="data.limit" min="0" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-list-ol"></i>
                    </mat-icon>
                    <mat-error>Notification Limit is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Limit Type</mat-label>
                    <mat-select [(ngModel)]="data.limitType" required>
                        <mat-option *ngFor="let limitType of limitTypes" [value]="limitType.id">
                            {{limitType.value}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-stopwatch"></i>
                    </mat-icon>
                    <mat-error>Limit Type is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Threshold [{{getParameterUnit(data.parameterType)}}]</mat-label>
                    <input type="number" matInput [(ngModel)]="data.threshold" min="0" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-tachometer-alt"></i>
                    </mat-icon>
                    <mat-error>Threshold is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Subscribers</mat-label>
                    <mat-select [(ngModel)]="data.subscribers" multiple required>
                        <mat-option *ngFor="let user of admins" [value]="user.id">
                            {{user.userName}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-user"></i>
                    </mat-icon>
                    <mat-error>Subscribers are required.</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button class="btn button-primary" mat-button (click)="create()" [class.button-spinner]="isLoading"
            [disabled]="isDisabled() || isLoading">
            Add
        </button>

        <button class="btn button-accent" mat-button mat-dialog-close (click)="onClose()">Cancel</button>
    </div>
</div>
