import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceVersion } from 'src/app/models/deviceVersion.model';
import { DeviceVersionService } from 'src/app/services/deviceVersionService';
import { ToastService } from 'src/app/services/toast.service';

@Component({
    selector: 'app-create-device-version-dialog',
    templateUrl: 'createDeviceVersionDialog.component.html'
})
export class CreateDeviceVersionDialogComponent {

    isLoading = false;

    constructor(public dialogRef: MatDialogRef<CreateDeviceVersionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public deviceVersion: DeviceVersion,
        private deviceVersionService: DeviceVersionService,
        private toastService: ToastService) { }

    create() {
        this.isLoading = true;
        this.deviceVersionService.create(this.deviceVersion)
            .subscribe(
                response => {
                    this.isLoading = false;
                    this.toastService.showSuccess('Device version added successfully.');
                    this.dialogRef.close(true);
                },
                error => {
                    console.log(error);
                    this.isLoading = false;
                    this.toastService.showError(error);
                }
            );
    }
}
