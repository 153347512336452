<body class="dashboard dashboard-bg">
    <app-header></app-header>

    <div class="body-container collapsed">
        <app-sidebar></app-sidebar>

        <div class="body-content update-user" style="float:left">
            <div class="row">
                <div class="col-md-12 p-0">
                    <app-page-header title="Edit Profile" iconClass="fas fa-user-edit"></app-page-header>
                </div>
            </div>

            <app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

            <div class="white-box" *ngIf="!isFormLoading">
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Username</mat-label>
                            <input matInput [(ngModel)]="user.userName" readonly class="cursor-not-allowed"
                                title="Username cannot be changed." />
                            <mat-icon matPrefix class="mr-3">
                                <i class="fas fa-user"></i>
                            </mat-icon>
                        </mat-form-field>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Company Name</mat-label>
                            <input matInput [(ngModel)]="user.companyName" [readonly]="isUser"
                                [class.cursor-not-allowed]="isUser" [required]="!isUser"
                                [title]="isUser ? 'Only admin can update Company Name.' : ''" />
                            <mat-icon matPrefix class="mr-3">
                                <i class="fas fa-building"></i>
                            </mat-icon>
                            <mat-error> Company Name is required. </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Full Name</mat-label>
                            <input matInput [(ngModel)]="user.fullName" required />
                            <mat-icon matPrefix class="mr-3">
                                <i class="fas fa-address-card"></i>
                            </mat-icon>
                            <mat-error> Full Name is required. </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Job Title</mat-label>
                            <input matInput [(ngModel)]="user.jobTitle" required />
                            <mat-icon matPrefix class="mr-3">
                                <i class="fas fa-briefcase"></i>
                            </mat-icon>
                            <mat-error>Job Title is required.</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Phone Number</mat-label>
                            <input matInput [(ngModel)]="user.phoneNumber" [required]="isPhoneNumberRequired(user.notificationPreferences)" />
                            <mat-icon matPrefix class="mr-3">
                                <i class="fas fa-mobile-alt"></i>
                            </mat-icon>
                            <mat-error> Phone Number is required. </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Time Zone</mat-label>
                            <mat-select [(ngModel)]="user.timeZoneId" required
                                (selectionChange)="onTimezoneChange(user.timeZoneId)">
                                <mat-option *ngFor="let timezone of timezones" [value]="timezone.id">
                                    {{timezone.name}} (UTC {{timezone.utcOffset}})
                                </mat-option>
                            </mat-select>
                            <mat-icon matPrefix class="mr-3">
                                <i class="fas fa-globe"></i>
                            </mat-icon>
                            <mat-error> Time Zone is required. </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Temperature Unit</mat-label>
                            <mat-select [(ngModel)]="user.temperatureUnitId" required>
                                <mat-option *ngFor="let temperatureUnit of temperatureUnits"
                                    [value]="temperatureUnit.id">
                                    {{temperatureUnit.value}}
                                </mat-option>
                            </mat-select>
                            <mat-icon matPrefix class="mr-3">
                                <i class="fas fa-temperature-high"></i>
                            </mat-icon>
                            <mat-error>Temperature Unit is required.</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Current Time</mat-label>
                            <input matInput [value]="now | date:'mediumTime':zone" readonly />
                            <mat-icon matPrefix class="mr-3">
                                <i class="fas fa-user-clock"></i>
                            </mat-icon>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>PM Unit</mat-label>
                            <mat-select [(ngModel)]="user.pmUnitId" required>
                                <mat-option *ngFor="let pmUnit of pmUnits" [value]="pmUnit.id">
                                    {{pmUnit.value}}
                                </mat-option>
                            </mat-select>
                            <mat-icon matPrefix class="mr-3">
                                <i class="fas fa-chart-area"></i>
                            </mat-icon>
                            <mat-error>PM Unit is required.</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-6 pt-4">
                        <mat-icon class="mr-3">
                            <i class="fas fa-map-marked-alt"></i>
                        </mat-icon>
                        <mat-label class="mr-3">
                            Show map on Device page
                        </mat-label>
                        <mat-slide-toggle color="primary" [(ngModel)]="user.showMap"></mat-slide-toggle>
                        <hr class="field-underline" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Notification Preferences</mat-label>
                            <mat-select [(ngModel)]="user.notificationPreferences" multiple required>
                                <mat-option *ngFor="let preferences of notificationPreferences" [value]="preferences.id">
                                    {{preferences.value}}
                                </mat-option>
                            </mat-select>
                            <mat-icon matPrefix class="mr-3">
                                <i class="fas fa-bell"></i>
                            </mat-icon>
                            <mat-error>Notification Preferences is required.</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field>
                            <mat-label>Default Map Location (Lat, Lon)</mat-label>
                            <input matInput [(ngModel)]="user.defaultMapLocation" />
                            <mat-icon matPrefix class="mr-3">
                                <i class="fas fa-map-marker-alt"></i>
                            </mat-icon>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 padding-y-10">
                        <mat-checkbox color="primary" [(ngModel)]="user.twoFactorEnabled" (change)="toggleMfa($event)">
                            Enable two factor authentication.
                        </mat-checkbox>
                        <mat-icon class="ml-1 color-primary-light" title="OTP code will be sent to email.">
                            <i class="fas fa-info-circle"></i>
                        </mat-icon>
                        <hr class="field-underline" />
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-md-12" align="end">
                        <button class="btn button-primary" mat-button (click)="save()"
                            [class.button-spinner]="isLoading" [disabled]="!user.jobTitle || !user.fullName
                            || (!isUser && !user.companyName)
                            || !user.timeZoneId || user.temperatureUnitId === undefined
                            || user.pmUnitId === undefined || !user.notificationPreferences || user.notificationPreferences.length === 0
                            || (user.notificationPreferences && user.notificationPreferences.includes(1) && !user.phoneNumber) || isLoading">SAVE</button>

                        <button class="btn button-accent ml-2" mat-button (click)="cancel()">CANCEL</button>
                    </div>
                </div>
            </div>

            <div class="clearfix"></div>

            <app-footer></app-footer>
        </div>

        <div class="clearfix"></div>
    </div>
</body>
