import { Injectable } from '@angular/core';
import { EndpointService } from './endpoint.service';
import { HttpClient } from '@angular/common/http';
import { IGridService } from '../shared/grid/grid.service';
import { DeviceVersion } from '../models/deviceVersion.model';
import { Observable, of } from 'rxjs';
import { GridModel } from '../shared/grid/grid.model';
import { catchError } from 'rxjs/operators';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root'
})
export class DeviceVersionService extends EndpointService implements IGridService<DeviceVersion> {

    constructor(http: HttpClient,
        private toastService: ToastService) {
        super(http);
    }

    get(filter: string, orderBy: string, sort: string, page: number, pageSize: number): Observable<GridModel<DeviceVersion>> {
        return this.http.get<GridModel<DeviceVersion>>(this.getApiUrl('DeviceVersion/GetDeviceVersions'), {
            params: this.createParams(filter, orderBy, sort, page, pageSize)
        });
    }

    create(deviceVersion: DeviceVersion) {
        return this.http.post(this.getApiUrl('DeviceVersion/CreateDeviceVersion'), deviceVersion);
    }

    getDeviceVersion(id: number) {
        return this.http.get<DeviceVersion>(this.getApiUrl(`DeviceVersion/GetDeviceVersion?id=${id}`))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading device version.');
                    return of(new DeviceVersion());
                })
            );
    }

    update(deviceVersion: DeviceVersion) {
        return this.http.post(this.getApiUrl('DeviceVersion/UpdateDeviceVersion'), deviceVersion);
    }

    delete(id: number) {
        return this.http.delete(this.getApiUrl(`DeviceVersion/DeleteDeviceVersion?id=${id}`));
    }

    getDeviceVersionLookupList() {
        return this.http.get<DeviceVersion[]>(this.getApiUrl('DeviceVersion/GetDeviceVersionLookupList'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading device versions.');
                    return of([]);
                })
            );
    }
}
