import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { ToastService } from './toast.service';
import { DateRange } from '../models/dateRange.model';

@Injectable()
export class TimeZoneService {
    constructor(private toastService: ToastService) { }

    createTimeZoneLabel(timeZone: string) {
        try {
            if (timeZone) {
                const utcOffset = moment.tz(timeZone).format('Z');
                return `${timeZone} (UTC ${utcOffset})`;
            }
        } catch (error) {
        }

        return 'UTC (UTC +00:00)';
    }

    createDateRange(option: string, timeZone: string): DateRange {
        const td = moment(new Date()).tz(timeZone);
        if (!td) {
            this.toastService.displayErrorMessage(`Invalid time zone: '${timeZone}'.`);
            return null;
        }

        const endDate = new Date(td.toDate().getTime() + (td.toDate().getTimezoneOffset() + td.utcOffset()) * 60 * 1000);
        const startDate = new Date(endDate.getTime());

        switch (option) {
            case '8h':
                startDate.setHours(startDate.getHours() - 8);
                break;
            case '3d':
                startDate.setDate(startDate.getDate() - 3);
                break;
            case '1m':
                startDate.setDate(startDate.getDate() - 30);
                break;
        }

        return new DateRange(startDate, endDate);
    }

    convertTimestampToLocalDate(timestamp: number, timeZone: string) {
        const td = moment(timestamp).tz(timeZone);
        return new Date(td.toDate().getTime() + (td.toDate().getTimezoneOffset() + td.utcOffset()) * 60 * 1000);
    }
}
