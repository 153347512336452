import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { DateRange, DateRangeModel } from 'src/app/models/dateRange.model';
import { Enum } from 'src/app/models/enum.model';
import { NotificationSettings, NotificationSettingsModel } from 'src/app/models/notificationSettings.model';
import { UserLookup } from 'src/app/models/userLookup.model';
import { DateFormatService } from 'src/app/services/dateFormat.service';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { NotificationSettingsService } from 'src/app/services/notificationSettings.service';
import { ToastService } from 'src/app/services/toast.service';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { TimeRangeDialogComponent } from '../timeRangeDialog/timeRangeDialog.component';

@Component({
    selector: 'app-edit-notification-settings-dialog',
    templateUrl: 'editNotificationSettingsDialog.component.html'
})
export class EditNotificationSettingsDialogComponent extends TrackableComponent implements OnInit, OnDestroy {
    isFormLoading = false;
    isLoading = false;
    parameterTypes: Enum[] = [];
    averagingTypes: Enum[] = [];
    shiftTypes: Enum[] = [];
    limitTypes: Enum[] = [];
    daysOfWeek: Enum[] = [];
    admins: UserLookup[];
    data: NotificationSettings;

    constructor(public dialogRef: MatDialogRef<EditNotificationSettingsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public inData: NotificationSettings,
        private notificationSettingsService: NotificationSettingsService,
        private dateFormatService: DateFormatService,
        private toastService: ToastService,
        featureUsageService: FeatureUsageService,
        private dialog: MatDialog
    ) {
        super(featureUsageService, FeatureUsagePageEnum.EditNotificationSettingsDialog);
        this.data = new NotificationSettings();
    }

    ngOnInit() {
        this.isFormLoading = true;
        forkJoin({
            notificationSettingsData: this.notificationSettingsService.getNotificationSettingsData(),
            notificationSettings: this.notificationSettingsService.getNotificationSetting(this.inData.id)
        }).subscribe(
            result => {
                this.parameterTypes = result.notificationSettingsData.parameterTypes;
                this.averagingTypes = result.notificationSettingsData.averagingTypes;
                this.shiftTypes = result.notificationSettingsData.shiftTypes;
                this.limitTypes = result.notificationSettingsData.limitTypes;
                this.daysOfWeek = result.notificationSettingsData.daysOfWeek;
                this.admins = result.notificationSettingsData.admins;
                this.data = result.notificationSettings;
                this.isFormLoading = false;
            },
            error => {
                console.log(error);
                this.toastService.showError(error);
                this.isFormLoading = false;
            }
        );
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    addEditInterval(shiftInterval: DateRange = null) {
        const isAdd = shiftInterval === null;
        const timeRangeDialog = this.dialog.open(TimeRangeDialogComponent, {
            data: shiftInterval || new DateRange(undefined, undefined),
            width: '450px',
            disableClose: true
        });

        timeRangeDialog.afterClosed()
            .subscribe((dateRange: DateRange) => {
                if (dateRange && dateRange.startDate && dateRange.endDate) {
                    if (isAdd) {
                        if (!this.data.shifts) {
                            this.data.shifts = [];
                        }
                        this.data.shifts.push(dateRange);
                    } else {
                        shiftInterval = dateRange;
                    }
                }
            });
    }

    deleteInterval(shiftInterval: DateRange) {
        this.data.shifts.splice(this.data.shifts.indexOf(shiftInterval), 1);
    }

    areShiftsEmpty() {
        return !this.data.shifts || this.data.shifts.length < 1;
    }

    isDisabled() {
        return !this.data || !this.data.name || this.data.parameterType === undefined || this.data.averagingType === undefined ||
            this.data.threshold === undefined || !this.data.shifts || this.data.shifts.length < 1 ||
            !this.data.workingDays || this.data.workingDays.length < 1 ||
            (this.data.averagingType !== 0 && this.data.shiftType === undefined) ||
            this.data.limit === undefined || this.data.limitType === undefined ||
            !this.data.subscribers || this.data.subscribers.length < 1;
    }

    save() {
        this.isLoading = true;

        const payload = new NotificationSettingsModel();
        payload.id = this.data.id;
        payload.name = this.data.name;
        payload.parameterType = this.data.parameterType;
        payload.averagingType = this.data.averagingType;
        payload.shiftType = this.data.shiftType;
        if (this.data.shifts) {
            payload.shifts = [];
            this.data.shifts.forEach(s => {
                const dateRangeModel = new DateRangeModel();
                dateRangeModel.startDate = this.dateFormatService.formatDateTimeToString(s.startDate);
                dateRangeModel.endDate = this.dateFormatService.formatDateTimeToString(s.endDate);
                payload.shifts.push(dateRangeModel);
            });
        }
        payload.limit = this.data.limit;
        payload.limitType = this.data.limitType;
        payload.threshold = this.data.threshold;
        payload.workingDays = this.data.workingDays;
        payload.subscribers = this.data.subscribers;
        payload.isEnabled = this.data.isEnabled;

        this.notificationSettingsService.updateNotificationSettings(payload)
            .subscribe(response => {
                this.isLoading = false;
                this.toastService.showSuccess('Notification settings saved successfully.');
                this.dialogRef.close(true);
            }, error => {
                this.isLoading = false;
                console.log(error);
                this.toastService.showError(error);
            });

        this.recordAction(FeatureUsageActionEnum.UpdateNotificationSettingsClick);
    }

    onClose() {
        this.recordAction(FeatureUsageActionEnum.CancelUpdateNotificationSettingsClick);
    }
}
