<div class="top-header">
    <div class="header-content">
        <mat-form-field>
            <mat-label *ngIf="label">{{label}}</mat-label>
            <mat-select [(ngModel)]="itemId" (selectionChange)="onSelectionChanged($event)" required>
                <mat-option *ngFor="let item of items" [value]="item.id">
                    <mat-icon *ngIf="item.icon" matPrefix>
                        <i [class]="item.icon"></i>
                    </mat-icon>
                    {{item.name}}
                </mat-option>
            </mat-select>
            <mat-icon matPrefix class="mr-4">
                <i [class]="iconClass"></i>
            </mat-icon>
        </mat-form-field>
    </div>
</div>
