export class DateRangeModel {
    startDate: string;
    endDate: string;
}

export class DateRange {
    startDate: Date;
    endDate: Date;

    constructor(sDate: Date, eDate: Date) {
        this.startDate = sDate;
        this.endDate = eDate;
    }
}
