import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorCodeEnum } from '../enums/errorCode.enum';
import { FeatureUsagePageEnum } from '../enums/featureUsagePage.enum';
import { SecurityProfileEnum } from '../enums/securityProfile.enum';
import { ErrorLog } from '../models/errorLog.model';
import { FeatureUsageAction } from '../models/featureUsageAction.model';
import { FeatureUsageActivity } from '../models/featureUsageActivity.model';
import { AuthService } from './auth.service';
import { EndpointService } from './endpoint.service';

@Injectable({
    providedIn: 'root'
})
export class FeatureUsageService extends EndpointService {

    constructor(http: HttpClient,
        private authService: AuthService) {
        super(http);
    }

    recordActivity(page: FeatureUsagePageEnum, timestampStart: number, actions: FeatureUsageAction[], errors: ErrorLog[]) {
        const currentUser = this.authService.getCurrentUser();
        if (currentUser && !currentUser.isImpersonated && currentUser.userType !== SecurityProfileEnum.SuperAdmin) {
            const payload = new FeatureUsageActivity(page, timestampStart, actions, errors);
            return this.http.post(this.getApiUrl('FeatureUsage/RecordActivity'), payload)
                .pipe(
                    catchError(error => {
                        console.log(error);
                        return of();
                    })
                );
        }

        return of();
    }

    recordError(errorCode: ErrorCodeEnum, message: string) {
        const payload = new ErrorLog(errorCode, message);
        return this.http.post(this.getApiUrl('FeatureUsage/LogError'), payload)
            .pipe(
                catchError(error => {
                    console.log(error);
                    return of();
                })
            );
    }
}
