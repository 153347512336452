import { CustomObjectTypeEnum } from '../enums/customObjectType.enum';

export class CustomObject {
    id: number;
    name: string;
    type: CustomObjectTypeEnum;
    typeName: string;
    icon: string;
}

export class CustomObjectModel {
    name: string;
    type: CustomObjectTypeEnum;
    typeName: string;
    icon: string;
}
