import { Injectable } from '@angular/core';
import { EndpointService } from './endpoint.service';
import { HttpClient } from '@angular/common/http';
import { IGridService } from '../shared/grid/grid.service';
import { Observable, of } from 'rxjs';
import { GridModel } from '../shared/grid/grid.model';
import { catchError } from 'rxjs/operators';
import { ToastService } from './toast.service';
import { NotificationSettings, NotificationSettingsData, NotificationSettingsModel } from '../models/notificationSettings.model';
import { NotificationAssignmentsModel } from '../models/notificationAssignments.model';

@Injectable({
    providedIn: 'root'
})
export class NotificationSettingsService extends EndpointService implements IGridService<NotificationSettings> {

    constructor(http: HttpClient,
        private toastService: ToastService) {
        super(http);
    }

    get(filter: string, orderBy: string, sort: string, page: number, pageSize: number): Observable<GridModel<NotificationSettings>> {
        return this.http.get<GridModel<NotificationSettings>>(this.getApiUrl('NotificationSettings/GetNotificationSettings'), {
            params: this.createParams(filter, orderBy, sort, page, pageSize)
        });
    }

    getNotificationSetting(id: number): Observable<NotificationSettings> {
        return this.http.get<NotificationSettings>(this.getApiUrl(`NotificationSettings/GetNotificationSetting?id=${id}`))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading notification settings.');
                    return of(new NotificationSettings());
                })
            );
    }

    getNotificationSettingsData(): Observable<NotificationSettingsData> {
        return this.http.get<NotificationSettingsData>(this.getApiUrl('NotificationSettings/GetNotificationSettingsData'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading notification settings data.');
                    return of(new NotificationSettingsData());
                })
            );
    }

    getNotificationAssignments(id: number): Observable<NotificationAssignmentsModel> {
        return this.http.get<NotificationAssignmentsModel>(this.getApiUrl(`NotificationSettings/GetNotificationAssignments?id=${id}`))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading notification assignments.');
                    return of(new NotificationAssignmentsModel());
                })
            );
    }

    addNotificationSettings(payload: NotificationSettingsModel) {
        return this.http.post(this.getApiUrl('NotificationSettings/AddNotificationSettings'), payload);
    }

    updateNotificationSettings(payload: NotificationSettingsModel) {
        return this.http.post(this.getApiUrl('NotificationSettings/UpdateNotificationSettings'), payload);
    }

    deleteNotificationSettings(id: number) {
        return this.http.delete(this.getApiUrl(`NotificationSettings/DeleteNotificationSettings?id=${id}`));
    }

    cloneNotificationSettings(payload: NotificationSettingsModel) {
        return this.http.post(this.getApiUrl('NotificationSettings/CloneNotificationSettings'), payload);
    }

    saveNotificationAssignments(payload: NotificationAssignmentsModel) {
        return this.http.post(this.getApiUrl('NotificationSettings/SaveNotificationAssignments'), payload);
    }
}
