<body class="dashboard dashboard-bg">
    <app-header></app-header>

    <div class="body-container collapsed">
        <app-sidebar></app-sidebar>

        <div class="body-content">
            <div class="row">
                <div class="col p-0">
                    <app-page-header title="Dashboard" iconClass="material-icons" iconText="dashboard">
                    </app-page-header>
                </div>
            </div>

            <app-progress-spinner [show]="isLoading"></app-progress-spinner>

            <div class="white-box">
                <div class="row">
                    <div class="col">
                        <h5 class="mb-3">Sensor Location</h5>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-9">
                        <agm-map [maxZoom]="22" [mapTypeControl]="true" [styles]="agmMapStyles" (idle)="onMapIdle()">
                            <app-map-clusterer></app-map-clusterer>
                        </agm-map>
                    </div>

                    <div class="col-lg-3 mt-2">
                        <mat-label class="clickable mr-3" (click)="showSensors(false)"
                            [class.selected-toggle-label]="!isViewBySensor">End Points
                        </mat-label>
                        <mat-slide-toggle *ngIf="userType !== SecurityProfileEnum.User" [(ngModel)]="isViewBySensor"
                            (change)="toggleSensors($event)">
                        </mat-slide-toggle>
                        <mat-label *ngIf="userType !== SecurityProfileEnum.User" class="clickable ml-3"
                            [class.selected-toggle-label]="isViewBySensor" (click)="showSensors(true)">Sensors
                        </mat-label>
                        <hr class="toggle-underline" />

                        <div class="dashboard-toggle">
                            <mat-nav-list *ngIf="!isViewBySensor" [@leftSlideInAnimation]>
                                <mat-list-item *ngIf="!endPoints || endPoints.length < 1">
                                    N/A
                                </mat-list-item>
                                <a mat-list-item *ngFor="let endPoint of endPoints"
                                    [routerLink]="['/end-point-view/' + endPoint.id]" [title]="endPoint.name">
                                    <mat-icon class="dashboard-icon mr-3"
                                        [class.status-offline]="endPoint.statusId === SensorStatusEnum.Offline">
                                        <i [class]="endPoint.icon"></i>
                                    </mat-icon>
                                    <mat-label class="dashboard-label">{{endPoint.name}}</mat-label>
                                </a>
                            </mat-nav-list>
                            <mat-nav-list *ngIf="isViewBySensor" [@rightSlideInAnimation]>
                                <mat-list-item *ngIf="!sensors || sensors.length < 1">
                                    N/A
                                </mat-list-item>
                                <a mat-list-item *ngFor="let sensor of sensors" [routerLink]="['/sensor']"
                                    (click)="setSensorId(sensor.id)" [title]="sensor.name">
                                    <mat-icon class="dashboard-icon mr-3"
                                        [class.status-offline]="sensor.statusId === SensorStatusEnum.Offline">
                                        <i class="fas fa-broadcast-tower"></i>
                                    </mat-icon>
                                    <mat-label class="dashboard-label">{{sensor.name}}</mat-label>
                                </a>
                            </mat-nav-list>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field>
                            <mat-label>From Date</mat-label>
                            <input matInput [(ngModel)]="fromDate" class="clickable" [owlDateTime]="dtStart"
                                [owlDateTimeTrigger]="dtStart" required (click)="onDateFilterClick()" />
                            <mat-icon matPrefix class="clickable mr-3" [owlDateTimeTrigger]="dtStart"
                                (click)="onDateFilterClick()">
                                <i class="fas fa-calendar-alt"></i>
                            </mat-icon>
                            <mat-error>From Date is required.</mat-error>
                            <owl-date-time #dtStart></owl-date-time>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field>
                            <mat-label>To Date</mat-label>
                            <input matInput [(ngModel)]="toDate" class="clickable" [owlDateTime]="dtEnd"
                                [owlDateTimeTrigger]="dtEnd" required (click)="onDateFilterClick()" />
                            <mat-icon matPrefix class="clickable mr-3" [owlDateTimeTrigger]="dtEnd"
                                (click)="onDateFilterClick()">
                                <i class="fas fa-calendar-alt"></i>
                            </mat-icon>
                            <mat-error>To Date is required.</mat-error>
                            <owl-date-time #dtEnd></owl-date-time>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-3">
                        <button mat-button class="btn button-secondary mt-3 mb-3" [class.button-spinner]="isMapLoading"
                            (click)="search()" [disabled]="!fromDate || !toDate || isMapLoading">Search</button>
                    </div>

                    <div class="col-lg-3">
                        <mat-form-field>
                            <mat-label>Time Zone</mat-label>
                            <input matInput [(ngModel)]="timeZone" readonly />
                            <mat-icon matPrefix class="mr-3">
                                <i class="fas fa-globe"></i>
                            </mat-icon>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="clearfix"></div>
            <app-footer></app-footer>
        </div>

        <div class="clearfix"></div>
    </div>
</body>
