<h4 class="dialog-title text-center">Edit Sensor Assignment</h4>

<hr />

<app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>End Point</mat-label>
                    <mat-select [(ngModel)]="sensorAssignment.endPointId" required>
                        <mat-option *ngFor="let endPoint of endPoints" [value]="endPoint.id">
                            {{endPoint.name}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-desktop"></i>
                    </mat-icon>
                    <mat-error>End Point is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Sensor</mat-label>
                    <mat-select [(ngModel)]="sensorAssignment.sensorId" required>
                        <mat-option *ngFor="let sensor of sensors" [value]="sensor.id">
                            {{sensor.name}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-broadcast-tower"></i>
                    </mat-icon>
                    <mat-error>Sensor is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>From Date</mat-label>
                    <input matInput [(ngModel)]="sensorAssignment.startDate" class="clickable" [owlDateTime]="dtStart" [owlDateTimeTrigger]="dtStart" required />
                    <mat-icon matPrefix class="clickable mr-3" [owlDateTimeTrigger]="dtStart">
                        <i class="fas fa-calendar-alt"></i>
                    </mat-icon>
                    <mat-error>From Date is required.</mat-error>
                    <owl-date-time #dtStart></owl-date-time>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>To Date</mat-label>
                    <input matInput [(ngModel)]="sensorAssignment.endDate" class="clickable" [owlDateTime]="dtEnd" [owlDateTimeTrigger]="dtEnd" required />
                    <mat-icon matPrefix class="clickable mr-3" [owlDateTimeTrigger]="dtEnd">
                        <i class="fas fa-calendar-alt"></i>
                    </mat-icon>
                    <mat-error>To Date is required.</mat-error>
                    <owl-date-time #dtEnd></owl-date-time>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Time Zone</mat-label>
                    <input matInput [(ngModel)]="timeZone" readonly />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-globe"></i>
                    </mat-icon>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button class="btn button-primary" (click)="save()" [class.button-spinner]="isLoading"
            [disabled]="!sensorAssignment.endPointId || !sensorAssignment.sensorId || !sensorAssignment.startDate || !sensorAssignment.endDate || isLoading">
        Save </button>

        <button mat-button class="btn button-accent" mat-dialog-close (click)="onClose()">Cancel</button>
    </div>
</div>
