import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export class EndpointService {

    constructor(protected http: HttpClient) { }

    protected getApiUrl(url) {
        return `${environment.apiUrl}/${url}`;
    }

    protected createParams(filter: string, orderBy: string, sort: string, page: number, pageSize: number) {
        return new HttpParams()
            .set('filter', filter)
            .set('orderBy', orderBy)
            .set('sort', sort)
            .set('page', page.toString())
            .set('pageSize', pageSize.toString());
    }
}
