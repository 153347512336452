import { Injectable } from '@angular/core';
import { EndpointService } from './endpoint.service';
import { HttpClient } from '@angular/common/http';
import { TenantPermissions } from '../models/tenantPermissions.model';

@Injectable({
    providedIn: 'root'
})
export class TenantPermissionsService extends EndpointService {

    constructor(http: HttpClient) {
        super(http);
    }

    getTenantPermissions(id: number) {
        return this.http.get<TenantPermissions>(this.getApiUrl(`TenantPermissions/GetTenantPermissions?id=${id}`));
    }

    saveTenantPermissions(tenantPermissions: TenantPermissions) {
        return this.http.post(this.getApiUrl('TenantPermissions/SaveTenantPermissions'), tenantPermissions);
    }
}
