import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { TenantSensorService } from 'src/app/services/tenantSensor.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateTenantSensorDialogComponent } from '../createTenantSensorDialog/createTenantSensorDialog.component';
import { EditTenantSensorDialogComponent } from '../editTenantSensorDialog/editTenantSensorDialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/confirmDialog/confirmDialog.component';
import { TenantSensor } from 'src/app/models/tenantSensor.model';
import { ConfigureSensorDialogComponent } from '../configureSensorDialog/configureSensorDialog.component';
import { Router } from '@angular/router';
import { TimeZoneService } from 'src/app/services/timeZone.service';
import { AuthService } from 'src/app/services';
import { GridBaseComponent } from 'src/app/shared/grid/grid.component';

@Component({
    selector: 'app-tenant-sensor-list',
    templateUrl: 'tenantSensorList.component.html',
    styleUrls: ['tenantSensorList.component.css']
})
export class TenantSensorListComponent extends GridBaseComponent<TenantSensor> implements OnInit, AfterViewInit {

    constructor(protected tenantSensorService: TenantSensorService,
        private router: Router,
        toastService: ToastService,
        authService: AuthService,
        timeZoneService: TimeZoneService,
        dialog: MatDialog
    ) {
        super(toastService, authService, timeZoneService, dialog);
        this.gridService = tenantSensorService;
        this.displayedColumns = ['Tenant.Name', 'name', 'id', 'DeviceVersion.Version', 'status', 'imeI_No', 'latitude', 'longitude', 'installationDate', 'gpsCapability', 'tableNames', 'videoCapability', 'density', 'configurationCapability', 'action'];
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    setSensorId(sensor: TenantSensor) {
        const encSensorId = window.btoa(sensor.id);
        localStorage.setItem('deviceName', encSensorId);
        this.router.navigate(['/sensor']);
    }

    add() {
        this.openDialog(CreateTenantSensorDialogComponent, {});
    }

    edit(sensor: TenantSensor) {
        this.openDialog(EditTenantSensorDialogComponent, { id: sensor.id });
    }

    configure(sensor: TenantSensor) {
        this.openDialog(ConfigureSensorDialogComponent, { id: sensor.id });
    }

    delete(sensor: TenantSensor) {
        this.openDeleteDialog(
            ConfirmDialogComponent,
            {
                title: 'Delete Sensor',
                message: `Are you sure you want to delete sensor ${sensor.id}?`,
                primaryButtonText: 'Delete',
                primaryCallback: () => this.tenantSensorService.deleteTenantSensor(sensor.id),
                successMessage: 'Sensor deleted successfully!',
                secondaryButtonText: 'Cancel'
            }
        );
    }
}
