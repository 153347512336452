import { Injectable } from '@angular/core';
import { UserSignupModel } from '../models/userSignup.model';
import { HttpClient } from '@angular/common/http';
import { EndpointService } from './endpoint.service';
import { IGridService } from '../shared/grid/grid.service';
import { Signup } from '../models/signup.model';
import { GridModel } from '../shared/grid/grid.model';

@Injectable({
    providedIn: 'root'
})
export class SignupService extends EndpointService implements IGridService<Signup> {

    constructor(http: HttpClient) {
        super(http);
    }

    registerUser(user: UserSignupModel) {
        return this.http.post(this.getApiUrl('PreSignup/RegisterUser'), user);
    }

    get(filter: string, orderBy: string, sort: string, page: number, pageSize: number) {
        return this.http.get<GridModel<Signup>>(this.getApiUrl('PreSignup/GetPreSignupUserList'), {
            params: this.createParams(filter, orderBy, sort, page, pageSize)
        });
    }

    approveUser(id: number) {
        return this.http.get(this.getApiUrl(`PreSignup/ApproveUser?id=${id}`));
    }

    declineUser(id: number) {
        return this.http.get(this.getApiUrl(`PreSignup/DeclineUser?id=${id}`));
    }
}
