import { Component, OnInit, Inject } from '@angular/core';
import { TenantLookup } from 'src/app/models/tenantLookup.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TenantSensor } from 'src/app/models/tenantSensor.model';
import { TenantSensorService } from 'src/app/services/tenantSensor.service';
import { TenantService } from 'src/app/services/tenant.service';
import { ToastService } from 'src/app/services/toast.service';
import { DateFormatService } from 'src/app/services/dateFormat.service';
import { DeviceVersion } from 'src/app/models/deviceVersion.model';
import { DeviceVersionService } from 'src/app/services/deviceVersionService';
import { forkJoin } from 'rxjs';

export interface EditTenantSensorDialogData {
    id: string;
}

@Component({
    selector: 'app-edit-tenant-sensor-dialog',
    templateUrl: 'editTenantSensorDialog.component.html',
    styleUrls: ['editTenantSensorDialog.component.css']
})
export class EditTenantSensorDialogComponent implements OnInit {

    isFormLoading = false;
    isLoading = false;
    tenants: TenantLookup[];
    deviceVersions: DeviceVersion[];
    sensor: TenantSensor;
    tableNames = ['particulate-timeseries', 'opc-r2', 'noise-timeseries', 'AirNowMonitoringSiteObservations', 'GasData'];

    constructor(public dialogRef: MatDialogRef<EditTenantSensorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EditTenantSensorDialogData,
        private tenantSensorService: TenantSensorService,
        private tenantService: TenantService,
        private deviceVersionService: DeviceVersionService,
        private dateFormatService: DateFormatService,
        private toastService: ToastService) {
        this.sensor = new TenantSensor();
    }

    ngOnInit() {
        this.isFormLoading = true;
        forkJoin({
            tenants: this.tenantService.getTenantLookupList(),
            deviceVersions: this.deviceVersionService.getDeviceVersionLookupList(),
            sensor: this.tenantSensorService.getTenantSensor(this.data.id)
        }).subscribe(
            result => {
                this.tenants = result.tenants;
                this.deviceVersions = result.deviceVersions;
                this.sensor = result.sensor;
                this.isFormLoading = false;
            },
            error => {
                console.log(error);
                this.toastService.showError(error);
                this.isFormLoading = false;
            }
        );
    }

    save() {
        const latitude = this.sensor.latitude;
        if (latitude !== undefined && (latitude < -90 || latitude > 90)) {
            this.toastService.showWarning('Latitude must be between -90 and 90.');
            return;
        }

        const longitude = this.sensor.longitude;
        if (longitude !== undefined && (longitude < -180 || longitude > 180)) {
            this.toastService.showWarning('Longitude must be between -180 and 180.');
            return;
        }

        this.isLoading = true;
        this.sensor.installDate = this.dateFormatService.formatDateTimeToString(this.sensor.installationDate);
        this.tenantSensorService.updateTenantSensor(this.sensor)
            .subscribe(response => {
                this.toastService.showSuccess('Sensor saved successfully.');
                this.isLoading = false;
                this.dialogRef.close(true);
            }, error => {
                console.log(error);
                this.toastService.showError(error);
                this.isLoading = false;
            });
    }
}
