<header>
    <nav class="navbar navbar-expand-sm bg-white navbar-light">
        <a class="navbar-brand">
            <img src="./assets/images/logo.png">
        </a>

        <a *ngIf="tenantLogoUrl" class="navbar-brand ml-auto">
            <img [src]="tenantLogoUrl">
        </a>

        <ul class="navbar-nav ml-auto align-items-center profile-section">
            <li *ngIf="isAdmin && canViewNotifications" class="nav-item">
                <button type="button" mat-button title="Notifications" [routerLink]="['/notifications']" (click)="onHeaderButtonClick(FeatureUsageActionEnum.NewNotificationCountClick)">
                    <a class="header-icon header-icon-accent nav-link rounded-circle">
                        <i class="fas fa-bell fa-lg"></i>
                    </a>
                    <label class="ml-1 color-accent">{{newNotificationCount}}</label>
                </button>
            </li>

            <li class="nav-item">
                <button type="button" mat-button [matMenuTriggerFor]="headerMenu" (click)="onHeaderButtonClick(FeatureUsageActionEnum.HeaderMenuClick)">
                    <a class="header-icon header-icon-secondary nav-link rounded-circle">
                        <i class="fas fa-user fa-lg"></i>
                    </a>
                    <span class="header-button-text ml-2">{{userEmail}}</span>
                </button>
            </li>

            <mat-menu #headerMenu="matMenu" xPosition="before">
                <button mat-menu-item [routerLink]="['/updateprofile']" (click)="onHeaderButtonClick(FeatureUsageActionEnum.HeaderEditProfileClick)">
                    <mat-icon class="menu-item-icon mb-2">
                        <i class="fas fa-user-edit fa-sm"></i>
                    </mat-icon>
                    <span>Edit Profile</span>
                </button>

                <button mat-menu-item [routerLink]="['/changepassword']" (click)="onHeaderButtonClick(FeatureUsageActionEnum.HeaderChangePasswordClick)">
                    <mat-icon class="menu-item-icon mb-2">
                        <i class="fas fa-user-lock fa-sm"></i>
                    </mat-icon>
                    <span>Change Password</span>
                </button>

                <button mat-menu-item (click)="logout()">
                    <mat-icon class="menu-item-icon mb-2">
                        <i class="fas fa-sign-out-alt fa-sm"></i>
                    </mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>
        </ul>
    </nav>
</header>
