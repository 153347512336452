import { Component, OnDestroy, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { MapClustererComponent } from '../../shared/map/map-clusterer.component';
import { DateFormatService } from '../../services/dateFormat.service';
import { SensorRecordService } from 'src/app/services/sensorRecord.service';
import { forkJoin, of, Subscription } from 'rxjs';
import { SensorRecord } from 'src/app/models/sensorRecord.model';
import { TimeZoneService } from 'src/app/services/timeZone.service';
import { DateRangeModel } from 'src/app/models/dateRange.model';
import { AuthService } from 'src/app/services';
import { SensorStatusEnum } from 'src/app/enums/status.enum';
import { SensorStatusModel } from 'src/app/models/sensorStatus.model';
import { rightSlideInAnimation } from 'src/app/animations/rightSlideIn.animation';
import { leftSlideInAnimation } from 'src/app/animations/leftSlideIn.animation';
import { UserPreferences } from 'src/app/models/userPreferences.model';
import { PmUnitEnum } from 'src/app/enums/pmUnit.enum';
import { TemperatureUnitEnum } from 'src/app/enums/temperatureUnit.enum';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { UserPreferencesService } from 'src/app/services/userPreferences.service';
import { ErrorCodeEnum } from 'src/app/enums/errorCode.enum';
import { EventService } from 'src/app/services/event.service';
import { EndPointStatusModel } from 'src/app/models/endPointStatus.model';
import { SecurityProfileEnum } from 'src/app/enums/securityProfile.enum';
import { AirNowService } from 'src/app/services/airNow.service';
import { MonitoringSite } from 'src/app/models/monitoringSite.model';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    animations: [rightSlideInAnimation, leftSlideInAnimation]
})
export class DashboardComponent extends TrackableComponent implements OnInit, OnDestroy {

    sensors: SensorStatusModel[];
    endPoints: EndPointStatusModel[];
    timeZone: string;
    isLoading = false;
    isMapLoading = false;
    fromDate: Date;
    toDate: Date;
    isViewBySensor = false;
    payload: DateRangeModel;
    preferences: UserPreferences;
    SensorStatusEnum = SensorStatusEnum;
    agmMapStyles: any;
    shouldRecordMapInteraction = false;
    markerClickSubscription: Subscription;
    intervalId: any;
    userType: SecurityProfileEnum;
    SecurityProfileEnum = SecurityProfileEnum;
    allowAirnowDevices = false;
    monitoringSites: MonitoringSite[];

    @ViewChild(MapClustererComponent, { static: true }) mapClustererComponent: MapClustererComponent;

    constructor(private sensorRecordService: SensorRecordService,
        private toastService: ToastService,
        private dateFormatService: DateFormatService,
        timeZoneService: TimeZoneService,
        authService: AuthService,
        featureUsageService: FeatureUsageService,
        private userPreferencesService: UserPreferencesService,
        private eventService: EventService,
        private airNowService: AirNowService) {

        super(featureUsageService, FeatureUsagePageEnum.DashboardPage);

        this.payload = new DateRangeModel();
        const currentUser = authService.getCurrentUser();
        this.userType = currentUser.userType;
        this.isViewBySensor = this.userType !== SecurityProfileEnum.User
            ? currentUser.preferences.isViewBySensor
            : false;
        this.preferences = currentUser.preferences;
        this.timeZone = timeZoneService.createTimeZoneLabel(currentUser.userTimeZone);
        this.agmMapStyles = [{
            featureType: 'poi',
            stylers: [
                { visibility: 'off' }
            ]
        }];
        this.markerClickSubscription = this.eventService.on('markerClick')
            .subscribe((action: FeatureUsageActionEnum) => this.recordAction(action));
        this.allowAirnowDevices = currentUser.tenantPermissions.allowAirnowDevices;
    }

    ngOnInit() {
        this.isLoading = true;
        forkJoin({
            sensors: this.sensorRecordService.getSensorStatusModels(),
            endPoints: this.sensorRecordService.getEndPointStatusModels(),
            sensorRecords: this.isViewBySensor
                ? this.sensorRecordService.getLatestSensorRecordsBySensor(this.payload)
                : this.sensorRecordService.getLatestSensorRecordsByEndPoint(this.payload),
            monitoringSites: this.allowAirnowDevices && this.userType === SecurityProfileEnum.Admin
                ? this.airNowService.getMonitoringSites()
                : of([])
        }).subscribe(
            result => {
                this.sensors = result.sensors;
                this.monitoringSites = result.monitoringSites;
                if (this.sensors.length === 0) {
                    this.toastService.showMessage('Sensors are not assigned to your tenant.');
                }
                this.endPoints = this.setEndPoints(result.endPoints, result.sensors);
                this.drawMap(result.sensorRecords);
                this.intervalId = setInterval(() => {
                    this.isLoading = true;
                    this.getLatestSensorRecords(this.payload);
                }, 60000);
                this.isLoading = false;
            },
            error => {
                console.log(error);
                this.toastService.showError(error);
                this.isLoading = false;
            }
        );
    }

    ngOnDestroy() {
        clearInterval(this.intervalId);

        if (this.markerClickSubscription && !this.markerClickSubscription.closed) {
            this.markerClickSubscription.unsubscribe();
        }

        this.recordActivity();
    }

    setEndPoints(endPoints: EndPointStatusModel[], sensors: SensorStatusModel[]) {
        endPoints.forEach(endPoint => {
            if (endPoint.deviceId) {
                const sensor = sensors.find(x => x.id === endPoint.deviceId);
                if (sensor) {
                    endPoint.statusId = sensor.statusId;
                }
            }
        });

        return endPoints;
    }

    search() {
        if (this.fromDate > this.toDate) {
            const message = 'Invalid date: From Date must be less than To Date.';
            this.toastService.showWarning(message);
            this.recordError(ErrorCodeEnum.Validation, message);
            return;
        }

        this.isMapLoading = true;
        this.payload.startDate = this.dateFormatService.formatDateTimeToString(this.fromDate);
        this.payload.endDate = this.dateFormatService.formatDateTimeToString(this.toDate);
        clearInterval(this.intervalId);
        this.getLatestSensorRecords(this.payload);
        this.recordAction(this.isViewBySensor
            ? FeatureUsageActionEnum.DashboardSensorSearch
            : FeatureUsageActionEnum.DashboardUserSearch);
    }

    showSensors(show: boolean) {
        if (show === this.isViewBySensor) {
            return;
        }
        this.isViewBySensor = show;
        this.toggleSensors(null);
    }

    toggleSensors(event: any) {
        this.isLoading = true;
        this.getLatestSensorRecords(this.payload);
        this.recordAction(this.isViewBySensor
            ? FeatureUsageActionEnum.DashboardSensorsToggle
            : FeatureUsageActionEnum.DashboardUsersToggle);
        this.userPreferencesService.setViewBySensor(this.isViewBySensor)
            .subscribe();
    }

    getLatestSensorRecords(payload) {
        const observable = this.isViewBySensor
            ? this.sensorRecordService.getLatestSensorRecordsBySensor(payload)
            : this.sensorRecordService.getLatestSensorRecordsByEndPoint(payload);

        observable.subscribe(
            sensorRecords => {
                this.drawMap(sensorRecords);
                this.isLoading = false;
                this.isMapLoading = false;
            },
            error => {
                console.log(error);
                this.toastService.displayErrorMessage('Error loading markers.');
                this.isLoading = false;
                this.isMapLoading = false;
            });
    }

    private drawMap(sensorRecords: SensorRecord[]) {
        this.shouldRecordMapInteraction = false;
        const convertToMgPerM3 = this.preferences.pmUnitId === PmUnitEnum.MgPerM3;
        const convertToFahrenheit = this.preferences.temperatureUnitId === TemperatureUnitEnum.Fahrenheit;
        sensorRecords.forEach(record => {
            if (convertToMgPerM3) {
                record.pm1 = record.pm1 * 0.001;
                record.pm2_5 = record.pm2_5 * 0.001;
                record.pm10 = record.pm10 * 0.001;
            }
            if (convertToFahrenheit) {
                record.tempC = record.tempC * 1.8 + 32;
            }
        });
        this.mapClustererComponent.updateMarkerClusterer(sensorRecords, this.sensors, FeatureUsagePageEnum.DashboardPage,
            this.payload.startDate !== undefined, this.monitoringSites);
    }

    setSensorId(sensorId: string) {
        const encDeviceName = window.btoa(sensorId);
        localStorage.setItem('deviceName', encDeviceName);
        this.recordAction(this.isViewBySensor
            ? FeatureUsageActionEnum.DashboardSensorClick
            : FeatureUsageActionEnum.DashboardUserClick);
    }

    onMapIdle() {
        if (!this.shouldRecordMapInteraction) {
            this.shouldRecordMapInteraction = true;
            return;
        }
        this.recordAction(FeatureUsageActionEnum.MapInteraction);
    }

    onDateFilterClick() {
        this.recordAction(FeatureUsageActionEnum.DateFilterClick);
    }
}
