import { TenantPermissions } from './tenantPermissions.model';
import { UserPreferences } from './userPreferences.model';

export class UserIdentity {
    userType: number;
    userEmail: string;
    tenantPermissions: TenantPermissions;
    userTimeZone: string;
    tenantLogoUrl: string;
    preferences: UserPreferences;
    isImpersonated: boolean;
}
