import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface Event {
    key: string;
    value: any;
}

@Injectable({
    providedIn: 'root'
})
export class EventService {
    private eventSubject = new Subject<Event>();

    public broadcast(key: string, value: any) {
        this.eventSubject.next({ key, value });
    }

    public on(key: string) {
        return this.eventSubject.asObservable()
            .pipe(
                filter(e => e.key === key),
                map(e => e.value)
            );
    }
}
