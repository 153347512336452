import { Component, OnInit } from '@angular/core';
import { ResetPasswordModel } from 'src/app/models/resetPassword.model';
import { AccountService } from 'src/app/services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';

@Component({
    selector: 'app-updateuserpassword',
    templateUrl: './updateuserpassword.component.html',
    styleUrls: ['./updateuserpassword.component.css']
})
export class UpdateUserPasswordComponent implements OnInit {

    isFormLoading = false;
    isLoading = false;
    model: ResetPasswordModel;
    show1: boolean;
    show2: boolean;

    constructor(private accountService: AccountService,
        private toastService: ToastService,
        private route: ActivatedRoute,
        private router: Router) {

        this.isFormLoading = true;
        this.model = new ResetPasswordModel();
    }

    ngOnInit() {
        this.model.id = this.route.snapshot.params.id;
        this.model.token = this.route.snapshot.queryParams.token;
        this.isFormLoading = false;

        if (!this.model.id || !this.model.token) {
            this.router.navigate(['/forgotpassword']);
            setTimeout(() => this.toastService.showWarning('Expired token. Please create a new Reset password request.'), 0);
        }
    }

    submit() {
        this.isLoading = true;
        this.accountService.resetPassword(this.model)
            .subscribe(res => {
                this.toastService.showSuccess('Your password has been changed. Please login.');
                this.isLoading = false;
                this.router.navigate(['login']);
            }, error => {
                console.log(error);
                this.toastService.showError(error);
                this.isLoading = false;
            });
    }
}
