import { Component, NgZone, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_kelly from '@amcharts/amcharts4/themes/kelly';
import { SensorRecordService } from 'src/app/services/sensorRecord.service';
import { ToastService } from 'src/app/services/toast.service';
import { DateFormatService } from 'src/app/services/dateFormat.service';
import { AuthService } from 'src/app/services';
import { ChartService } from 'src/app/services/chart.service';
import { SensorService } from 'src/app/services/sensor.service';
import { Sensor } from 'src/app/models/sensor.model';
import { forkJoin, Observable } from 'rxjs';
import { Enum } from 'src/app/models/enum.model';
import { TimeIntervalEnum } from 'src/app/enums/timeInterval.enum';
import { MatDialog } from '@angular/material/dialog';
import { TimeRangeDialogComponent } from '../timeRangeDialog/timeRangeDialog.component';
import { DateRange } from 'src/app/models/dateRange.model';
import { DataAnalysisRequestModel } from 'src/app/models/dataAnalysisRequest.model';
import { ParameterTypeEnum } from 'src/app/enums/parameterType.enum';
import { SensorAnalysisRecord } from 'src/app/models/sensorAnalysisRecord.model';
import { UserPreferences } from 'src/app/models/userPreferences.model';
import { PmUnitEnum } from 'src/app/enums/pmUnit.enum';
import { TemperatureUnitEnum } from 'src/app/enums/temperatureUnit.enum';
import { DataAnalysisExportModel } from 'src/app/models/dataAnalysisExport.model';
import { DeviceValue } from 'src/app/models/deviceValue.model';
import { OnZoom } from '../chartBase/onZoom.interface';
import { SensorRecord } from 'src/app/models/sensorRecord.model';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { ResizedEvent } from 'angular-resize-event';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { TimeZoneService } from 'src/app/services/timeZone.service';
import { EndPointSensorLookup } from 'src/app/models/endPointSensorLookup.model';
import { Lookup } from 'src/app/models/lookup.model';
import { CustomEntityService } from 'src/app/services/customEntity.service';

am4core.useTheme(am4themes_kelly);
am4core.options.animationsEnabled = false;
am4core.options.minPolylineStep = 10;

@Component({
    selector: 'app-data-analysis-page',
    templateUrl: 'dataAnalysisPage.component.html',
    styleUrls: ['dataAnalysisPage.component.css']
})
export class DataAnalysisPageComponent extends TrackableComponent implements OnInit, AfterViewInit, OnDestroy, OnZoom {

    readonly CHART_DIV_ID = 'data-analysis-chart';

    endPoints: EndPointSensorLookup[];
    sensors: EndPointSensorLookup[];
    selected: string[];
    isLoading = false;
    chart: am4charts.XYChart;
    fromDate: Date;
    toDate: Date;
    timeZone: string;
    timeZoneLabel: string;
    parameters: Enum[];
    selectedParams: number[];
    timeIntervals: Enum[];
    timeIntervalId: number;
    shiftInterval: DateRange;
    preferences: UserPreferences;
    deviceValues: { [key: string]: DeviceValue | undefined };
    rowDevices: string[];
    columnParameters: number[];
    disposer: am4core.IDisposer;
    initDisposer: am4core.IDisposer;
    analyticsDisposer: am4core.IDisposer;
    shouldRecordAnalyticsZoom = false;
    analyticsVerticalDisposer: am4core.IDisposer;
    shouldRecordVerticalZoom = false;

    constructor(private zone: NgZone,
        private sensorService: SensorService,
        private customEntityService: CustomEntityService,
        private sensorRecordService: SensorRecordService,
        private toastService: ToastService,
        private dateFormatService: DateFormatService,
        private chartService: ChartService,
        private dialog: MatDialog,
        authService: AuthService,
        timeZoneService: TimeZoneService,
        featureUsageService: FeatureUsageService) {

        super(featureUsageService, FeatureUsagePageEnum.DataAnalysisPage);

        const currentUser = authService.getCurrentUser();
        this.timeZone = currentUser.userTimeZone;
        this.timeZoneLabel = timeZoneService.createTimeZoneLabel(this.timeZone);
        this.preferences = currentUser.preferences;
        this.shiftInterval = new DateRange(undefined, undefined);
        this.parameters = this.chartService.getParameters();
        this.timeIntervals = [
            new Enum(TimeIntervalEnum.None, 'None'),
            new Enum(TimeIntervalEnum.Sliding15Minute, '15 min STEL (moving)'),
            new Enum(TimeIntervalEnum.Sliding1Hour, '1h Moving Average'),
            new Enum(TimeIntervalEnum.Sliding4Hour, '4h Moving Average'),
            new Enum(TimeIntervalEnum.Sliding8Hour, '8h Moving Average'),
            new Enum(TimeIntervalEnum.Hourly, '1h Fixed Average'),
            new Enum(TimeIntervalEnum.Daily, 'Daily (24h average)'),
            new Enum(TimeIntervalEnum.DailyShift, 'Daily Shifts TWA')
        ];
        this.deviceValues = {};
    }

    ngOnInit() {
        this.isLoading = true;
        this.selectedParams = this.chartService.getDefaultParameters();
        this.selected = this.preferences.selectedDevices;
        this.timeIntervalId = this.preferences.timeIntervalId;
        this.fromDate = this.preferences.fromDate;
        this.toDate = this.preferences.toDate;
        this.shiftInterval = new DateRange(this.preferences.shiftStart, this.preferences.shiftEnd);
        forkJoin({
            sensors: this.sensorService.getSensorLookupList(),
            endPoints: this.customEntityService.getEndPointLookupList()
        }).subscribe(
            result => {
                this.mapToEndPointsAndSensors(result.sensors, result.endPoints);
                this.isLoading = false;
            },
            error => {
                console.log(error);
                this.toastService.showError(error);
                this.isLoading = false;
            }
        );
    }

    mapToEndPointsAndSensors(sensors: Sensor[], endPoints: Lookup[]) {
        this.endPoints = [];
        this.sensors = [];
        endPoints.forEach(endPoint =>
            this.endPoints.push(new EndPointSensorLookup(
                `0+${endPoint.id}`,
                endPoint.name,
                endPoint.id,
                null,
                true
            )));
        sensors.forEach(sensor =>
            this.sensors.push(new EndPointSensorLookup(
                `1+${sensor.id}`,
                sensor.name,
                null,
                sensor.id,
                false
            )));
    }

    ngAfterViewInit() {
        this.chart = this.zone.runOutsideAngular(() => {
            return this.chartService.createChart(this.CHART_DIV_ID, this);
        });
        this.disposer = this.zone.runOutsideAngular(() => {
            return this.chartService.createChartZoomEvent(this.chart, this, false);
        });
        this.analyticsDisposer = this.zone.runOutsideAngular(() => {
            return this.chartService.createChartAnalyticsEvent(this.chart, this);
        });
        this.analyticsVerticalDisposer = this.zone.runOutsideAngular(() => {
            return this.chartService.createChartAnalyticsVerticalZoomEvent(this.chart, this);
        });
    }

    onInitialZoom(range) {
        if (this.initDisposer && !this.initDisposer.isDisposed() && range.start && range.end) {
            this.onDataZoom(range);
        }
    }

    onZoom(range) {
        if (range.start && range.end && this.initDisposer && !this.initDisposer.isDisposed()) {
            this.initDisposer.dispose();
        }
        this.onDataZoom(range);
    }

    onDataZoom(range) {
        this.zone.run(() => {
            const timestampStart = range.start;
            const timestampEnd = range.end;
            this.deviceValues = {};

            if (!timestampStart || !timestampEnd ||
                !this.chart.series || this.chart.series.length < 1) {
                return;
            }

            for (let i = 0; i < this.chart.series.length; i++) {
                const series = this.chart.series.getIndex(i);
                const name = series.name.split(' - ')[0];
                const paramField = series.dataFields.valueY;
                const paramId = this.chartService.getParameterId(paramField);

                let deviceValue = this.deviceValues[name];
                if (!deviceValue) {
                    deviceValue = new DeviceValue();
                    this.deviceValues[name] = deviceValue;
                }

                deviceValue.average[paramId] = 0;
                deviceValue.peak[paramId] = 0;
                let count = 0;

                series.data.forEach((s: SensorRecord) => {
                    if (s.timestamp < timestampStart || s.timestamp > timestampEnd) {
                        return;
                    }

                    if (deviceValue.peak[paramId] < s[paramField]) {
                        deviceValue.peak[paramId] = s[paramField];
                    }
                    deviceValue.average[paramId] += s[paramField];
                    count++;
                });
                if (count > 0) {
                    deviceValue.average[paramId] /= count;
                }
            }
        });
    }

    onAnalyticsHorizontalZoom() {
        if (!this.shouldRecordAnalyticsZoom) {
            this.shouldRecordAnalyticsZoom = true;
            return;
        }
        this.recordAction(FeatureUsageActionEnum.ChartHorizontalZoom);
    }

    onAnalyticsVerticalZoom() {
        if (!this.shouldRecordVerticalZoom) {
            this.shouldRecordVerticalZoom = true;
            return;
        }
        this.recordAction(FeatureUsageActionEnum.ChartVerticalZoom);
    }

    ngOnDestroy() {
        this.zone.runOutsideAngular(() => {
            this.chartService.disposeEvent(this.disposer);
            this.chartService.disposeEvent(this.initDisposer);
            this.chartService.disposeEvent(this.analyticsDisposer);
            this.chartService.disposeEvent(this.analyticsVerticalDisposer);
            if (this.chart) {
                this.chartService.disposeChartData(this.chart);
                this.chart.dispose();
            }
        });

        this.recordActivity();
    }

    selectInterval(id: number) {
        if (id === TimeIntervalEnum.DailyShift) {
            const dialogRef = this.dialog.open(TimeRangeDialogComponent, {
                data: this.shiftInterval,
                width: '450px',
                disableClose: true
            });

            dialogRef.afterClosed()
                .subscribe((dateRange: DateRange) => {
                    if (dateRange) {
                        this.shiftInterval = dateRange;
                    } else {
                        this.timeIntervalId = undefined;
                    }
                });
        }
    }

    search() {
        if (this.isDisabled() ||
            !this.chartService.validateDateRange(this.fromDate, this.toDate, this)) {
            return;
        }

        this.isLoading = true;
        const startDate = this.dateFormatService.formatDateTimeToString(this.fromDate);
        const endDate = this.dateFormatService.formatDateTimeToString(this.toDate);
        const startTime = this.dateFormatService.formatDateTimeToString(this.shiftInterval.startDate);
        const endTime = this.dateFormatService.formatDateTimeToString(this.shiftInterval.endDate);

        const selectedEndPointsAndSensors = this.getSelectedEndPointsAndSensors();

        const requests: Observable<SensorAnalysisRecord[]>[] = [];
        selectedEndPointsAndSensors.forEach(x => {
            const payload = new DataAnalysisRequestModel();
            if (x.isEndPoint) {
                payload.endPointId = x.endPointId;
            } else {
                payload.sensorId = x.sensorId;
            }
            payload.isViewByEndPoint = x.isEndPoint;
            payload.startDate = startDate;
            payload.endDate = endDate;
            payload.parameters = this.selectedParams;
            payload.timeIntervalId = this.timeIntervalId;
            payload.startTime = startTime;
            payload.endTime = endTime;
            payload.selectedDevices = this.selected;

            requests.push(this.sensorRecordService.getParameters(payload));
        });

        if (!requests) {
            this.isLoading = false;
            return;
        }

        forkJoin(requests)
            .subscribe(results => {
                this.updateChart(selectedEndPointsAndSensors.map(x => x.name), results);
                this.isLoading = false;
            },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isLoading = false;
                }
            );

        this.recordAction(FeatureUsageActionEnum.DataAnalysisPlotClick);
    }

    updateChart(names: string[], data: SensorAnalysisRecord[][]) {
        this.chartService.disposeChartData(this.chart);
        this.chartService.disposeEvent(this.initDisposer);
        this.initDisposer = this.zone.runOutsideAngular(() => {
            return this.chartService.createChartInitialZoomEvent(this.chart, this, false);
        });
        this.shouldRecordAnalyticsZoom = false;
        this.shouldRecordVerticalZoom = false;

        const title = this.chartService.getValueAxisTitle(this.selectedParams, this.preferences);
        this.chart.yAxes.getIndex(0).title.text = title;

        this.rowDevices = JSON.parse(JSON.stringify(names));
        this.columnParameters = JSON.parse(JSON.stringify(this.selectedParams));

        const series = [];
        const convertToMgPerM3 = this.preferences.pmUnitId === PmUnitEnum.MgPerM3;
        const convertToFahrenheit = this.preferences.temperatureUnitId === TemperatureUnitEnum.Fahrenheit;
        names.forEach((name, i) => {
            const sensorRecords = data[i];

            const values: any[][] = [];
            this.selectedParams.forEach(p => values.push([]));

            sensorRecords.forEach(record => {
                const timestamp = record.timestamp * 1000;
                this.selectedParams.forEach((p, j) => {
                    const value = new SensorRecord();
                    value.timestamp = timestamp;
                    const paramField = this.chartService.getParameterField(p);
                    switch (p) {
                        case ParameterTypeEnum.Pm1:
                        case ParameterTypeEnum.Pm2_5:
                        case ParameterTypeEnum.Pm10:
                        case ParameterTypeEnum.RespirablePm:
                        case ParameterTypeEnum.Pm1_A:
                        case ParameterTypeEnum.Pm2_5_A:
                        case ParameterTypeEnum.Pm10_A:
                        case ParameterTypeEnum.RespirablePm_A:
                            value[paramField] = convertToMgPerM3 ? record[paramField] * 0.001 : record[paramField];
                            break;
                        case ParameterTypeEnum.Temperature:
                            value[paramField] = convertToFahrenheit ? record[paramField] * 1.8 + 32 : record[paramField];
                            break;
                        case ParameterTypeEnum.Humidity:
                        case ParameterTypeEnum.Lavg:
                        case ParameterTypeEnum.Ozone:
                        case ParameterTypeEnum.SO2:
                        case ParameterTypeEnum.NO2:
                        case ParameterTypeEnum.CO:
                        case ParameterTypeEnum.TVOC:
                        case ParameterTypeEnum.CO2:
                            value[paramField] = record[paramField];
                            break;
                    }
                    values[j].push(value);
                });
            });

            this.selectedParams.forEach((p, j) => {
                const s = this.chart.series.push(
                    this.chartService.createLineSeries(p, this.selectedParams.length === 1, name));
                s.data = values[j];
                series.push(s);
            });
        });

        this.chartService.createXYChartScrollbar(this.chart, series);
        this.chart.scrollbarY = new am4core.Scrollbar();
    }
    // noData: 'No data found' : TODO :

    export() {
        if (this.isDisabled() ||
            !this.chartService.validateDateRange(this.fromDate, this.toDate, this)) {
            return;
        }

        this.isLoading = true;
        const startDate = this.dateFormatService.formatDateTimeToString(this.fromDate);
        const endDate = this.dateFormatService.formatDateTimeToString(this.toDate);
        const startTime = this.dateFormatService.formatDateTimeToString(this.shiftInterval.startDate);
        const endTime = this.dateFormatService.formatDateTimeToString(this.shiftInterval.endDate);

        const selectedEndPointsAndSensors = this.getSelectedEndPointsAndSensors();
        const models: DataAnalysisRequestModel[] = [];
        selectedEndPointsAndSensors.forEach(x => {
            const model = new DataAnalysisRequestModel();
            if (x.isEndPoint) {
                model.endPointId = x.endPointId;
            } else {
                model.sensorId = x.sensorId;
            }
            model.name = x.isEndPoint ? this.endPoints.find(m => m.id === x.id)?.name : this.sensors.find(s => s.id === x.id)?.name;
            model.isViewByEndPoint = x.isEndPoint;
            model.startDate = startDate;
            model.endDate = endDate;
            model.parameters = this.selectedParams;
            model.timeIntervalId = this.timeIntervalId;
            model.startTime = startTime;
            model.endTime = endTime;
            model.selectedDevices = this.selected;

            models.push(model);
        });

        if (!models) {
            this.isLoading = false;
            return;
        }

        const payload = new DataAnalysisExportModel();
        payload.models = models;

        this.sensorRecordService.exportParameters(payload)
            .subscribe(
                blob => {
                    this.chartService.downloadFile(blob);
                    this.isLoading = false;
                },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isLoading = false;
                });

        this.recordAction(FeatureUsageActionEnum.DataAnalysisExportDataClick);
    }

    isDisabled() {
        return !this.selected || this.selected.length < 1
            || !this.fromDate || !this.toDate
            || !this.selectedParams || this.selectedParams.length < 1
            || this.timeIntervalId === undefined;
    }

    getSelectedEndPointsAndSensors(): EndPointSensorLookup[] {
        const selectedEndPointsAndSensors: EndPointSensorLookup[] = [];
        this.selected.forEach(lookupId => {
            const selectedLookup = lookupId.startsWith('0+')
                ? this.endPoints.find(x => x.id === lookupId)
                : this.sensors.find(x => x.id === lookupId);
            if (selectedLookup) {
                selectedEndPointsAndSensors.push(selectedLookup);
            }
        });

        return selectedEndPointsAndSensors;
    }

    getParamName(paramId: number) {
        return this.chartService.getParameterName(paramId);
    }

    getParamUnit(paramId: number) {
        return this.chartService.getParameterUnit(paramId, this.preferences);
    }

    getValue(id: number, device: string, paramId: number) {
        if (!this.deviceValues[device]) {
            return -1;
        }

        return id === 0
            ? this.deviceValues[device].average[paramId]
            : this.deviceValues[device].peak[paramId];
    }

    onResized(event: ResizedEvent) {
        this.chartService.onResized(this.chart, event, this.CHART_DIV_ID);
    }

    onDateFilterClick() {
        this.recordAction(FeatureUsageActionEnum.DateFilterClick);
    }
}
