import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/toast.service';
import { SensorService } from 'src/app/services/sensor.service';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';

export interface EditSensorDialogData {
    id: string;
    name: string;
}

@Component({
    selector: 'app-edit-sensor-dialog-component',
    templateUrl: './editSensorDialog.component.html'
})
export class EditSensorDialogComponent extends TrackableComponent implements OnDestroy {

    isLoading = false;

    constructor(public dialogRef: MatDialogRef<EditSensorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EditSensorDialogData,
        private sensorService: SensorService,
        private toastService: ToastService,
        featureUsageService: FeatureUsageService) {

        super(featureUsageService, FeatureUsagePageEnum.EditSensorDialog);
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    save() {
        this.isLoading = true;
        this.sensorService.updateSensor(this.data)
            .subscribe(response => {
                this.isLoading = false;
                this.toastService.showSuccess('Sensor saved successfully.');
                this.dialogRef.close(true);
            }, error => {
                console.log(error);
                this.isLoading = false;
                this.toastService.showError(error);
            });

        this.recordAction(FeatureUsageActionEnum.UpdateSensorClick);
    }

    onClose() {
        this.recordAction(FeatureUsageActionEnum.CancelUpdateSensorClick);
    }
}
