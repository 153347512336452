<h4 class="dialog-title text-center">{{data.title}}</h4>

<hr />

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <h5>{{data.message}}</h5>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end" class="mt-4">
        <button mat-button class="btn button-primary" (click)="primaryClick()"
        [class.button-spinner]="isLoading" [disabled]="isLoading">{{data.primaryButtonText}}</button>

        <button class="btn button-accent" mat-button mat-dialog-close>{{data.secondaryButtonText}}</button>
    </div>
</div>
