<h4 class="dialog-title text-center">Custom Field</h4>

<hr />

<app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput [(ngModel)]="customField.name" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-tag"></i>
                    </mat-icon>
                    <mat-error>Name is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="customField.type" required>
                        <mat-option *ngFor="let customFieldType of customFieldTypes" [value]="customFieldType.id">
                            {{customFieldType.value}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-file-alt"></i>
                    </mat-icon>
                    <mat-error>Type is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12" *ngIf="customField.type === CustomFieldTypeEnum.SingleSelect">
                <mat-form-field>
                    <mat-label>Values (comma-delimited)</mat-label>
                    <input matInput [(ngModel)]="customField.data" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-tag"></i>
                    </mat-icon>
                    <mat-error>Values are required.</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button class="btn button-primary" (click)="ok()" [disabled]="isDisabled()">
            Ok
        </button>

        <button mat-button class="btn button-accent" mat-dialog-close>
            Cancel
        </button>
    </div>
</div>
