import { Component, OnInit } from '@angular/core';
import { SignupService } from '../../services/signup.service';
import { Router } from '@angular/router';
import { ToastService } from '../../services/toast.service';
import { UserSignupModel } from 'src/app/models/userSignup.model';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

    isLoading = false;
    isFormLoading = false;
    user: UserSignupModel;

    constructor(private signupService: SignupService,
        private toastService: ToastService,
        private router: Router) {
        this.isFormLoading = true;
        this.user = new UserSignupModel();
    }

    ngOnInit() {
        this.isFormLoading = false;
    }

    submit() {
        this.isLoading = true;
        this.signupService.registerUser(this.user)
            .subscribe(
                (response: any) => {
                    this.toastService.showSuccess('Your request has been submitted. We will notify you once it is reviewed.');
                    this.isLoading = false;
                    this.router.navigate(['login']);
                },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isLoading = false;
                });
    }
}
