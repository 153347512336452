<h4 class="dialog-title text-center">Add Shadow Constraint</h4>

<hr />

<app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Device Version</mat-label>
                    <mat-select [(ngModel)]="shadowConstraint.deviceVersionId" required>
                        <mat-option *ngFor="let deviceVersion of deviceVersions" [value]="deviceVersion.id">
                            {{deviceVersion.version}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-laptop-code"></i>
                    </mat-icon>
                    <mat-error>Device Version is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Field</mat-label>
                    <input matInput [(ngModel)]="shadowConstraint.field" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-file-invoice"></i>
                    </mat-icon>
                    <mat-error>Field is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Constraint Type</mat-label>
                    <mat-select [(ngModel)]="shadowConstraint.constraintTypeId" required>
                        <mat-option *ngFor="let constraintType of constraintTypes" [value]="constraintType.id">
                            {{constraintType.value}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-ruler"></i>
                    </mat-icon>
                    <mat-error>Constraint Type is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12" *ngIf="shadowConstraint.constraintTypeId !== ConstraintTypeEnum.Readonly && shadowConstraint.constraintTypeId !== ConstraintTypeEnum.SuperAdminOnly && shadowConstraint.constraintTypeId !== ConstraintTypeEnum.Rename">
                <mat-form-field>
                    <mat-label>Operator</mat-label>
                    <mat-select [(ngModel)]="shadowConstraint.operatorTypeId" required>
                        <mat-option *ngFor="let operatorType of operatorTypes" [value]="operatorType.id">
                            {{operatorType.value}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-greater-than-equal"></i>
                    </mat-icon>
                    <mat-error>Operator is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12" *ngIf="shadowConstraint.constraintTypeId !== ConstraintTypeEnum.Readonly && shadowConstraint.constraintTypeId != ConstraintTypeEnum.SuperAdminOnly">
                <mat-form-field>
                    <mat-label>{{shadowConstraint.constraintTypeId === ConstraintTypeEnum.Relative ? 'Other Field' : 'Value'}}</mat-label>
                    <input matInput [(ngModel)]="shadowConstraint.operatorValue" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-file-alt"></i>
                    </mat-icon>
                    <mat-error>
                        {{ shadowConstraint.constraintTypeId === ConstraintTypeEnum.Relative ? 'Other Field is required.' : 'Value is required.'}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12" *ngIf="shadowConstraint.constraintTypeId === ConstraintTypeEnum.Bounded">
                <mat-form-field>
                    <mat-label>Operator 2</mat-label>
                    <mat-select [(ngModel)]="shadowConstraint.operatorTypeId2" required>
                        <mat-option *ngFor="let operatorType of operatorTypes" [value]="operatorType.id">
                            {{operatorType.value}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-greater-than-equal"></i>
                    </mat-icon>
                    <mat-error>Operator 2 is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12" *ngIf="shadowConstraint.constraintTypeId === ConstraintTypeEnum.Bounded">
                <mat-form-field>
                    <mat-label>Value 2</mat-label>
                    <input matInput [(ngModel)]="shadowConstraint.operatorValue2" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-file-alt"></i>
                    </mat-icon>
                    <mat-error>Value 2 is required.</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button class="btn button-primary" (click)="create()" [class.button-spinner]="isLoading"
            [disabled]="isDisabled() || isLoading">
            Add
        </button>

        <button mat-button class="btn button-accent" mat-dialog-close>Cancel</button>
    </div>
</div>
