import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { NotificationAveragingEnum } from 'src/app/enums/notificationAveraging.enum';
import { ParameterTypeEnum } from 'src/app/enums/parameterType.enum';
import { Enum } from 'src/app/models/enum.model';
import { AppNotification } from 'src/app/models/notification';
import { UserPreferences } from 'src/app/models/userPreferences.model';
import { AuthService } from 'src/app/services';
import { ChartService } from 'src/app/services/chart.service';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { NotificationSettingsService } from 'src/app/services/notificationSettings.service';
import { ToastService } from 'src/app/services/toast.service';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';

@Component({
    selector: 'app-view-notification-dialog',
    templateUrl: 'viewNotificationDialog.component.html'
})
export class ViewNotificationDialogComponent extends TrackableComponent implements OnInit, OnDestroy {
    isFormLoading = false;
    isLoading = false;
    parameterTypes: Enum[] = [];
    averagingTypes: Enum[] = [];
    preferences: UserPreferences;

    constructor(public dialogRef: MatDialogRef<ViewNotificationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AppNotification,
        private notificationSettingsService: NotificationSettingsService,
        private toastService: ToastService,
        authService: AuthService,
        featureUsageService: FeatureUsageService,
        private chartService: ChartService,
    ) {
        super(featureUsageService, FeatureUsagePageEnum.ViewNotificationDialog);
        this.preferences = authService.getCurrentUser().preferences;
    }

    ngOnInit() {
        this.isFormLoading = true;
        this.notificationSettingsService.getNotificationSettingsData()
            .subscribe(
                result => {
                    this.parameterTypes = result.parameterTypes;
                    this.averagingTypes = result.averagingTypes;
                    this.isFormLoading = false;
                },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isFormLoading = false;
                }
            );
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    onClose() {
        this.recordAction(FeatureUsageActionEnum.CloseViewNotificationClick);
    }

    getParameterTypeName(parameterType: ParameterTypeEnum) {
        return this.chartService.getParameterName(parameterType);
    }

    getParameterUnit(parameterType: ParameterTypeEnum) {
        return this.chartService.getParameterUnit(parameterType, this.preferences);
    }

    getAveragingTypeName(averagingType: NotificationAveragingEnum) {
        return averagingType === NotificationAveragingEnum.Instantaneous
            ? 'Instantaneous'
            : 'Time Weighted Average';
    }
}
