import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShadowConstraintService } from 'src/app/services/shadowConstraint.service';
import { ToastService } from 'src/app/services/toast.service';
import { CreateShadowConstraintDialogComponent } from '../createShadowConstraintDialog/createShadowConstraintDialog.component';
import { EditShadowConstraintDialogComponent } from '../editShadowConstraintDialog/editShadowConstraintDialog.component';
import { ShadowConstraint } from 'src/app/models/shadowConstraint.model';
import { ConstraintTypeEnum } from 'src/app/enums/constraintType.enum';
import { ConfirmDialogComponent } from 'src/app/shared/confirmDialog/confirmDialog.component';
import { AuthService } from 'src/app/services';
import { TimeZoneService } from 'src/app/services/timeZone.service';
import { GridBaseComponent } from 'src/app/shared/grid/grid.component';

@Component({
    selector: 'app-shadow-constraint-list',
    templateUrl: 'shadowConstraintList.component.html',
    styleUrls: ['shadowConstraintList.component.css']
})
export class ShadowConstraintListComponent extends GridBaseComponent<ShadowConstraint> implements OnInit, AfterViewInit {

    ConstraintTypeEnum = ConstraintTypeEnum;

    constructor(protected shadowConstraintService: ShadowConstraintService,
        toastService: ToastService,
        authService: AuthService,
        timeZoneService: TimeZoneService,
        dialog: MatDialog
    ) {
        super(toastService, authService, timeZoneService, dialog);
        this.gridService = shadowConstraintService;
        this.displayedColumns = ['DeviceVersion.Version', 'field', 'constraintType', 'operatorType', 'operatorValue', 'operatorType2', 'operatorValue2', 'action'];
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    add() {
        this.openDialog(CreateShadowConstraintDialogComponent, {});
    }

    edit(shadowConstraint: ShadowConstraint) {
        this.openDialog(EditShadowConstraintDialogComponent, { id: shadowConstraint.id });
    }

    delete(shadowConstraint: ShadowConstraint) {
        this.openDeleteDialog(
            ConfirmDialogComponent,
            {
                title: 'Delete Shadow Constraint',
                message: `Are you sure you want to delete this shadow constraint?`,
                primaryButtonText: 'Delete',
                primaryCallback: () => this.shadowConstraintService.delete(shadowConstraint.id),
                successMessage: 'Shadow constraint deleted successfully!',
                secondaryButtonText: 'Cancel'
            }
        );
    }
}
