<div class="login-bg d-flex justify-content-center align-items-center">
    <div class="login-area registration-area">
        <app-logo></app-logo>

        <app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

        <div class="row">
            <div class="col text-center">
                <h3> RESET PASSWORD</h3>
                <hr />
            </div>
        </div>

        <div class="row">
            <div class="col text-center">
                <p>
                    Please set at least one lower, one upper, one digit, one special and minimum 6 and maximum 15
                    characters.
                </p>
            </div>
        </div>

        <form [mustMatch]="['newPassword', 'confirmPassword']" autocomplete="off">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8">
                    <mat-form-field>
                        <mat-label>New Password</mat-label>
                        <input matInput [(ngModel)]="model.newPassword" [type]="show1 ? 'text' : 'password'"
                            pattern="((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15})" required name="newPassword"
                            #newPassword="ngModel" />
                        <mat-icon matPrefix class="mr-3">
                            <i class="fas fa-lock"></i>
                        </mat-icon>
                        <button mat-icon-button matSuffix (click)="show1 = !show1">
                            <mat-icon>{{show1 ? 'visibility' : 'visibility_off'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="newPassword.errors?.required">New Password is required.</mat-error>
                        <mat-error *ngIf="newPassword.errors?.pattern">New Password is invalid.</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8">
                    <mat-form-field>
                        <mat-label>Confirm Password</mat-label>
                        <input matInput [(ngModel)]="model.confirmPassword" [type]="show2 ? 'text' : 'password'"
                            required name="confirmPassword" #confirmPassword="ngModel" />
                        <mat-icon matPrefix class="mr-3">
                            <i class="fas fa-lock"></i>
                        </mat-icon>
                        <button mat-icon-button matSuffix (click)="show2 = !show2">
                            <mat-icon>{{show2 ? 'visibility' : 'visibility_off'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="confirmPassword.errors?.required">Confirm Password is required.</mat-error>
                        <mat-error *ngIf="confirmPassword.errors?.mustMatch">Passwords do not match.</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>

        <div class="row">
            <div class="col" align="end">
                <button mat-button class="btn button-primary" (click)="submit()" [class.button-spinner]="isLoading"
                    [disabled]="newPassword.invalid || confirmPassword.invalid || isLoading">Reset</button>

                <button mat-button class="btn button-accent ml-2" [routerLink]="['/cancel']">Cancel</button>
            </div>
        </div>
    </div>
</div>
