import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/services/toast.service';
import { forkJoin } from 'rxjs';
import { UserUpdateModel } from 'src/app/models/userUpdateModel';
import { Enum } from 'src/app/models/enum.model';
import { SecurityProfileEnum } from 'src/app/enums/securityProfile.enum';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';

export interface EditUserDialogData {
    id: string;
}

@Component({
    selector: 'app-edit-user-dialog',
    templateUrl: 'editUserDialog.component.html'
})
export class EditUserDialogComponent extends TrackableComponent implements OnInit, OnDestroy {

    isFormLoading = false;
    isLoading = false;
    securityProfiles: Enum[];
    user: UserUpdateModel;
    isNoneUser = false;

    constructor(public dialogRef: MatDialogRef<EditUserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EditUserDialogData,
        private userService: UserService,
        private toastService: ToastService,
        featureUsageService: FeatureUsageService) {

        super(featureUsageService, FeatureUsagePageEnum.EditUserDialog);

        this.securityProfiles = [];
        this.user = new UserUpdateModel();
    }

    ngOnInit() {
        this.isFormLoading = true;
        forkJoin({
            user: this.userService.getUser(this.data.id),
            securityProfiles: this.userService.getSecurityProfileLookupList()
        }).subscribe(
            result => {
                this.user = result.user;
                this.isNoneUser = this.user.securityProfileId === undefined || this.user.securityProfileId === SecurityProfileEnum.None;
                this.securityProfiles = result.securityProfiles;
                this.isFormLoading = false;
            },
            error => {
                console.log(error);
                this.toastService.showError(error);
                this.isFormLoading = false;
            }
        );
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    save() {
        this.isLoading = true;
        this.userService.updateUser(this.user)
            .subscribe(
                response => {
                    this.toastService.showSuccess('User saved successfully.');
                    this.isLoading = false;
                    this.dialogRef.close(true);
                },
                error => {
                    console.log(error);
                    this.isLoading = false;
                    this.toastService.showErrors(error, ['Id', 'FullName', 'SecurityProfileId']);
                }
            );

        this.recordAction(FeatureUsageActionEnum.UpdateUserClick);
    }

    onClose() {
        this.recordAction(FeatureUsageActionEnum.CancelUpdateUserClick);
    }
}
