import { OnInit, Component, Inject } from '@angular/core';
import { DeviceVersionService } from 'src/app/services/deviceVersionService';
import { ToastService } from 'src/app/services/toast.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceVersion } from 'src/app/models/deviceVersion.model';

export interface EditDeviceVersionDialogData {
    id: number;
}

@Component({
    selector: 'app-edit-device-version-dialog',
    templateUrl: 'editDeviceVersionDialog.component.html'
})
export class EditDeviceVersionDialogComponent implements OnInit {

    isFormLoading = false;
    isLoading = false;
    deviceVersion: DeviceVersion;

    constructor(public dialogRef: MatDialogRef<EditDeviceVersionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EditDeviceVersionDialogData,
        private deviceVersionService: DeviceVersionService,
        private toastService: ToastService) {
        this.deviceVersion = new DeviceVersion();
    }

    ngOnInit() {
        this.isFormLoading = true;
        this.deviceVersionService.getDeviceVersion(this.data.id)
            .subscribe(result => {
                this.deviceVersion = result;
                this.isFormLoading = false;
            });
    }

    save() {
        this.isLoading = true;
        this.deviceVersionService.update(this.deviceVersion)
            .subscribe(
                result => {
                    this.isLoading = false;
                    this.toastService.showSuccess('Device version saved successfully.');
                    this.dialogRef.close(true);
                },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isLoading = false;
                });
    }
}
