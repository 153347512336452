<body class="dashboard dashboard-bg">
    <app-header></app-header>

    <div class="body-container collapsed">
        <app-sidebar></app-sidebar>

        <div class="body-content">
            <div class="row">
                <div class="col p-0">
                    <app-page-header title="Data Analysis" iconClass="fas fa-chart-bar"></app-page-header>
                </div>
            </div>

            <app-progress-spinner [show]="isLoading"></app-progress-spinner>

            <div class="white-box">
                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <mat-label>End Point / Sensor</mat-label>
                            <mat-select [(ngModel)]="selected" multiple required>
                                <mat-optgroup class="optgroup-label" label="End Points">
                                    <mat-option *ngFor="let endPoint of endPoints" [value]="endPoint.id">
                                        {{endPoint.name}}
                                    </mat-option>
                                </mat-optgroup>
                                <mat-optgroup class="optgroup-label" label="Sensors">
                                    <mat-option *ngFor="let sensor of sensors" [value]="sensor.id">
                                        {{sensor.name}}
                                    </mat-option>
                                </mat-optgroup>
                            </mat-select>
                            <mat-icon matPrefix class="mr-2">
                                <i class="fas fa-desktop"></i>
                            </mat-icon>
                            <mat-icon matPrefix class="mr-3">
                                <i class="fas fa-broadcast-tower"></i>
                            </mat-icon>
                            <mat-error>End Point / Sensor is required.</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col">
                        <mat-form-field>
                            <mat-label>From Date</mat-label>
                            <input matInput [(ngModel)]="fromDate" class="clickable" [owlDateTime]="dtStart"
                                [owlDateTimeTrigger]="dtStart" required (click)="onDateFilterClick()" />
                            <mat-icon matPrefix class="clickable mr-3" [owlDateTimeTrigger]="dtStart" (click)="onDateFilterClick()">
                                <i class="fas fa-calendar-alt"></i>
                            </mat-icon>
                            <mat-error>From Date is required.</mat-error>
                            <owl-date-time #dtStart></owl-date-time>
                        </mat-form-field>
                    </div>

                    <div class="col">
                        <mat-form-field>
                            <mat-label>To Date</mat-label>
                            <input matInput [(ngModel)]="toDate" class="clickable" [owlDateTime]="dtEnd"
                                [owlDateTimeTrigger]="dtEnd" required (click)="onDateFilterClick()" />
                            <mat-icon matPrefix class="clickable mr-3" [owlDateTimeTrigger]="dtEnd" (click)="onDateFilterClick()">
                                <i class="fas fa-calendar-alt"></i>
                            </mat-icon>
                            <mat-error>To Date is required.</mat-error>
                            <owl-date-time #dtEnd></owl-date-time>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Plot Parameter(s)</mat-label>
                            <mat-select [(ngModel)]="selectedParams" multiple required>
                                <mat-option *ngFor="let param of parameters" [value]="param.id">
                                    {{param.value}}
                                </mat-option>
                            </mat-select>
                            <mat-icon matPrefix class="mr-3">
                                <i class="fas fa-chart-line"></i>
                            </mat-icon>
                            <mat-error>Plot Parameter is required.</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col">
                        <mat-form-field>
                            <mat-label>Averaging</mat-label>
                            <mat-select [(ngModel)]="timeIntervalId" required>
                                <mat-option *ngFor="let timeInterval of timeIntervals" [value]="timeInterval.id"
                                    (click)="selectInterval(timeInterval.id)">
                                    {{timeInterval.value}}
                                </mat-option>
                            </mat-select>
                            <mat-icon matPrefix class="mr-3">
                                <i class="fas fa-clock"></i>
                            </mat-icon>
                            <mat-error>Averaging is required.</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col">
                        <button mat-button class="btn button-secondary mt-3" (click)="search()"
                            [class.button-spinner]="isLoading" [disabled]="isLoading || isDisabled()">
                            Plot
                        </button>

                        <button mat-button class="btn button-primary-light mt-3 ml-2 mb-3" (click)="export()"
                            [class.button-spinner]="isLoading" [disabled]="isLoading || isDisabled()">
                            Export Data
                        </button>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-9" (resized)="onResized($event)">
                        <div [id]="CHART_DIV_ID" style="width: 100%; height: 800px;"></div>
                    </div>

                    <div class="col-lg-3">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <ng-container *ngFor="let param of columnParameters">
                                        <th>{{getParamName(param)}}</th>
                                        <th></th>
                                    </ng-container>
                                </tr>
                                <tr>
                                    <th>End Point / Sensor</th>
                                    <ng-container *ngFor="let param of columnParameters">
                                        <th>Average [{{getParamUnit(param)}}]</th>
                                        <th>Peak [{{getParamUnit(param)}}]</th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let device of rowDevices">
                                    <td>{{device}}</td>
                                    <ng-container *ngFor="let param of columnParameters">
                                        <td>{{getValue(0, device, param) | number:'1.0-2'}}</td>
                                        <td>{{getValue(1, device, param) | number:'1.0-2'}}</td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-3 offset-lg-9">
                    <mat-form-field>
                        <mat-label>Time Zone</mat-label>
                        <input matInput [(ngModel)]="timeZoneLabel" readonly />
                        <mat-icon matPrefix class="mr-3">
                            <i class="fas fa-globe"></i>
                        </mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div class="clearfix"></div>
            <app-footer></app-footer>
        </div>

        <div class="clearfix"></div>
    </div>
</body>
