import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { NotificationAveragingEnum } from 'src/app/enums/notificationAveraging.enum';
import { ParameterTypeEnum } from 'src/app/enums/parameterType.enum';
import { NotificationSettings } from 'src/app/models/notificationSettings.model';
import { UserPreferences } from 'src/app/models/userPreferences.model';
import { AuthService } from 'src/app/services';
import { ChartService } from 'src/app/services/chart.service';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { NotificationSettingsService } from 'src/app/services/notificationSettings.service';
import { TimeZoneService } from 'src/app/services/timeZone.service';
import { ToastService } from 'src/app/services/toast.service';
import { ConfirmDialogComponent } from 'src/app/shared/confirmDialog/confirmDialog.component';
import { TrackableGridBaseComponent } from 'src/app/shared/grid/trackableGrid.component';
import { AssignNotificationSettingsDialogComponent } from '../assignNotificationSettingsDialog/assignNotificationSettingsDialog.component';
import { CloneNotificationSettingsDialogComponent } from '../cloneNotificationSettingsDialog/cloneNotificationSettingsDialog.component';
import { CreateNotificationSettingsDialogComponent } from '../createNotificationSettingsDialog/createNotificationSettingsDialog.component';
import { EditNotificationSettingsDialogComponent } from '../editNotificationSettingsDialog/editNotificationSettingsDialog.component';

@Component({
    selector: 'app-notification-settings-list',
    templateUrl: 'notificationSettingsList.component.html',
    styleUrls: ['notificationSettingsList.component.css']
})
export class NotificationSettingsListComponent extends TrackableGridBaseComponent<NotificationSettings> implements OnInit, AfterViewInit, OnDestroy {

    preferences: UserPreferences;
    userEmail: string;

    constructor(protected notificationSettingsService: NotificationSettingsService,
        toastService: ToastService,
        authService: AuthService,
        timeZoneService: TimeZoneService,
        featureUsageService: FeatureUsageService,
        dialog: MatDialog,
        private chartService: ChartService
    ) {
        super(toastService, authService, timeZoneService, dialog, featureUsageService, FeatureUsagePageEnum.NotificationSettingsPage);
        this.gridService = notificationSettingsService;
        const currentUser = authService.getCurrentUser();
        this.userEmail = currentUser.userEmail;
        this.preferences = currentUser.preferences;
        this.displayedColumns = ['name', 'isEnabled', 'parameterType', 'averagingType', 'threshold', 'subscribed', 'action'];
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    getParameterTypeName(parameterType: ParameterTypeEnum) {
        return this.chartService.getParameterName(parameterType);
    }

    getParameterUnit(parameterType: ParameterTypeEnum) {
        return this.chartService.getParameterUnit(parameterType, this.preferences);
    }

    getAveragingTypeName(averagingType: NotificationAveragingEnum) {
        return averagingType === NotificationAveragingEnum.Instantaneous
            ? 'Instantaneous'
            : 'Time Weighted Average';
    }

    isTextMuted(notificationSettings: NotificationSettings) {
        return !notificationSettings || !notificationSettings.subscribedEmails || !notificationSettings.subscribedEmails.includes(this.userEmail);
    }

    add() {
        this.openDialog(
            CreateNotificationSettingsDialogComponent,
            {},
            undefined,
            FeatureUsageActionEnum.NotificationSettingsAddClick
        );
    }

    assign(notificationSettings: NotificationSettings) {
        this.openDialog(
            AssignNotificationSettingsDialogComponent,
            { id: notificationSettings.id },
            undefined,
            FeatureUsageActionEnum.NotificationSettingsAssignClick
        );
    }

    edit(notificationSettings: NotificationSettings) {
        this.openDialog(
            EditNotificationSettingsDialogComponent,
            { id: notificationSettings.id },
            undefined,
            FeatureUsageActionEnum.NotificationSettingsEditClick
        );
    }

    clone(notificationSettings: NotificationSettings) {
        this.openDialog(
            CloneNotificationSettingsDialogComponent,
            { id: notificationSettings.id },
            undefined,
            FeatureUsageActionEnum.NotificationSettingsCloneClick
        );
    }

    delete(notificationSettings: NotificationSettings) {
        this.openTrackableDeleteDialog(
            ConfirmDialogComponent,
            {
                title: 'Delete Notification Settings',
                message: 'Are you sure you want to delete these notification settings? Deleting it will also delete the history associated with this notification.',
                primaryButtonText: 'Delete',
                primaryCallback: () => this.notificationSettingsService.deleteNotificationSettings(notificationSettings.id),
                successMessage: 'Notification Settings deleted successfully!',
                secondaryButtonText: 'Cancel'
            },
            undefined,
            FeatureUsageActionEnum.NotificationSettingsDeleteClick,
            FeatureUsageActionEnum.DeleteNotificationSettingsClick,
            FeatureUsageActionEnum.CancelDeleteNotificationSettingsClick
        );
    }
}
