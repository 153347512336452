<h4 class="dialog-title text-center">Add User</h4>

<hr />

<app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>First Name</mat-label>
                    <input matInput [(ngModel)]="data.firstName" required maxlength="100" />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-address-card"></i>
                    </mat-icon>
                    <mat-error>
                        First Name is required.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Last Name</mat-label>
                    <input matInput [(ngModel)]="data.lastName" required maxlength="100" />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-address-card"></i>
                    </mat-icon>
                    <mat-error>
                        Last Name is required.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Username</mat-label>
                    <input matInput [(ngModel)]="data.username" [type]="data.createAccount ? 'email' : 'text'"
                        [email]="data.createAccount" #username="ngModel" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-user"></i>
                    </mat-icon>
                    <mat-error *ngIf="username.errors?.required">Username is required.</mat-error>
                    <mat-error *ngIf="username.errors?.email">Invalid email address.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12 padding-y-10">
                <mat-checkbox color="primary" [(ngModel)]="data.createAccount">
                    Create account for this user.
                </mat-checkbox>
                <mat-icon class="ml-1 color-primary-light" [title]="createAccountInfo">
                    <i class="fas fa-info-circle"></i>
                </mat-icon>
                <hr class="field-underline" />
            </div>

            <div class="col-md-12" *ngIf="data.createAccount">
                <mat-form-field>
                    <mat-label>Role</mat-label>
                    <mat-select [(ngModel)]="data.securityProfileId" [disabled]="!data.createAccount"
                        [title]="data.createAccount ? '' : 'Disabled'" required class="cursor-not-allowed">
                        <mat-option *ngFor="let profile of securityProfiles" [value]="profile.id">
                            {{profile.value}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-user-tag"></i>
                    </mat-icon>
                    <mat-error>Role is required.</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button class="btn button-primary" mat-button (click)="create()" [class.button-spinner]="isLoading"
            [disabled]="!data.firstName || !data.lastName || username.invalid || (data.createAccount && data.securityProfileId === undefined) || isLoading">
            Add
        </button>

        <button class="btn button-accent" mat-button mat-dialog-close (click)="onClose()">Cancel</button>
    </div>
</div>
