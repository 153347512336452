import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomFieldTypeEnum } from 'src/app/enums/customFieldType.enum';
import { CustomField } from 'src/app/models/customField.model';
import { Enum } from 'src/app/models/enum.model';
import { CustomObjectService } from 'src/app/services/customObject.service';
import { ToastService } from 'src/app/services/toast.service';

export interface CustomFieldDialogData {
    names: string[];
    customField: CustomField;
}

@Component({
    selector: 'app-custom-field-dialog',
    templateUrl: 'customFieldDialog.component.html'
})
export class CustomFieldDialogComponent implements OnInit {

    isFormLoading = false;
    customFieldTypes: Enum[];
    CustomFieldTypeEnum = CustomFieldTypeEnum;
    customField: CustomField;

    constructor(public dialogRef: MatDialogRef<CustomFieldDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CustomFieldDialogData,
        private customObjectService: CustomObjectService,
        private toastService: ToastService
    ) {
        this.customField = data.customField;
    }

    ngOnInit() {
        this.isFormLoading = true;
        this.customObjectService.getCustomFieldTypes()
            .subscribe(
                result => {
                    this.customFieldTypes = result;
                    this.isFormLoading = false;
                },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isFormLoading = false;
                }
            );
    }

    isDisabled() {
        return !this.customField || !this.customField.name || this.customField.type === undefined
            || (this.customField.type === CustomFieldTypeEnum.SingleSelect && !this.customField.data);
    }

    ok() {
        if (this.data.names.map(x => x.toLowerCase()).includes(this.customField.name.toLowerCase())) {
            this.toastService.showWarning('Duplicate Name: Please select another value.');
            return;
        }
        this.dialogRef.close(this.customField);
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize(event) {
        this.dialogRef.updatePosition({});
    }
}
