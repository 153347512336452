import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-chart-header',
    templateUrl: 'chartHeader.component.html',
    styleUrls: ['chartHeader.component.css']
})
export class ChartHeaderComponent {
    @Input() itemId: string;
    @Input() iconClass: string;
    @Input() items: any[];
    @Input() label: string;
    @Output() selectionChanged = new EventEmitter<string>();

    constructor() { }

    onSelectionChanged(event) {
        this.selectionChanged.emit(event.value);
    }
}
