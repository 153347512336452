<h4 class="dialog-title text-center">Edit Logo</h4>

<hr />

<app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row mb-1">
            <div class="col">
                <mat-label>{{selectedFile ? selectedFile.fileName : ''}}</mat-label>
            </div>
        </div>

        <div class="row mb-1">
            <div class="col" *ngIf="!selectedFile && tenantLogoUrl">
                <div class="img-preview-container">
                    <div class="img-preview" [ngStyle]="{'background-image': 'url(' + tenantLogoUrl + ')'}"></div>
                </div>
            </div>

            <div class="col" *ngIf="selectedFile">
                <div class="img-preview-container">
                    <div class="img-preview" [ngStyle]="{'background-image': 'url(' + selectedFile.src + ')'}"></div>
                </div>
            </div>
        </div>

        <div class="row mb-1">
            <div class="col">
                <button mat-button class="btn button-secondary full-width" (click)="imageInput.click()">
                    Select Image
                </button>
                <input hidden #imageInput type="file" accept="image/*" (change)="previewImage(imageInput)" />
            </div>
        </div>

        <div class="row mb-4">
            <div class="col">
                <mat-icon class="mr-1 color-primary-light">
                    <i class="fas fa-info-circle"></i>
                </mat-icon>

                <mat-label class="text-muted text-small">
                    Maximum file size limit: <b>2 MB</b>.
                </mat-label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button class="btn button-primary" (click)="save()" [class.button-spinner]="isLoading"
            [disabled]="!selectedFile || isLoading">
            Save
        </button>

        <button mat-button class="btn button-accent" mat-dialog-close>Cancel</button>
    </div>
</div>
