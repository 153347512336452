<h4 class="dialog-title text-center">Edit User</h4>

<hr />

<app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Username</mat-label>
                    <input matInput [(ngModel)]="user.userName" readonly class="cursor-not-allowed"
                        title="Username cannot be changed." />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-user"></i>
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Full Name</mat-label>
                    <input matInput [(ngModel)]="user.fullName" required maxlength="100" />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-address-card"></i>
                    </mat-icon>
                    <mat-error>Full Name is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12" [hidden]="isNoneUser">
                <mat-form-field>
                    <mat-label>Role</mat-label>
                    <mat-select [(ngModel)]="user.securityProfileId" required>
                        <mat-option *ngFor="let profile of securityProfiles" [value]="profile.id">
                            {{profile.value}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-user-tag"></i>
                    </mat-icon>
                    <mat-error>Role is required.</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button class="btn button-primary" (click)="save()" [class.button-spinner]="isLoading"
            [disabled]="!user.fullName || !isNoneUser && user.securityProfileId === undefined || isLoading">
            Save
        </button>

        <button mat-button class="btn button-accent" mat-dialog-close (click)="onClose()">Cancel</button>
    </div>
</div>
