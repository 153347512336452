import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { NotificationAveragingEnum } from 'src/app/enums/notificationAveraging.enum';
import { ParameterTypeEnum } from 'src/app/enums/parameterType.enum';
import { AppNotification } from 'src/app/models/notification';
import { UserPreferences } from 'src/app/models/userPreferences.model';
import { AuthService } from 'src/app/services';
import { ChartService } from 'src/app/services/chart.service';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TimeZoneService } from 'src/app/services/timeZone.service';
import { ToastService } from 'src/app/services/toast.service';
import { ConfirmDialogComponent } from 'src/app/shared/confirmDialog/confirmDialog.component';
import { TrackableGridBaseComponent } from 'src/app/shared/grid/trackableGrid.component';
import { ViewNotificationDialogComponent } from '../viewNotificationDialog/viewNotificationDialog.component';

@Component({
    selector: 'app-notification-list',
    templateUrl: 'notificationList.component.html',
    styleUrls: ['notificationList.component.css']
})
export class NotificationListComponent extends TrackableGridBaseComponent<AppNotification> implements OnInit, AfterViewInit, OnDestroy {

    preferences: UserPreferences;

    constructor(protected notificationService: NotificationService,
        toastService: ToastService,
        private authService: AuthService,
        timeZoneService: TimeZoneService,
        featureUsageService: FeatureUsageService,
        dialog: MatDialog,
        private chartService: ChartService,
        private router: Router,
    ) {
        super(toastService, authService, timeZoneService, dialog, featureUsageService, FeatureUsagePageEnum.NotificationListPage);
        this.gridService = notificationService;
        this.preferences = authService.getCurrentUser().preferences;
        this.displayedColumns = ['notificationSentDate', 'NotificationSettings.Name', 'parameterType', 'averagingType', 'value', 'sensor', 'endPoint', 'action'];
        this.defaultOrderBy = 'desc';
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    getParameterTypeName(parameterType: ParameterTypeEnum) {
        return this.chartService.getParameterName(parameterType);
    }

    getParameterUnit(parameterType: ParameterTypeEnum) {
        return this.chartService.getParameterUnit(parameterType, this.preferences);
    }

    getAveragingTypeName(averagingType: NotificationAveragingEnum) {
        return averagingType === NotificationAveragingEnum.Instantaneous
            ? 'Instantaneous'
            : 'Time Weighted Average';
    }

    showOnGraph(notification: AppNotification) {
        const id = notification.sensorId || notification.endPointId;
        if (!id || notification.parameterType === undefined || !notification.shiftStartDate || !notification.notificationSentDate) {
            this.toastService.displayErrorMessage('Error in notification data.');
            return;
        }
        const isViewBySensor = !!notification.sensorId;
        if (isViewBySensor) {
            localStorage.setItem('deviceName', window.btoa(notification.sensorId));
        }
        this.recordAction(FeatureUsageActionEnum.NotificationsShowOnGraphClick);
        this.authService.setCurrentUserNotificationDetails(notification.parameterType, notification.shiftStartDate, notification.notificationSentDate);
        if (isViewBySensor) {
            this.router.navigate(['/sensor'], { queryParams: { notification: true } });
        } else {
            this.router.navigate([`/end-point-view/${id}`], { queryParams: { notification: true } });
        }
    }

    view(notification: AppNotification) {
        this.openDialog(
            ViewNotificationDialogComponent,
            notification,
            undefined,
            FeatureUsageActionEnum.NotificationsViewClick
        );
    }

    delete(notification: AppNotification) {
        this.openTrackableDeleteDialog(
            ConfirmDialogComponent,
            {
                title: 'Delete Notification',
                message: 'Are you sure you want to delete this notification?',
                primaryButtonText: 'Delete',
                primaryCallback: () => this.notificationService.deleteNotification(notification.id),
                successMessage: 'Notification deleted successfully!',
                secondaryButtonText: 'Cancel'
            },
            undefined,
            FeatureUsageActionEnum.NotificationsDeleteClick,
            FeatureUsageActionEnum.DeleteNotificationClick,
            FeatureUsageActionEnum.CancelDeleteNotificationClick
        );
    }
}
