import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../services/account.service';
import { ToastService } from 'src/app/services/toast.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-forgotpassword',
    templateUrl: './forgotpassword.component.html',
    styleUrls: ['./forgotpassword.component.css']
})
export class ForgetpasswordComponent implements OnInit {

    isFormLoading = false;
    isLoading = false;
    username: string;

    constructor(private accountService: AccountService,
        private toastService: ToastService,
        private router: Router) {
        this.isFormLoading = true;
    }

    ngOnInit() {
        this.isFormLoading = false;
    }

    submit() {
        this.isLoading = true;
        this.accountService.forgotPassword(this.username)
            .subscribe(
                response => {
                    this.toastService.showSuccess('Please check your email to change your password.');
                    this.isLoading = false;
                    this.router.navigate(['login']);
                },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isLoading = false;
                });
    }
}
