<h4 class="dialog-title text-center">Notification</h4>

<hr />

<app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Date Sent</mat-label>
                    <input matInput value="{{data.notificationSentDate | date:'medium'}}" readonly />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-calendar-alt"></i>
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Notification Name</mat-label>
                    <input matInput value="{{data.notificationName}}" readonly />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-bell"></i>
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Parameter Type</mat-label>
                    <input matInput value="{{getParameterTypeName(data.parameterType)}}" readonly />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-chart-line"></i>
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Averaging Type</mat-label>
                    <input matInput value="{{getAveragingTypeName(data.averagingType)}}" readonly />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-clock"></i>
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Value</mat-label>
                    <input matInput value="{{data.value}} [{{getParameterUnit(data.parameterType)}}]" readonly />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-tachometer-alt"></i>
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12" *ngIf="data.sensor">
                <mat-form-field>
                    <mat-label>Sensor</mat-label>
                    <input matInput value="{{data.sensor}}" readonly />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-broadcast-tower"></i>
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12" *ngIf="data.endPoint">
                <mat-form-field>
                    <mat-label>endPoint</mat-label>
                    <input matInput value="{{data.endPoint}}" readonly />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-desktop"></i>
                    </mat-icon>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button class="btn button-accent" mat-button mat-dialog-close (click)="onClose()">Close</button>
    </div>
</div>
