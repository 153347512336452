import { Injectable } from '@angular/core';
import { EndpointService } from './endpoint.service';
import { HttpClient } from '@angular/common/http';
import { IGridService } from '../shared/grid/grid.service';
import { TenantSensor } from '../models/tenantSensor.model';
import { GridModel } from '../shared/grid/grid.model';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root'
})
export class TenantSensorService extends EndpointService implements IGridService<TenantSensor> {

    constructor(http: HttpClient,
        private toastService: ToastService) {
        super(http);
    }

    get(filter: string, orderBy: string, sort: string, page: number, pageSize: number) {
        return this.http.get<GridModel<TenantSensor>>(this.getApiUrl('TenantSensor/GetTenantSensors'), {
            params: this.createParams(filter, orderBy, sort, page, pageSize)
        });
    }

    getTenantSensor(id: string) {
        return this.http.get<TenantSensor>(this.getApiUrl(`TenantSensor/GetTenantSensor?id=${id}`))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading sensor.');
                    return of(new TenantSensor());
                })
            );
    }

    createTenantSensor(sensor) {
        return this.http.post(this.getApiUrl('TenantSensor/CreateTenantSensor'), sensor);
    }

    updateTenantSensor(sensor) {
        return this.http.put(this.getApiUrl('TenantSensor/UpdateTenantSensor'), sensor);
    }

    deleteTenantSensor(id) {
        return this.http.delete(this.getApiUrl(`TenantSensor/DeleteTenantSensor?id=${id}`));
    }
}
