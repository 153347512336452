<body class="dashboard dashboard-bg">
    <app-header></app-header>

    <div class="body-container collapsed">
        <app-sidebar></app-sidebar>

        <div class="body-content">
            <app-progress-spinner [show]="isLoading"></app-progress-spinner>

            <div class="row">
                <div class="col p-0">
                    <app-chart-header [itemId]="sensorId" [items]="sensors" iconClass="fas fa-broadcast-tower"
                        (selectionChanged)="switchSensor($event)"></app-chart-header>
                </div>
            </div>

            <div class="row mt-2 device-status">
                <div class="col-auto ml-auto">
                    <mat-label>
                        <span class="text-muted">Status as of:</span>
                        {{sensorRecord.dateTime ? (sensorRecord.dateTime | date:"medium") : 'Never'}}
                    </mat-label>
                </div>
            </div>
            <div *ngIf="sensorType && (sensorType.includes('noise-timeseries') || sensorType.includes('AirNowMonitoringSiteObservations') || sensorType.includes('GasData'))"
                class="row mt-4 device-status">
                <div class="col-sm-auto ml-auto"></div>
                <div *ngIf="sensorType.includes('noise-timeseries')" class="col-sm-auto">
                    <mat-label>
                        <span class="text-muted">Avg Sound Level:</span>
                        {{sensorRecord.lavg !== undefined ? (sensorRecord.lavg | number:'1.0-2') : '-'}} db
                    </mat-label>
                </div>
                <div *ngIf="tenantPermissions.allowAirnowDevices && sensorType.includes('AirNowMonitoringSiteObservations')"
                    class="col-sm-auto">
                    <mat-label>
                        <span class="text-muted">Ozone:</span>
                        {{sensorRecord.ozone !== undefined ? (sensorRecord.ozone | number:'1.0-2') : '-'}} PPB
                    </mat-label>
                </div>
                <div *ngIf="tenantPermissions.allowAirnowDevices && sensorType.includes('AirNowMonitoringSiteObservations')"
                    class="col-sm-auto">
                    <mat-label>
                        <span class="text-muted">NO2:</span>
                        {{sensorRecord.nO2 !== undefined ? (sensorRecord.nO2 | number:'1.0-2') : '-'}} PPB
                    </mat-label>
                </div>
                <div *ngIf="tenantPermissions.allowAirnowDevices && sensorType.includes('AirNowMonitoringSiteObservations')"
                    class="col-sm-auto">
                    <mat-label>
                        <span class="text-muted">SO2:</span>
                        {{sensorRecord.sO2 !== undefined ? (sensorRecord.sO2 | number:'1.0-2') : '-'}} PPB
                    </mat-label>
                </div>
                <div *ngIf="tenantPermissions.allowAirnowDevices && sensorType.includes('AirNowMonitoringSiteObservations')"
                    class="col-sm-auto">
                    <mat-label>
                        <span class="text-muted">CO:</span>
                        {{sensorRecord.co !== undefined ? (sensorRecord.co | number:'1.0-2') : '-'}} PPM
                    </mat-label>
                </div>
                <div *ngIf="tenantPermissions.canViewCO2 && sensorType.includes('GasData')"
                    class="col-sm-auto">
                    <mat-label>
                        <span class="text-muted">CO2:</span>
                        {{sensorRecord.cO2 !== undefined ? (sensorRecord.cO2 | number:'1.0-2') : '-'}} PPM
                    </mat-label>
                </div>
                <div *ngIf="tenantPermissions.canViewTVOC && sensorType.includes('GasData')"
                    class="col-sm-auto">
                    <mat-label>
                        <span class="text-muted">TVOC:</span>
                        {{sensorRecord.tvoc !== undefined ? (sensorRecord.tvoc | number:'1.0-2') : '-'}} PPM
                    </mat-label>
                </div>
            </div>
            <div *ngIf="sensorType && (sensorType.includes('particulate-timeseries') || sensorType.includes('opc-r2') || sensorType.includes('AirNowMonitoringSiteObservations'))"
                class="row mt-4 device-status">
                <div class="col-sm-auto ml-auto"></div>
                <div *ngIf="tenantPermissions.canViewPm1_0 && (sensorType.includes('particulate-timeseries') || sensorType.includes('opc-r2'))"
                    class="col-sm-auto">
                    <mat-label>
                        <span class="text-muted">PM1.0:</span>
                        {{sensorRecord.pm1 !== undefined ? (sensorRecord.pm1 | number:'1.0-2') : '-'}}
                        {{pmUnit}}
                    </mat-label>
                </div>
                <div *ngIf="tenantPermissions.canViewPm2_5" class="col-sm-auto">
                    <mat-label>
                        <span class="text-muted">PM2.5:</span>
                        {{sensorRecord.pm2_5 !== undefined ? (sensorRecord.pm2_5 | number:'1.0-2') : '-'}}
                        {{pmUnit}}
                    </mat-label>
                </div>
                <div *ngIf="tenantPermissions.canViewPm10_0" class="col-sm-auto">
                    <mat-label>
                        <span class="text-muted">PM10.0</span>
                        {{sensorRecord.pm10 !== undefined ? (sensorRecord.pm10 | number:'1.0-2') : '-'}}
                        {{pmUnit}}
                    </mat-label>
                </div>
                <div *ngIf="tenantPermissions.canViewRespirablePm && (sensorType.includes('particulate-timeseries') || sensorType.includes('opc-r2'))"
                    class="col-sm-auto">
                    <mat-label>
                        <span class="text-muted">Respirable PM:</span>
                        {{sensorRecord.respirablePm !== undefined ? (sensorRecord.respirablePm | number:'1.0-2') : '-'}}
                        {{pmUnit}}
                    </mat-label>
                </div>
                <div *ngIf="tenantPermissions.canViewEnvironmentalData && (sensorType.includes('particulate-timeseries') || sensorType.includes('opc-r2'))"
                    class="col-sm-auto">
                    <mat-label>
                        <span class="text-muted">Temperature:</span>
                        {{sensorRecord.tempC !== undefined ? (sensorRecord.tempC | number:'1.0-2') : '-'}}
                        {{temperatureUnit}}
                    </mat-label>
                </div>
                <div *ngIf="tenantPermissions.canViewEnvironmentalData && (sensorType.includes('particulate-timeseries') || sensorType.includes('opc-r2'))"
                    class="col-sm-auto">
                    <mat-label>
                        <span class="text-muted">Humidity:</span>
                        {{sensorRecord.hum !== undefined ? (sensorRecord.hum | number:'1.0-2') : '-'}} %
                    </mat-label>
                </div>
                <div *ngIf="sensorType.includes('particulate-timeseries') || sensorType.includes('opc-r2')"
                    class="col-auto mr-5">
                    <mat-icon class="mr-4">
                        <img style="width: 30px; height: 24px; vertical-align: baseline;" [src]="icon">
                    </mat-icon>
                    <mat-icon>
                        <i [class]="batteryPercentageClass"></i>
                        <span class="ml-2">
                            {{sensorRecord.batteryPercentage !== undefined ? sensorRecord.batteryPercentage : '-'}} %
                        </span>
                    </mat-icon>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-md-auto pl-0">
                    <div id="zoom-buttons">
                        <button mat-button class="btn button-accent zoom-8h active ml-2 mt-3" (click)="zoom('8h')"
                            [disabled]="isMapLoading">
                            <mat-icon>
                                <i class="fas fa-clock fa-sm"></i>
                            </mat-icon>
                            <div>8h</div>
                        </button>
                        <button mat-button class="btn button-primary zoom-3d ml-2 mt-3" (click)="zoom('3d')"
                            [disabled]="isMapLoading">
                            <mat-icon>
                                <i class="fas fa-clock fa-sm"></i>
                            </mat-icon>
                            <div>3d</div>
                        </button>
                        <button mat-button class="btn button-primary zoom-1m ml-2 mt-3" (click)="zoom('1m')"
                            [disabled]="isMapLoading">
                            <mat-icon>
                                <i class="fas fa-clock fa-sm"></i>
                            </mat-icon>
                            <div>1m</div>
                        </button>
                        <button mat-button class="btn button-primary zoom-all ml-2 mt-3" (click)="zoom('all')"
                            [disabled]="isMapLoading">
                            <mat-icon>
                                <i class="fas fa-clock fa-sm"></i>
                            </mat-icon>
                            <div>All</div>
                        </button>
                    </div>
                </div>

                <div class="col-md-3">
                    <mat-form-field>
                        <mat-label>From Date</mat-label>
                        <input matInput [(ngModel)]="fromDate" class="clickable" [owlDateTime]="dtStart"
                            [owlDateTimeTrigger]="dtStart" required (click)="onDateFilterClick()" />
                        <mat-icon matPrefix class="clickable mr-3" [owlDateTimeTrigger]="dtStart"
                            (click)="onDateFilterClick()">
                            <i class="fas fa-calendar-alt"></i>
                        </mat-icon>
                        <mat-error>From Date is required.</mat-error>
                        <owl-date-time #dtStart></owl-date-time>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>To Date</mat-label>
                        <input matInput [(ngModel)]="toDate" class="clickable" [owlDateTime]="dtEnd"
                            [owlDateTimeTrigger]="dtEnd" required (click)="onDateFilterClick()" />
                        <mat-icon matPrefix class="clickable mr-3" [owlDateTimeTrigger]="dtEnd"
                            (click)="onDateFilterClick()">
                            <i class="fas fa-calendar-alt"></i>
                        </mat-icon>
                        <mat-error>To Date is required.</mat-error>
                        <owl-date-time #dtEnd></owl-date-time>
                    </mat-form-field>
                </div>

                <div class="col-md-2">
                    <mat-form-field>
                        <mat-label>Parameter(s)</mat-label>
                        <mat-select [(ngModel)]="selectedParams" multiple required>
                            <mat-option *ngFor="let param of parameters" [value]="param.id">
                                {{param.value}}
                            </mat-option>
                        </mat-select>
                        <mat-icon matPrefix class="mr-3">
                            <i class="fas fa-chart-line"></i>
                        </mat-icon>
                        <mat-error>Parameter is required.</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-3">
                    <button class="btn button-secondary mt-3 mr-2" mat-button [class.button-spinner]="isMapLoading"
                        (click)="search()" [disabled]="isDisabled() || isMapLoading">Plot</button>

                    <button *ngIf="tenantPermissions.canExportData" class="btn button-primary-light mt-3 mb-3"
                        mat-button (click)="export()" [disabled]="!fromDate || !toDate || isMapLoading">Export</button>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-8 p-0">
                    <div class="row">
                        <div class="col" (resized)="onResized($event)">
                            <div [id]="CHART_DIV_ID" style="width: 100%; height: 800px;"></div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="row">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th *ngFor="let param of columnParameters">
                                        {{getParamName(param)}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Average [{{getParamUnit()}}]</td>
                                    <td *ngFor="let param of columnParameters">
                                        {{getValue(0, param) | number:'1.0-2'}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Peak [{{getParamUnit()}}]</td>
                                    <td *ngFor="let param of columnParameters">
                                        {{getValue(1, param) | number:'1.0-2'}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="row" *ngIf="preferences.showMap">
                        <div class="col p-0">
                            <agm-map [maxZoom]="22" [mapTypeControl]="true" [styles]="agmMapStyles"
                                (idle)="onMapIdle()">
                                <app-map-clusterer></app-map-clusterer>
                            </agm-map>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row ml-3 mr-3" [hidden]="isVideoChartHidden">
                <div class="col-lg-8 pl-4 pr-4" (resized)="onVideoResized($event)">
                    <div [id]="CHART_VIDEO_DIV_ID" style="width: 100%; height: 150px;"></div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-8 p-0">
                    <div *ngIf="videoResource">
                        <vg-player [hidden]="!videoResource.url" (onPlayerReady)="onPlayerReady($event)">
                            <vg-buffering></vg-buffering>

                            <vg-controls>
                                <vg-play-pause></vg-play-pause>
                                <vg-playback-button></vg-playback-button>

                                <vg-mute></vg-mute>
                                <vg-volume></vg-volume>

                                <vg-fullscreen></vg-fullscreen>
                            </vg-controls>

                            <video #media [vgMedia]="media" [src]="videoResource.url" id="singleVideo" preload="auto">
                            </video>
                        </vg-player>
                    </div>
                </div>

                <div class="col-lg-3 offset-lg-1 p-0">
                    <mat-form-field>
                        <mat-label>Time Zone</mat-label>
                        <input matInput [(ngModel)]="timeZoneLabel" readonly />
                        <mat-icon matPrefix class="mr-3">
                            <i class="fas fa-globe"></i>
                        </mat-icon>
                    </mat-form-field>
                    <div [hidden]="isVideoChartHidden" style="min-height: 70vh">&nbsp;</div>
                </div>
            </div>

            <div class="clearfix"></div>
            <app-footer></app-footer>
        </div>
    </div>
</body>
