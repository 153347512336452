import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, mapTo, catchError, finalize, tap } from 'rxjs/operators';
import { Tokens } from '../models/tokens.model';
import { UserIdentity } from '../models/userIdentity.model';
import { EndpointService } from './endpoint.service';
import { LoginResponse } from '../models/loginResponse.model';
import { SecurityProfileEnum } from '../enums/securityProfile.enum';
import { UserPreferences } from '../models/userPreferences.model';
import { DataAnalysisRequestModel } from '../models/dataAnalysisRequest.model';
import { DateFormatService } from './dateFormat.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends EndpointService {

    private readonly ACCESS_TOKEN = 'ACCESS_TOKEN';
    private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
    private readonly CURRENT_USER = 'CURRENT_USER';
    private readonly REMEMBER_ME = 'REMEMBER_ME';

    constructor(http: HttpClient,
        private dateFormatService: DateFormatService) {
        super(http);
    }

    login(username: string, password: string) {
        return this.http.post<LoginResponse>(this.getApiUrl('Auth/Login'), { username, password })
            .pipe(
                tap(response => this.handleLoginResponse(response))
            );
    }

    logout(): Observable<boolean> {
        return this.http.post<any>(this.getApiUrl('Auth/Logout'), {
            'refreshToken': this.getRefreshToken()
        }).pipe(
            mapTo(true),
            catchError(
                error => {
                    console.log(error);
                    return of(false);
                }
            ),
            finalize(() => this.doLogoutUser())
        );
    }

    refreshToken() {
        return this.http.post<any>(this.getApiUrl('Auth/RefreshToken'), {
            'accessToken': this.getAccessToken(),
            'refreshToken': this.getRefreshToken()
        }).pipe(
            map((response: any) => {
                const tokens = this.mapToTokens(response);
                this.storeTokens(tokens);
                return tokens;
            }),
            catchError(
                error => {
                    console.log(error);
                    return of(null);
                }
            )
        );
    }

    revokeTokens() {
        return this.http.post<any>(this.getApiUrl('Auth/RevokeTokens'), {
            'accessToken': this.getAccessToken(),
            'refreshToken': this.getRefreshToken()
        }).pipe(
            mapTo(true),
            tap(_ => this.doLogoutUser()),
            catchError(error => {
                console.log(error);
                this.doLogoutUser();
                return of(false);
            })
        );
    }

    impersonateUser(userId: string) {
        return this.http.post<LoginResponse>(this.getApiUrl('Auth/ImpersonateUser'), { userId })
            .pipe(
                tap(response => this.handleLoginResponse(response, true))
            );
    }

    isLoggedIn() {
        return !!this.getAccessToken();
    }

    getCurrentUser(): UserIdentity {
        return JSON.parse(localStorage.getItem(this.CURRENT_USER)) as UserIdentity;
    }

    setCurrentUserPreferences(preferences: UserPreferences) {
        if (!preferences) {
            return;
        }
        const currentUser = this.getCurrentUser();
        currentUser.preferences.pmUnitId = preferences.pmUnitId;
        currentUser.preferences.temperatureUnitId = preferences.temperatureUnitId;
        currentUser.preferences.showMap = preferences.showMap;
        currentUser.preferences.defaultMapLocation = preferences.defaultMapLocation;
        localStorage.setItem(this.CURRENT_USER, JSON.stringify(currentUser));
    }

    setCurrentUserParameters(parameters: number[]) {
        if (!parameters || !parameters.length) {
            return;
        }
        const currentUser = this.getCurrentUser();
        currentUser.preferences.parameters = parameters;
        localStorage.setItem(this.CURRENT_USER, JSON.stringify(currentUser));
    }

    setCurrentUserAnalysisParameters(model: DataAnalysisRequestModel) {
        const selectedDevice = model.isViewByEndPoint ? `0+${model.endPointId}` : `1+${model.sensorId}`;
        if (model.selectedDevices[0] !== selectedDevice) {
            return;
        }
        const currentUser = this.getCurrentUser();
        currentUser.preferences.parameters = model.parameters;
        currentUser.preferences.selectedDevices = model.selectedDevices;
        currentUser.preferences.fromDate = this.dateFormatService.convertStringToDateTime(model.startDate);
        currentUser.preferences.toDate = this.dateFormatService.convertStringToDateTime(model.endDate);
        currentUser.preferences.timeIntervalId = model.timeIntervalId;
        currentUser.preferences.shiftStart = this.dateFormatService.convertStringToDateTime(model.startTime);
        currentUser.preferences.shiftEnd = this.dateFormatService.convertStringToDateTime(model.endTime);
        localStorage.setItem(this.CURRENT_USER, JSON.stringify(currentUser));
    }

    setCurrentUserViewBySensor(isViewBySensor: boolean) {
        const currentUser = this.getCurrentUser();
        currentUser.preferences.isViewBySensor = isViewBySensor;
        localStorage.setItem(this.CURRENT_USER, JSON.stringify(currentUser));
    }

    setCurrentUserTimeZone(timeZone: string) {
        const currentUser = this.getCurrentUser();
        currentUser.userTimeZone = timeZone;
        localStorage.setItem(this.CURRENT_USER, JSON.stringify(currentUser));
    }

    setCurrentUserNotificationDetails(parameter: number, fromDate: Date, toDate: Date) {
        const currentUser = this.getCurrentUser();
        currentUser.preferences.parameters = [parameter];
        currentUser.preferences.fromDate = fromDate;
        currentUser.preferences.toDate = new Date(toDate);
        currentUser.preferences.toDate.setMinutes(currentUser.preferences.toDate.getMinutes() + 1);
        localStorage.setItem(this.CURRENT_USER, JSON.stringify(currentUser));
    }

    setCurrentUserEndPoint(endPointId: number) {
        const currentUser = this.getCurrentUser();
        currentUser.preferences.endPointId = endPointId;
        localStorage.setItem(this.CURRENT_USER, JSON.stringify(currentUser));
    }

    getAccessToken() {
        return localStorage.getItem(this.ACCESS_TOKEN);
    }

    getRefreshToken() {
        return localStorage.getItem(this.REFRESH_TOKEN);
    }

    getRememberMe() {
        const stored = JSON.parse(localStorage.getItem(this.REMEMBER_ME)) as boolean;

        return stored === true || stored === null;
    }

    setRememberMe(rememberMe: boolean) {
        localStorage.setItem(this.REMEMBER_ME, JSON.stringify(rememberMe));
    }

    verifyOtp(username: string, otp: string) {
        return this.http.post<LoginResponse>(this.getApiUrl('Auth/VerifyOtp'), { username, otp })
            .pipe(
                tap(response => this.handleLoginResponse(response))
            );
    }

    private doLoginUser(user: UserIdentity, tokens: Tokens) {
        localStorage.setItem(this.CURRENT_USER, JSON.stringify(user));
        this.storeTokens(tokens);
    }

    private doLogoutUser() {
        localStorage.setItem(this.CURRENT_USER, null);
        this.removeTokens();
    }

    private storeTokens(tokens: Tokens) {
        if (tokens === null) {
            return;
        }

        localStorage.setItem(this.ACCESS_TOKEN, tokens.accessToken);
        localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
    }

    private removeTokens() {
        localStorage.removeItem(this.ACCESS_TOKEN);
        localStorage.removeItem(this.REFRESH_TOKEN);
    }

    private handleLoginResponse(response: LoginResponse, isImpersonated: boolean = false) {
        const user = this.mapToUserIdentity(response, isImpersonated);
        const tokens = this.mapToTokens(response);

        if (user && user.userType === SecurityProfileEnum.User) {
            const encUserId = window.btoa(response.userId);
            localStorage.setItem('deviceName', encUserId);
        }

        this.doLoginUser(user, tokens);
    }

    private mapToUserIdentity(response: LoginResponse, isImpersonated: boolean): UserIdentity {
        if (response && response.userId) {
            const user = new UserIdentity();
            user.userType = response.userType;
            user.userEmail = response.userEmail;
            user.userTimeZone = response.userTimeZone;
            user.tenantPermissions = response.tenantPermissions;
            user.tenantLogoUrl = response.tenantLogoUrl;
            user.preferences = response.preferences;
            user.isImpersonated = isImpersonated;

            return user;
        }

        return null;
    }

    private mapToTokens(response: LoginResponse): Tokens {
        if (response && response.accessToken) {
            const tokens = new Tokens();
            tokens.accessToken = response.accessToken.token;
            tokens.refreshToken = response.refreshToken;

            return tokens;
        }

        return null;
    }
}
