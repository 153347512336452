import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable()
export class DateFormatService {
    constructor() { }

    formatDateTimeToString(date: Date): string {
        if (date) {
            return formatDate(date, 'yyyy-MM-dd HH:mm:ss', 'en-US');
        }

        return '';
    }

    convertStringToDateTime(dateString: string): Date {
        try {
            if (dateString) {
                return new Date(dateString);
            }
        } catch (error) {
            console.log(error);
        }

        return undefined;
    }
}
