export enum FeatureUsagePageEnum {
    None = 0,

    DashboardPage = 1000,
    DevicePage = 1001,
    UserPage = 1002,
    SensorsPage = 1003,
    SensorAssignmentsPage = 1004,
    UsersPage = 1005,
    DataAnalysisPage = 1006,
    LoginPage = 1007,
    EditProfilePage = 1008,
    ChangePasswordPage = 1009,
    NotificationSettingsPage = 1010,
    NotificationListPage = 1011,
    EndPointListPage = 1012,

    Sidebar = 2001,
    Header = 2002,

    CreateUserDialog = 3001,
    EditUserDialog = 3002,
    EditSensorDialog = 3003,
    ConfigureSensorDialog = 3004,
    CreateSensorAssignmentDialog = 3005,
    EditSensorAssignmentDialog = 3006,
    CreateNotificationSettingsDialog = 3007,
    EditNotificationSettingsDialog = 3008,
    CloneNotificationSettingsDialog = 3009,
    AssignNotificationSettingsDialog = 3010,
    ViewNotificationDialog = 3011,
    CreateCustomObjectDialog = 3012,
    EditCustomObjectDialog = 3013,
    CreateCustomEntityDialog = 3014,
    EditCustomEntityDialog = 3015,
    CreateEndPointDialog = 3016,
    MfaDialog = 3017,
}
