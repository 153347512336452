import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/toast.service';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { AccountService } from 'src/app/services/account.service';
import { AuthService } from 'src/app/services/auth.service';

export interface MfaDialogData {
    username: string;
    isLogin: boolean;
}

@Component({
    selector: 'app-mfa-dialog-component',
    templateUrl: './mfaDialog.component.html'
})
export class MfaDialogComponent extends TrackableComponent implements OnDestroy {

    isLoading = false;
    otp: string;

    constructor(public dialogRef: MatDialogRef<MfaDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MfaDialogData,
        private accountService: AccountService,
        private authService: AuthService,
        private toastService: ToastService,
        featureUsageService: FeatureUsageService) {

        super(featureUsageService, FeatureUsagePageEnum.MfaDialog);
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    ok() {
        this.isLoading = true;
        const request = this.data.isLogin
            ? this.authService.verifyOtp(this.data.username, this.otp)
            : this.accountService.verifyOtp(this.otp);
        
        request.subscribe(response =>
            {
                this.isLoading = false;
                this.toastService.showSuccess('OTP Code verified successfully.');
                this.dialogRef.close(true);
            }, error => {
                console.log(error);
                this.isLoading = false;
                this.toastService.showError(error);
            });

        // this.recordAction(FeatureUsageActionEnum.);
    }

    onClose() {
        // this.recordAction(FeatureUsageActionEnum.);
    }
}
