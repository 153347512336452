import { Injectable } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { Enum } from '../models/enum.model';
import { AuthService } from './auth.service';
import { ParameterTypeEnum } from '../enums/parameterType.enum';
import { UserPreferences } from '../models/userPreferences.model';
import { PmUnitEnum } from '../enums/pmUnit.enum';
import { TemperatureUnitEnum } from '../enums/temperatureUnit.enum';
import { TenantPermissions } from '../models/tenantPermissions.model';
import { SensorRecord } from '../models/sensorRecord.model';
import { ToastService } from './toast.service';
import { RecordDto } from '../models/recordDto.model';
import { OnZoom } from '../components/chartBase/onZoom.interface';
import { DeviceValue } from '../models/deviceValue.model';
import { ResizedEvent } from 'angular-resize-event';
import { TrackableComponent } from '../shared/featureUsage/trackable.component';
import { ErrorCodeEnum } from '../enums/errorCode.enum';
import { IMapComponent } from '../components/chartBase/mapComponent.interface';
import { FeatureUsageActionEnum } from '../enums/featureUsageAction.enum';
import { VideoResource } from '../models/videoResource.model';

@Injectable()
export class ChartService {

    timestamp = 0;
    initialTimestamp = 0;
    analyticsTimestamp = 0;
    analyticsVerticalTimestamp = 0;
    resizeTimestamp: { [key: string]: number | undefined } = {};

    constructor(private authService: AuthService,
        private toastService: ToastService) { }

    getSensorParameters(sensorType: string[]) {
        let parameters = this.getParameters();
        if (!sensorType.includes('noise-timeseries')) {
            parameters = parameters.filter(x => x.id !== ParameterTypeEnum.Lavg);
        }
        if (!sensorType.includes('GasData')) {
            parameters = parameters.filter(x =>
                x.id !== ParameterTypeEnum.TVOC &&
                x.id !== ParameterTypeEnum.CO2);
        }
        if (!sensorType.includes('AirNowMonitoringSiteObservations')) {
            parameters = parameters.filter(x =>
                x.id !== ParameterTypeEnum.Ozone &&
                x.id !== ParameterTypeEnum.SO2 &&
                x.id !== ParameterTypeEnum.NO2 &&
                x.id !== ParameterTypeEnum.CO);
        }
        if (!sensorType.includes('particulate-timeseries') && !sensorType.includes('opc-r2')) {
            parameters = parameters.filter(x =>
                x.id !== ParameterTypeEnum.Pm1 &&
                x.id !== ParameterTypeEnum.RespirablePm &&
                x.id !== ParameterTypeEnum.Humidity &&
                x.id !== ParameterTypeEnum.Temperature);
        }
        if (!sensorType.includes('particulate-timeseries') && !sensorType.includes('opc-r2') && !sensorType.includes('AirNowMonitoringSiteObservations')) {
            parameters = parameters.filter(x =>
                x.id !== ParameterTypeEnum.Pm2_5 &&
                x.id !== ParameterTypeEnum.Pm10);
        }

        return parameters;
    }

    getParameters() {
        const tenantPermissions = this.authService.getCurrentUser().tenantPermissions;
        const parameters: Enum[] = [];
        if (tenantPermissions.canViewPm1_0) {
            parameters.push(new Enum(ParameterTypeEnum.Pm1, this.getParameterName(ParameterTypeEnum.Pm1)));
        }
        if (tenantPermissions.canViewPm2_5) {
            parameters.push(new Enum(ParameterTypeEnum.Pm2_5, this.getParameterName(ParameterTypeEnum.Pm2_5)));
        }
        if (tenantPermissions.canViewPm10_0) {
            parameters.push(new Enum(ParameterTypeEnum.Pm10, this.getParameterName(ParameterTypeEnum.Pm10)));
        }
        if (tenantPermissions.canViewRespirablePm) {
            parameters.push(new Enum(ParameterTypeEnum.RespirablePm, this.getParameterName(ParameterTypeEnum.RespirablePm)));
        }
        if (tenantPermissions.canViewEnvironmentalData) {
            parameters.push(new Enum(ParameterTypeEnum.Humidity, this.getParameterName(ParameterTypeEnum.Humidity)));
            parameters.push(new Enum(ParameterTypeEnum.Temperature, this.getParameterName(ParameterTypeEnum.Temperature)));
        }
        parameters.push(new Enum(ParameterTypeEnum.Lavg, this.getParameterName(ParameterTypeEnum.Lavg))); // TODO: tenant permission
        if (tenantPermissions.allowAirnowDevices) {
            parameters.push(new Enum(ParameterTypeEnum.Ozone, this.getParameterName(ParameterTypeEnum.Ozone)));
            parameters.push(new Enum(ParameterTypeEnum.SO2, this.getParameterName(ParameterTypeEnum.SO2)));
            parameters.push(new Enum(ParameterTypeEnum.NO2, this.getParameterName(ParameterTypeEnum.NO2)));
            parameters.push(new Enum(ParameterTypeEnum.CO, this.getParameterName(ParameterTypeEnum.CO)));
        }
        if (tenantPermissions.canViewCO2) {
            parameters.push(new Enum(ParameterTypeEnum.CO2, this.getParameterName(ParameterTypeEnum.CO2)));
        }
        if (tenantPermissions.canViewTVOC) {
            parameters.push(new Enum(ParameterTypeEnum.TVOC, this.getParameterName(ParameterTypeEnum.TVOC)));
        }
        if (tenantPermissions.canViewPm1_0_A) {
            parameters.push(new Enum(ParameterTypeEnum.Pm1_A, this.getParameterName(ParameterTypeEnum.Pm1_A)));
        }
        if (tenantPermissions.canViewPm2_5_A) {
            parameters.push(new Enum(ParameterTypeEnum.Pm2_5_A, this.getParameterName(ParameterTypeEnum.Pm2_5_A)));
        }
        if (tenantPermissions.canViewPm10_0_A) {
            parameters.push(new Enum(ParameterTypeEnum.Pm10_A, this.getParameterName(ParameterTypeEnum.Pm10_A)));
        }
        if (tenantPermissions.canViewRespirablePm_A) {
            parameters.push(new Enum(ParameterTypeEnum.RespirablePm_A, this.getParameterName(ParameterTypeEnum.RespirablePm_A)));
        }
        if (tenantPermissions.canViewBinMass) {
            parameters.push(new Enum(ParameterTypeEnum.BinMass, this.getParameterName(ParameterTypeEnum.BinMass)));
        }

        return parameters;
    }

    createChart(id: string, trackableComponent: TrackableComponent) {
        const chart = am4core.create(id, am4charts.XYChart);

        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.baseInterval = {
            timeUnit: 'second',
            count: 1
        };
        dateAxis.tooltipDateFormat = 'yyyy-MM-dd HH:mm:ss';
        dateAxis.title.text = 'Time';
        dateAxis.title.fontWeight = 'bold';
        dateAxis.keepSelection = false;
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.labels.template.location = 0.000001;

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.title.fontWeight = 'bold';
        valueAxis.keepSelection = false;

        chart.legend = new am4charts.Legend();
        chart.cursor = new am4charts.XYCursor();
        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.events.on('exportstarted', (event) => {
            let action = FeatureUsageActionEnum.None;
            switch (event.format) {
                case 'png':
                    action = FeatureUsageActionEnum.ChartPngExport;
                    break;
                case 'jpg':
                    action = FeatureUsageActionEnum.ChartJpgExport;
                    break;
                case 'svg':
                    action = FeatureUsageActionEnum.ChartSvgExport;
                    break;
                case 'pdf':
                    action = FeatureUsageActionEnum.ChartPdfExport;
                    break;
                case 'json':
                    action = FeatureUsageActionEnum.ChartJsonExport;
                    break;
                case 'csv':
                    action = FeatureUsageActionEnum.ChartCsvExport;
                    break;
                case 'xlsx':
                    action = FeatureUsageActionEnum.ChartXlsxExport;
                    break;
                case 'html':
                    action = FeatureUsageActionEnum.ChartHtmlExport;
                    break;
                case 'print':
                    action = FeatureUsageActionEnum.ChartPrint;
                    break;
            }
            if (action !== FeatureUsageActionEnum.None) {
                trackableComponent.recordAction(action);
            }
        });
        chart.scrollbarY = new am4core.Scrollbar();

        const timeZone = this.authService.getCurrentUser().userTimeZone;
        chart.dateFormatter.timezone = timeZone;

        chart.svgContainer.autoResize = false;

        return chart;
    }

    createChartZoomEvent(chart: am4charts.XYChart, zoomComponent: OnZoom, zoomToValues = true) {
        const dateAxis = chart.xAxes.getIndex(0) as am4charts.DateAxis;

        return dateAxis.events.on('rangechangeended', (event) => {
            const myTime = (new Date()).getTime();
            const self = this;
            self.timestamp = myTime;

            setTimeout(() => {
                if (myTime !== self.timestamp) {
                    return;
                }

                const timestampStart = event.target.minZoomed;
                const timestampEnd = event.target.maxZoomed;

                if (!zoomToValues) {
                    zoomComponent.onZoom({ start: timestampStart, end: timestampEnd });
                    return;
                }

                if (!timestampStart || !timestampEnd ||
                    !chart.data || chart.data.length < 1) {
                    zoomComponent.onZoom([]);
                    return;
                }

                const sensorRecords: SensorRecord[] = chart.data.filter((s: SensorRecord) =>
                    s.timestamp > timestampStart && s.timestamp < timestampEnd);
                zoomComponent.onZoom(sensorRecords);
            }, 500);
        }, this);
    }

    createChartInitialZoomEvent(chart: am4charts.XYChart, zoomComponent: OnZoom, zoomToValues = true) {
        const dateAxis = chart.xAxes.getIndex(0) as am4charts.DateAxis;

        return dateAxis.events.on('datarangechanged', (event) => {
            const timestampStart = event.target.minZoomed;
            const timestampEnd = event.target.maxZoomed;

            if (!timestampStart || !timestampEnd) {
                return;
            }

            const myTime = (new Date()).getTime();
            const self = this;
            self.initialTimestamp = myTime;

            setTimeout(() => {
                if (myTime !== self.initialTimestamp) {
                    return;
                }

                if (!zoomToValues) {
                    zoomComponent.onInitialZoom({ start: timestampStart, end: timestampEnd });
                    return;
                }

                if (!timestampStart || !timestampEnd ||
                    !chart.data || chart.data.length < 1) {
                    zoomComponent.onInitialZoom([]);
                    return;
                }

                const sensorRecords: SensorRecord[] = chart.data.filter((s: SensorRecord) =>
                    s.timestamp > timestampStart && s.timestamp < timestampEnd);
                zoomComponent.onInitialZoom(sensorRecords);
            }, 3000);
        }, this);
    }

    createChartAnalyticsEvent(chart: am4charts.XYChart, zoomComponent: OnZoom) {
        const dateAxis = chart.xAxes.getIndex(0) as am4charts.DateAxis;

        return dateAxis.events.on('selectionextremeschanged', (event) => {
            const myTime = (new Date()).getTime();
            const self = this;
            self.analyticsTimestamp = myTime;

            setTimeout(() => {
                if (myTime !== self.analyticsTimestamp) {
                    return;
                }

                zoomComponent.onAnalyticsHorizontalZoom();
            }, 500);
        }, this);
    }

    createChartAnalyticsVerticalZoomEvent(chart: am4charts.XYChart, zoomComponent: OnZoom) {
        const valueAxis = chart.yAxes.getIndex(0) as am4charts.ValueAxis;

        return valueAxis.events.on('selectionextremeschanged', (event) => {
            const myTime = (new Date()).getTime();
            const self = this;
            self.analyticsVerticalTimestamp = myTime;

            setTimeout(() => {
                if (myTime !== self.analyticsVerticalTimestamp) {
                    return;
                }

                zoomComponent.onAnalyticsVerticalZoom();
            }, 500);
        }, this);
    }

    onResized(chart: am4charts.XYChart, event: ResizedEvent, chartId: string) {
        if (!chart || event.oldHeight === undefined || event.oldWidth === undefined) {
            return;
        }

        const myTime = (new Date()).getTime();
        const self = this;
        self.resizeTimestamp[chartId] = myTime;

        setTimeout(() => {
            if (myTime !== self.resizeTimestamp[chartId]) {
                return;
            }

            chart.svgContainer.measure();
        }, 100);
    }

    drawSensorRecordChart(chart: am4charts.XYChart, records: SensorRecord[], parameters: ParameterTypeEnum[], mapComponent: IMapComponent) {
        const currentUser = this.authService.getCurrentUser();
        const params = parameters.filter(parameter => this.canView(parameter, currentUser.tenantPermissions));
        if (params.length < 1) {
            return;
        }

        this.disposeChartData(chart);

        const lineSeries = [];
        params.forEach(p => {
            const s = chart.series.push(this.createSeries(p, mapComponent, currentUser.preferences));
            lineSeries.push(s);
        });

        chart.yAxes.getIndex(0).title.text = this.getValueAxisTitle(params, currentUser.preferences);

        this.createXYChartScrollbar(chart, lineSeries);
        chart.scrollbarY = new am4core.Scrollbar();

        chart.data = records;
    }

    drawVideoChart(chart: am4charts.XYChart, videoResources: VideoResource[]) {
        this.disposeChartData(chart);

        chart.exporting.menu.items = [];
        const dateAxis = chart.xAxes.getIndex(0) as am4charts.DateAxis;
        dateAxis.tooltip.dy = 10;

        if (!videoResources || videoResources.length === 0) {
            return;
        }

        let minTimestamp = videoResources[0].timestampStart;
        let maxTimestamp = videoResources[0].timestampEnd;
        videoResources.forEach(resource => {
            if (resource.timestampStart < minTimestamp) {
                minTimestamp = resource.timestampStart;
            }
            if (resource.timestampEnd > maxTimestamp) {
                maxTimestamp = resource.timestampEnd;
            }

            const range = dateAxis.axisRanges.push(new am4charts.DateAxisDataItem());
            range.value = resource.timestampStart;
            range.endValue = resource.timestampEnd;
            range.axisFill.fill = am4core.color('#f00');
            range.axisFill.fillOpacity = 0.2;
        });

        dateAxis.strictMinMax = true;
        dateAxis.min = minTimestamp;
        dateAxis.max = maxTimestamp;
    }

    createXYChartScrollbar(chart: am4charts.XYChart, lineSeries: any[]) {
        const scrollbarX = new am4charts.XYChartScrollbar();
        scrollbarX.dateFormatter.timezoneOffset = chart.dateFormatter.timezoneOffset;
        scrollbarX.series.pushAll(lineSeries);
        const scrollAxis = scrollbarX.scrollbarChart.xAxes.getIndex(0);
        scrollAxis.renderer.labels.template.disabled = true;
        scrollAxis.renderer.grid.template.disabled = true;
        chart.scrollbarX = scrollbarX;
    }

    disposeChartData(chart: am4charts.XYChart) {
        if (chart.scrollbarX && !chart.scrollbarX.isDisposed()) {
            const scrollbarToDispose = chart.scrollbarX as am4charts.XYChartScrollbar;
            if (scrollbarToDispose && scrollbarToDispose.scrollbarChart) {
                while (scrollbarToDispose.scrollbarChart.series.length > 0) {
                    const seriesToDispose = scrollbarToDispose.scrollbarChart.series.removeIndex(0);
                    seriesToDispose.disposeData();
                    seriesToDispose.disposeChildren();
                    seriesToDispose.dispose();
                    seriesToDispose.clonedFrom = undefined;
                }
                scrollbarToDispose.disposeChildren();
                scrollbarToDispose.dispose();
            } else {
                chart.scrollbarX.disposeChildren();
                chart.scrollbarX.dispose();
            }
        }
        if (chart.scrollbarY && !chart.scrollbarY.isDisposed()) {
            chart.scrollbarY.disposeChildren();
            chart.scrollbarY.dispose();
        }
        while (chart.series.length > 0) {
            const seriesToDispose = chart.series.removeIndex(0);
            while (seriesToDispose.bullets.length > 0) {
                const bulletToDispose = seriesToDispose.bullets.removeIndex(0);
                bulletToDispose.events.dispose();
                bulletToDispose.dispose();
            }
            seriesToDispose.disposeData();
            seriesToDispose.disposeChildren();
            seriesToDispose.dispose();
        }
        if (chart.data) {
            chart.disposeData();
        }
        const dateAxis = chart.xAxes.getIndex(0) as am4charts.DateAxis;
        while (dateAxis.axisRanges.length > 1) {
            const axisRangeToDispose = dateAxis.axisRanges.removeIndex(1);
            if (axisRangeToDispose.bullet) {
                axisRangeToDispose.bullet.events.dispose();
                axisRangeToDispose.bullet.dispose();
            }
            axisRangeToDispose.dispose();
        }
        chart.colors.reset();
    }

    disposeEvent(disposer: am4core.IDisposer) {
        if (disposer && !disposer.isDisposed()) {
            disposer.dispose();
        }
    }

    canView(parameterType: ParameterTypeEnum, permissions: TenantPermissions) {
        switch (parameterType) {
            case ParameterTypeEnum.Pm1:
                return permissions.canViewPm1_0;
            case ParameterTypeEnum.Pm2_5:
                return permissions.canViewPm2_5;
            case ParameterTypeEnum.Pm10:
                return permissions.canViewPm10_0;
            case ParameterTypeEnum.RespirablePm:
                return permissions.canViewRespirablePm;
            case ParameterTypeEnum.Temperature:
            case ParameterTypeEnum.Humidity:
                return permissions.canViewEnvironmentalData;
            case ParameterTypeEnum.Lavg:
                return true; // TODO: tenant permission
            case ParameterTypeEnum.Pm1_A:
                return permissions.canViewPm1_0_A;
            case ParameterTypeEnum.Pm2_5_A:
                return permissions.canViewPm2_5_A;
            case ParameterTypeEnum.Pm10_A:
                return permissions.canViewPm10_0_A;
            case ParameterTypeEnum.RespirablePm_A:
                return permissions.canViewRespirablePm_A;
            case ParameterTypeEnum.BinMass:
                return permissions.canViewBinMass;
            case ParameterTypeEnum.Ozone:
            case ParameterTypeEnum.SO2:
            case ParameterTypeEnum.CO:
            case ParameterTypeEnum.NO2:
                return permissions.allowAirnowDevices;
            case ParameterTypeEnum.TVOC:
                return permissions.canViewTVOC;
            case ParameterTypeEnum.CO2:
                return permissions.canViewCO2;
            default:
                return false;
        }
    }

    getValueAxisTitle(selectedParams: number[], preferences: UserPreferences) {
        if (!this.validateParams(selectedParams)) {
            return 'Value';
        }
        const unit = this.getParameterUnit(selectedParams[0], preferences);
        switch (selectedParams[0]) {
            case ParameterTypeEnum.Humidity:
                return `Humidity [[${unit}]]`;
            case ParameterTypeEnum.Temperature:
                return `Temperature [[${unit}]]`;
            case ParameterTypeEnum.Lavg:
                return `Average Sound Level [[${unit}]]`;
            case ParameterTypeEnum.Ozone:
                return `Ozone [[${unit}]]`;
            case ParameterTypeEnum.SO2:
                return `SO2 [[${unit}]]`;
            case ParameterTypeEnum.NO2:
                return `NO2 [[${unit}]]`;
            case ParameterTypeEnum.CO:
                return `CO [[${unit}]]`;
            case ParameterTypeEnum.TVOC:
                return `TVOC [[${unit}]]`;
            case ParameterTypeEnum.CO2:
                return `CO2 [[${unit}]]`;
            default:
                return `Concentration [[${unit}]]`;
        }
    }

    getPmUnit(preferences: UserPreferences) {
        return preferences.pmUnitId === PmUnitEnum.MgPerM3
            ? 'mg/m³'
            : 'µg/m³';
    }

    getTemperatureUnit(preferences: UserPreferences) {
        return preferences.temperatureUnitId === TemperatureUnitEnum.Fahrenheit
            ? '°F'
            : '°C';
    }

    createSeries(paramId: ParameterTypeEnum, mapComponent: IMapComponent, preferences: UserPreferences) {
        const seriesName = this.getParameterName(paramId);
        const paramField = this.getParameterField(paramId);
        const series = new am4charts.LineSeries();

        series.showOnInit = false;
        series.autoDispose = true;
        series.dataFields.dateX = 'timestamp';
        series.dataFields.valueY = paramField;
        series.name = seriesName;
        series.tooltipText = '{name}: [bold]{valueY.value}';

        series.strokeWidth = 3;
        series.strokeDasharray = this.getParameterStrokeDasharray(paramId, true);

        series.minBulletDistance = 15;
        const bullet = series.bullets.push(new am4core.Circle());
        bullet.radius = 5;

        if (preferences.showMap && mapComponent) {
            bullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;
            bullet.events.on('hit', (event) => {
                const record = event.target.dataItem.dataContext as SensorRecord;
                mapComponent.updateMap([record], true);
            });
            bullet.adapter.add('fill', function (fill, target) {
                if (!target.dataItem || !target.dataItem.dataContext) {
                    return fill;
                }

                const record = target.dataItem.dataContext as SensorRecord;
                return record.lat <= 90 && (record.hdop === undefined || record.hdop < 20) && record.lat >= -90 &&
                    record.lon <= 180 && record.lon >= -180 &&
                    (record.lat !== 0 || record.lon !== 0)
                    ? fill
                    : am4core.color('#fff');
            });
        }

        return series;
    }

    createLineSeries(paramId: number, useSolidLine: boolean, name: string) {
        const paramField = this.getParameterField(paramId);
        const seriesName = `${name} - ${this.getParameterName(paramId)}`;
        const series = new am4charts.LineSeries();

        series.showOnInit = false;
        series.autoDispose = true;
        series.dataFields.dateX = 'timestamp';
        series.dataFields.valueY = paramField;
        series.name = seriesName;
        series.tooltipText = '{name}: [bold]{valueY.value}';

        series.strokeWidth = 3;
        series.strokeDasharray = this.getParameterStrokeDasharray(paramId, useSolidLine);

        series.minBulletDistance = 15;
        const bullet = series.bullets.push(new am4core.Circle());
        bullet.radius = 5;

        return series;
    }

    createDeviceValue(sensorRecords: SensorRecord[], params: number[]) {
        const deviceValue = new DeviceValue();
        params.forEach(p => {
            deviceValue.average[p] = 0;
            deviceValue.peak[p] = 0;
            const paramFied = this.getParameterField(p);
            sensorRecords.forEach(record => {
                if (deviceValue.peak[p] < record[paramFied]) {
                    deviceValue.peak[p] = record[paramFied];
                }
                deviceValue.average[p] += record[paramFied];
            });
            if (sensorRecords.length > 0) {
                deviceValue.average[p] /= sensorRecords.length;
            }
        });
        return deviceValue;
    }

    getParameterName(parameterType: ParameterTypeEnum) {
        switch (parameterType) {
            case ParameterTypeEnum.Pm1:
                return 'PM1';
            case ParameterTypeEnum.Pm2_5:
                return 'PM2.5';
            case ParameterTypeEnum.Pm10:
                return 'PM10';
            case ParameterTypeEnum.RespirablePm:
                return 'Respirable PM';
            case ParameterTypeEnum.Humidity:
                return 'Humidity';
            case ParameterTypeEnum.Temperature:
                return 'Temperature';
            case ParameterTypeEnum.Lavg:
                return 'Average Sound Level';
            case ParameterTypeEnum.Ozone:
                return 'Ozone';
            case ParameterTypeEnum.CO:
                return 'CO';
            case ParameterTypeEnum.SO2:
                return 'SO2';
            case ParameterTypeEnum.NO2:
                return 'NO2';
            case ParameterTypeEnum.TVOC:
                return 'TVOC';
            case ParameterTypeEnum.CO2:
                return 'CO2';
            case ParameterTypeEnum.Pm1_A:
                return 'PM1_A';
            case ParameterTypeEnum.Pm2_5_A:
                return 'PM2.5_A';
            case ParameterTypeEnum.Pm10_A:
                return 'PM10_A';
            case ParameterTypeEnum.RespirablePm_A:
                return 'Respirable PM_A';
            case ParameterTypeEnum.BinMass:
                return 'Bin Mass';
        }
    }

    getParameterUnit(parameterType: ParameterTypeEnum, preferences: UserPreferences) {
        switch (parameterType) {
            case ParameterTypeEnum.Pm1:
            case ParameterTypeEnum.Pm2_5:
            case ParameterTypeEnum.Pm10:
            case ParameterTypeEnum.RespirablePm:
            case ParameterTypeEnum.Pm1_A:
            case ParameterTypeEnum.Pm2_5_A:
            case ParameterTypeEnum.Pm10_A:
            case ParameterTypeEnum.RespirablePm_A:
            case ParameterTypeEnum.BinMass:
                return this.getPmUnit(preferences);
            case ParameterTypeEnum.Temperature:
                return this.getTemperatureUnit(preferences);
            case ParameterTypeEnum.Humidity:
                return '%';
            case ParameterTypeEnum.Lavg:
                return 'db';
            case ParameterTypeEnum.Ozone:
            case ParameterTypeEnum.SO2:
            case ParameterTypeEnum.NO2:
                return 'PPB';
            case ParameterTypeEnum.CO:
            case ParameterTypeEnum.TVOC:
            case ParameterTypeEnum.CO2:
                return 'PPM';
        }
    }

    getParameterField(parameterType: ParameterTypeEnum) {
        switch (parameterType) {
            case ParameterTypeEnum.Pm1:
                return 'pm1';
            case ParameterTypeEnum.Pm2_5:
                return 'pm2_5';
            case ParameterTypeEnum.Pm10:
                return 'pm10';
            case ParameterTypeEnum.RespirablePm:
                return 'respirablePm';
            case ParameterTypeEnum.Humidity:
                return 'hum';
            case ParameterTypeEnum.Temperature:
                return 'tempC';
            case ParameterTypeEnum.Lavg:
                return 'lavg';
            case ParameterTypeEnum.Ozone:
                return 'ozone';
            case ParameterTypeEnum.NO2:
                return 'nO2';
            case ParameterTypeEnum.SO2:
                return 'sO2';
            case ParameterTypeEnum.CO:
                return 'co';
            case ParameterTypeEnum.TVOC:
                return 'tvoc';
            case ParameterTypeEnum.CO2:
                return 'cO2';
            case ParameterTypeEnum.Pm1_A:
                return 'pm1_A';
            case ParameterTypeEnum.Pm2_5_A:
                return 'pm2_5_A';
            case ParameterTypeEnum.Pm10_A:
                return 'pm10_A';
            case ParameterTypeEnum.RespirablePm_A:
                return 'respirablePm_A';
            case ParameterTypeEnum.BinMass:
                return 'binMass';
        }
    }

    getParameterId(paramField: string) {
        switch (paramField) {
            case 'pm1':
                return ParameterTypeEnum.Pm1;
            case 'pm2_5':
                return ParameterTypeEnum.Pm2_5;
            case 'pm10':
                return ParameterTypeEnum.Pm10;
            case 'respirablePm':
                return ParameterTypeEnum.RespirablePm;
            case 'hum':
                return ParameterTypeEnum.Humidity;
            case 'tempC':
                return ParameterTypeEnum.Temperature;
            case 'lavg':
                return ParameterTypeEnum.Lavg;
            case 'ozone':
                return ParameterTypeEnum.Ozone;
            case 'sO2':
                return ParameterTypeEnum.SO2;
            case 'co':
                return ParameterTypeEnum.CO;
            case 'nO2':
                return ParameterTypeEnum.NO2;
            case 'tvoc':
                return ParameterTypeEnum.TVOC;
            case 'cO2':
                return ParameterTypeEnum.CO2;
            case 'pm1_A':
                return ParameterTypeEnum.Pm1_A;
            case 'pm2_5_A':
                return ParameterTypeEnum.Pm2_5_A;
            case 'pm10_A':
                return ParameterTypeEnum.Pm10_A;
            case 'respirablePm_A':
                return ParameterTypeEnum.RespirablePm_A;
            case 'binMass':
                return ParameterTypeEnum.BinMass;
        }
    }

    getParameterStrokeDasharray(parameterType: ParameterTypeEnum, useSolidLine: boolean) {
        if (useSolidLine) {
            return '';
        }
        switch (parameterType) {
            case ParameterTypeEnum.Pm1:
                return '';
            case ParameterTypeEnum.Pm2_5:
                return '2,4';
            case ParameterTypeEnum.Pm10:
                return '8,4';
            case ParameterTypeEnum.RespirablePm:
                return '8,4,2,4';
            default:
                return '';
        }
    }

    validateDateRange(fromDate: Date, toDate: Date, trackableComponent: TrackableComponent) {
        if (fromDate >= toDate) {
            const message = 'Invalid date: From Date must be less than To Date.';
            this.toastService.showWarning(message);
            trackableComponent.recordError(ErrorCodeEnum.Validation, message);
            return false;
        }
        return true;
    }

    validateParams(selectedParams: number[]) {
        let isPmSelected = false;
        let isHumSelected = false;
        let isTempSelected = false;
        let isLavgSelected = false;
        let isPPBSelected = false;
        let isPPMSelected = false;

        selectedParams.forEach(p => {
            switch (p) {
                case ParameterTypeEnum.Pm1:
                case ParameterTypeEnum.Pm2_5:
                case ParameterTypeEnum.RespirablePm:
                case ParameterTypeEnum.Pm10:
                    isPmSelected = true;
                    break;
                case ParameterTypeEnum.Humidity:
                    isHumSelected = true;
                    break;
                case ParameterTypeEnum.Temperature:
                    isTempSelected = true;
                    break;
                case ParameterTypeEnum.Lavg:
                    isLavgSelected = true;
                    break;
                case ParameterTypeEnum.Ozone:
                case ParameterTypeEnum.NO2:
                case ParameterTypeEnum.SO2:
                    isPPBSelected = true;
                    break;
                case ParameterTypeEnum.CO:
                case ParameterTypeEnum.TVOC:
                case ParameterTypeEnum.CO2:
                    isPPMSelected = true;
                    break;
            }
        });

        if ([isPmSelected, isTempSelected, isHumSelected, isLavgSelected, isPPBSelected, isPPMSelected].filter(x => x).length > 1) {
            return false;
        }
        return true;
    }

    getDefaultParameters() {
        const preferences = this.authService.getCurrentUser().preferences;
        return preferences && preferences.parameters !== undefined
            ? preferences.parameters.map(x => x)
            : [ParameterTypeEnum.Pm1, ParameterTypeEnum.Pm2_5, ParameterTypeEnum.Pm10];
    }

    getBatteryPercentage(record: RecordDto): string {
        return !record || record.batteryPercentage === undefined || record.batteryPercentage < 10
            ? 'fas fa-battery-empty'
            : record.batteryPercentage < 26
                ? 'fas fa-battery-quarter'
                : record.batteryPercentage < 51
                    ? 'fas fa-battery-half'
                    : record.batteryPercentage < 76
                        ? 'fas fa-battery-three-quarters'
                        : 'fas fa-battery-full';
    }

    getWifiIcon(record: RecordDto): string {
        return !record || record.rssi === undefined || record.rssi <= -82
            ? './assets/images/wifi-signal-0.svg'
            : record.rssi > -82 && record.rssi <= -78
                ? './assets/images/wifi-signal-1.svg'
                : record.rssi > -78 && record.rssi <= -70
                    ? './assets/images/wifi-signal-2.svg'
                    : record.rssi > -70 && record.rssi <= -65
                        ? './assets/images/wifi-signal-3.svg'
                        : record.rssi > -65 && record.rssi <= -55
                            ? './assets/images/wifi-signal-4.svg'
                            : './assets/images/wifi-signal-4.svg';
    }

    downloadFile(blob: Blob) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'export.xlsx';
        link.click();
    }

    createChartRange(chart: am4charts.XYChart) {
        const dateAxis = chart.xAxes.getIndex(0) as am4charts.DateAxis;
        const range = dateAxis.axisRanges.push(new am4charts.DateAxisDataItem());
        range.grid.stroke = am4core.color('#bababa');
        range.grid.strokeOpacity = 1;
        range.grid.strokeWidth = 2;

        return range;
    }

    createChartSlider(chart: am4charts.XYChart, mapComponent: IMapComponent) {
        const dateAxis = chart.xAxes.getIndex(0) as am4charts.DateAxis;
        const range = dateAxis.axisRanges.push(new am4charts.DateAxisDataItem());
        range.grid.stroke = am4core.color('#bababa');
        range.grid.strokeOpacity = 1;
        range.grid.strokeWidth = 2;

        const bullet = new am4core.ResizeButton();
        bullet.background.fill = am4core.color('#bababa');
        bullet.background.states.copyFrom(chart.zoomOutButton.background.states);
        bullet.minX = 0;
        bullet.verticalCenter = 'top';
        bullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        range.bullet = bullet;
        range.bullet.adapter.add('minY', (minY, target) => {
            target.maxY = chart.plotContainer.maxHeight;
            target.maxX = chart.plotContainer.maxWidth;
            return chart.plotContainer.maxHeight;
        });

        chart.cursor.behavior = 'none';
        chart.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        const cursorPosition = {
            x: null,
            y: null
        };
        chart.cursor.events.on('cursorpositionchanged', (event) => {
            cursorPosition.x = dateAxis.positionToValue(dateAxis.toAxisPosition(event.target.xPosition));
            cursorPosition.y = event.target.yPosition;
        });
        chart.plotContainer.events.on('hit', (event) => {
            const cursorValueX = cursorPosition.x;
            if (cursorValueX && cursorPosition.y >= 0) {
                range.value = cursorValueX;
                mapComponent.jumpToVideoTime(range.value);
            }
        });

        return range;
    }

    createChartSliderButtonEvents(range: am4charts.DateAxisDataItem, timestampStart: number, chart: am4charts.XYChart, disposers: am4core.IDisposer[], mapComponent: IMapComponent, setRangeValue = true) {
        disposers.forEach(this.disposeEvent);

        const dragstartDisposer = range.bullet.events.on('dragstart', event => {
            mapComponent.jumpToVideoTime(0);
        });

        const dateAxis = chart.xAxes.getIndex(0) as am4charts.DateAxis;
        const dragstopDisposer = range.bullet.events.on('dragstop', (event) => {
            range.value = dateAxis.xToValue(range.bullet.pixelX);
            mapComponent.jumpToVideoTime(range.value);
        });

        if (setRangeValue) {
            range.value = timestampStart;
        }

        return [dragstartDisposer, dragstopDisposer];
    }
}
