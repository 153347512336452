<h4 class="dialog-title text-center">Create {{data.label}}</h4>

<hr />

<app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput [(ngModel)]="customEntity.name" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-tag"></i>
                    </mat-icon>
                    <mat-error>Name is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>User</mat-label>
                    <mat-select [(ngModel)]="customEntity.userId">
                        <mat-option *ngFor="let user of users" [value]="user.id">
                            {{user.userName}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-user"></i>
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Category</mat-label>
                    <mat-select [(ngModel)]="customEntity.customObjectId" (selectionChange)="onCategoryChanged()">
                        <mat-option *ngFor="let category of categories" [value]="category.id">
                            <mat-icon *ngIf="category.icon" matPrefix>
                                <i [class]="category.icon"></i>
                            </mat-icon>
                            {{category.name}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-desktop"></i>
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12" *ngFor="let field of customFields">
                <mat-form-field *ngIf="field.type !== CustomFieldTypeEnum.SingleSelect">
                    <mat-label>{{field.name}}</mat-label>
                    <input matInput [(ngModel)]="customEntity[field.property]" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-file-alt"></i>
                    </mat-icon>
                    <mat-error>{{field.name}} is required.</mat-error>
                    <mat-icon matSuffix class="ml-3 action-icon" title="Edit" (click)="addEditField(field, $event)">
                        <i class="fas fa-edit"></i>
                    </mat-icon>
                    <mat-icon matSuffix class="ml-2 action-icon color-accent" title="Delete"
                        (click)="deleteField(field)">
                        <i class="fas fa-trash"></i>
                    </mat-icon>
                </mat-form-field>

                <mat-form-field *ngIf="field.type === CustomFieldTypeEnum.SingleSelect">
                    <mat-label>{{field.name}}</mat-label>
                    <mat-select [(ngModel)]="customEntity[field.property]" required>
                        <mat-option *ngFor="let value of field.values" [value]="value">
                            {{value}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-file-alt"></i>
                    </mat-icon>
                    <mat-error>{{field.name}} is required.</mat-error>
                    <mat-icon matSuffix class="ml-3 action-icon" title="Edit" (click)="addEditField(field, $event)">
                        <i class="fas fa-edit"></i>
                    </mat-icon>
                    <mat-icon matSuffix class="ml-2 action-icon color-accent" title="Delete"
                        (click)="deleteField(field, $event)">
                        <i class="fas fa-trash"></i>
                    </mat-icon>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="padding-y-10 clickable" (click)="addEditField(null, $event)" title="Add Field">
                    <mat-label class="mr-1">Add Field</mat-label>
                    <mat-icon matSuffix class="action-icon">
                        <i class="fas fa-plus"></i>
                    </mat-icon>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button class="btn button-primary" mat-button (click)="save()" [class.button-spinner]="isLoading"
            [disabled]="isDisabled() || isLoading">
            Create
        </button>

        <button class="btn button-accent" mat-button mat-dialog-close (click)="onClose()">Cancel</button>
    </div>
</div>
