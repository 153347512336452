import { Component } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css']
})
export class LoaderComponent {
    color = 'primary';
    mode = 'indeterminate';
    value = 50;
    isLoading: any;
    constructor(private loaderService: LoaderService) {
        this.loaderService.isLoading.subscribe(loading => {
            this.isLoading = loading;
        });
    }
}
