import { Component, OnInit, Inject } from '@angular/core';
import { ShadowConstraintService } from 'src/app/services/shadowConstraint.service';
import { ToastService } from 'src/app/services/toast.service';
import { forkJoin } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShadowConstraint } from 'src/app/models/shadowConstraint.model';
import { Enum } from 'src/app/models/enum.model';
import { ConstraintTypeEnum } from 'src/app/enums/constraintType.enum';
import { OperatorTypeEnum } from 'src/app/enums/operatorType.enum';
import { DeviceVersion } from 'src/app/models/deviceVersion.model';
import { DeviceVersionService } from 'src/app/services/deviceVersionService';

export interface EditShadowConstraintDialogData {
    id: number;
}

@Component({
    selector: 'app-edit-shadow-constraint-dialog',
    templateUrl: 'editShadowConstraintDialog.component.html',
    styleUrls: ['editShadowConstraintDialog.component.css']
})
export class EditShadowConstraintDialogComponent implements OnInit {

    isFormLoading = false;
    isLoading = false;
    deviceVersions: DeviceVersion[];
    constraintTypes: Enum[];
    operatorTypes: Enum[];
    shadowConstraint: ShadowConstraint;
    ConstraintTypeEnum = ConstraintTypeEnum;
    OperatorTypeEnum = OperatorTypeEnum;

    constructor(public dialogRef: MatDialogRef<EditShadowConstraintDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EditShadowConstraintDialogData,
        private shadowConstraintService: ShadowConstraintService,
        private deviceVersionService: DeviceVersionService,
        private toastService: ToastService) {
        this.shadowConstraint = new ShadowConstraint();
    }

    ngOnInit() {
        this.isFormLoading = true;
        this.deviceVersions = [];
        this.constraintTypes = [];
        this.operatorTypes = [];
        forkJoin({
            deviceVersions: this.deviceVersionService.getDeviceVersionLookupList(),
            constraintTypes: this.shadowConstraintService.getConstraintTypes(),
            operatorTypes: this.shadowConstraintService.getOperatorTypes(),
            shadowConstraints: this.shadowConstraintService.getShadowConstraint(this.data.id)
        }).subscribe(
            result => {
                this.deviceVersions = result.deviceVersions;
                this.constraintTypes = result.constraintTypes;
                this.operatorTypes = result.operatorTypes;
                this.shadowConstraint = result.shadowConstraints;
                this.isFormLoading = false;
            },
            error => {
                console.log(error);
                this.toastService.showError(error);
                this.isFormLoading = false;
            }
        );
    }

    save() {
        this.isLoading = true;
        this.shadowConstraintService.update(this.shadowConstraint)
            .subscribe(
                response => {
                    this.isLoading = false;
                    this.toastService.showSuccess('Shadow constraint saved successfully.');
                    this.dialogRef.close(true);
                },
                error => {
                    console.log(error);
                    this.isLoading = false;
                    this.toastService.showError(error);
                });
    }

    isDisabled() {
        return !this.shadowConstraint.deviceVersionId
            || !this.shadowConstraint.field
            || this.shadowConstraint.constraintTypeId === undefined
            || ((this.shadowConstraint.constraintTypeId !== ConstraintTypeEnum.Readonly
                && this.shadowConstraint.constraintTypeId !== ConstraintTypeEnum.SuperAdminOnly)
                && ((this.shadowConstraint.constraintTypeId !== ConstraintTypeEnum.Rename
                    && this.shadowConstraint.operatorTypeId === undefined)
                    || !this.shadowConstraint.operatorValue))
            || (this.shadowConstraint.constraintTypeId === ConstraintTypeEnum.Bounded
                && (this.shadowConstraint.operatorTypeId2 === undefined
                    || !this.shadowConstraint.operatorValue2));
    }
}
