import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MonitoringSite } from '../models/monitoringSite.model';
import { MonitoringSiteObservationDto, MonitoringSiteObservationModel } from '../models/monitoringSiteObservation.model';
import { EndpointService } from './endpoint.service';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root'
})
export class AirNowService extends EndpointService {

    constructor(http: HttpClient,
        private toastService: ToastService) {
        super(http);
    }

    getMonitoringSites(): Observable<MonitoringSite[]> {
        return this.http.get<MonitoringSite[]>(this.getApiUrl('AirNow/GetMonitoringSites'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading monitoring sites.');
                    return of([]);
                })
            );
    }

    getObservationsByMonitoringSite(payload: MonitoringSiteObservationDto) {
        return this.http.post<MonitoringSiteObservationModel[]>(this.getApiUrl('AirNow/GetObservationsByMonitoringSite'), payload);
    }
}
