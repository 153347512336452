import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChangeUserPassword } from 'src/app/models/changePassword.model';
import { ResetPasswordModel } from '../models/resetPassword.model';
import { EndpointService } from './endpoint.service';

@Injectable({
    providedIn: 'root'
})
export class AccountService extends EndpointService {

    constructor(http: HttpClient) {
        super(http);
    }

    forgotPassword(username: string) {
        return this.http.get(this.getApiUrl(`Account/ForgotPassword?username=${username}`));
    }

    resetPassword(payload: ResetPasswordModel) {
        return this.http.post(this.getApiUrl('Account/ResetPassword'), payload);
    }

    changeUserPassword(formData: ChangeUserPassword) {
        return this.http.post(this.getApiUrl('Account/ChangePassword'), formData);
    }

    enableMfa(twoFactorEnabled: boolean) {
        return this.http.get(this.getApiUrl(`Account/EnableMfa?enabled=${twoFactorEnabled}`));
    }
    
    verifyOtp(otp: string) {
        return this.http.post(this.getApiUrl('Account/VerifyOtp'), { otp });
    }
}
