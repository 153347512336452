import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { AuthService } from 'src/app/services';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { TrackableGridBaseComponent } from 'src/app/shared/grid/trackableGrid.component';
import { TimeZoneService } from 'src/app/services/timeZone.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomObjectService } from 'src/app/services/customObject.service';
import { ConfirmDialogComponent } from 'src/app/shared/confirmDialog/confirmDialog.component';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { CustomEntity } from 'src/app/models/customEntity.model';
import { CustomObjectTypeEnum } from 'src/app/enums/customObjectType.enum';
import { CreateCustomObjectDialogComponent } from '../createCustomObjectDialog/createCustomObjectDialog.component';
import { CustomEntityDialogComponent } from '../customEntityDialog/customEntityDialog.component';
import { CustomField } from 'src/app/models/customField.model';
import { EditCustomObjectDialogComponent } from '../editCustomObjectDialog/editCustomObjectDialog.component';
import { UserPreferencesService } from 'src/app/services/userPreferences.service';
import { CustomEntityService } from 'src/app/services/customEntity.service';
import { CustomObjectLookup } from 'src/app/models/customObjectLookup.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-end-point-list',
    templateUrl: './endPointList.component.html',
    styleUrls: ['./endPointList.component.css']
})
export class EndPointListComponent extends TrackableGridBaseComponent<CustomEntity> implements OnInit, AfterViewInit, OnDestroy {

    readonly DEFAULT_END_POINT_ICON = 'fas fa-desktop';

    endPoints: CustomObjectLookup[] = [];
    fields: CustomField[] = [];
    endPointName: string;
    endPointIcon: string;
    canCreateCategories = false;

    constructor(private customObjectService: CustomObjectService,
        private customEntityService: CustomEntityService,
        toastService: ToastService,
        authService: AuthService,
        timeZoneService: TimeZoneService,
        featureUsageService: FeatureUsageService,
        dialog: MatDialog,
        private userPreferencesService: UserPreferencesService,
        private router: Router
    ) {
        super(toastService, authService, timeZoneService, dialog, featureUsageService, FeatureUsagePageEnum.EndPointListPage);
        this.gridService = customEntityService;
        this.displayedColumns = [];
        const currentUser = authService.getCurrentUser();
        this.coId = currentUser.preferences?.endPointId;
        this.canCreateCategories = currentUser.tenantPermissions.canCreateCategories;
    }

    ngOnInit() {
        this.customObjectService.getTypeLookup(CustomObjectTypeEnum.EndPoint)
            .subscribe(result => {
                this.endPoints = result;
                this.setEndPointName();
                this.isLoading = false;
            });
    }

    ngAfterViewInit() {
        if (this.coId) {
            this.loadEndPointPage();
        }
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    switchEndPoint(endPointId: number) {
        this.coId = endPointId;
        this.setEndPointName();
        this.loadEndPointPage();
        this.userPreferencesService.setEndPointId(endPointId)
            .subscribe();
    }

    setEndPointName() {
        const endPoint = this.endPoints.find(x => x.id === this.coId);
        this.endPointName = endPoint?.name ?? 'Category';
        this.endPointIcon = endPoint?.icon ?? this.DEFAULT_END_POINT_ICON;
    }

    loadEndPointPage() {
        this.isLoading = true;
        this.customObjectService.getCustomObjectFields(this.coId)
            .subscribe(result => {
                this.fields = result;
                this.displayedColumns = ['name'].concat(result.map(x => x.property)).concat(['action']);
                super.ngOnInit();
                super.ngAfterViewInit();
                this.isLoading = false;
            });
    }

    loadPage() {
        this.isLoading = true;
        this.ngOnInit();
        if (this.coId) {
            this.customObjectService.getCustomObjectFields(this.coId)
                .subscribe(result => {
                    this.fields = result;
                    this.displayedColumns = ['name'].concat(result.map(x => x.property)).concat(['action']);
                    this.isLoading = false;
                    super.loadPage();
                });
        }
    }

    createEndPoint() {
        this.openDialog(CreateCustomObjectDialogComponent, {
            type: CustomObjectTypeEnum.EndPoint,
            typeName: 'Category'
        },
            (result) => {
                this.ngOnInit();
                this.coId = result;
            },
            FeatureUsageActionEnum.EndPointsCreateEndPointClick,
        );
    }

    editEndPoint() {
        if (this.coId) {
            this.openDialog(EditCustomObjectDialogComponent, {
                id: this.coId,
                typeName: 'Category'
            },
                (result) => this.ngOnInit(),
                FeatureUsageActionEnum.EndPointsEditEndPointClick);
        }
    }

    deleteEndPoint() {
        if (this.coId) {
            this.openTrackableDeleteDialog(
                ConfirmDialogComponent,
                {
                    title: 'Delete Category',
                    message: `Are you sure you want to delete category ${this.endPointName}? Deleting it will also delete all entities associated with this category.`,
                    primaryButtonText: 'Delete',
                    primaryCallback: () => this.customObjectService.delete(this.coId),
                    successMessage: 'Category deleted successfully!',
                    secondaryButtonText: 'Cancel'
                },
                () => this.onEndPointDelete(),
                FeatureUsageActionEnum.EndPointsDeleteEndPointClick,
                FeatureUsageActionEnum.DeleteCustomObjectClick,
                FeatureUsageActionEnum.CancelDeleteCustomObjectClick
            );
        }
    }

    onEndPointDelete() {
        this.coId = undefined;
        this.endPointName = 'Category';
        this.endPointIcon = this.DEFAULT_END_POINT_ICON;
        this.displayedColumns = [];
        this.fields = [];
        this.ngOnInit();
    }

    viewEndPoint(customEntity: CustomEntity) {
        this.recordAction(FeatureUsageActionEnum.UsersUserClick);
        this.router.navigate([`/end-point-view/${customEntity.id}`]);
    }

    add() {
        this.openDialog(CustomEntityDialogComponent, {
            label: this.endPointName,
            customEntity: {
                customObjectId: this.coId
            }
        },
            undefined,
            FeatureUsageActionEnum.EndPointsAddClick);
    }

    edit(customEntity: CustomEntity) {
        this.openDialog(CustomEntityDialogComponent, {
            label: this.endPointName,
            customEntity: {
                id: customEntity.id,
                customObjectId: this.coId
            }
        },
            undefined,
            FeatureUsageActionEnum.EndPointsEditClick);
    }

    delete(customEntity: CustomEntity) {
        this.openTrackableDeleteDialog(
            ConfirmDialogComponent,
            {
                title: `Delete ${this.endPointName}`,
                message: `Are you sure you want to delete ${this.endPointName.toLowerCase()} ${customEntity.name}?`,
                primaryButtonText: 'Delete',
                primaryCallback: () => this.customEntityService.delete(customEntity.id),
                successMessage: `${this.endPointName} deleted successfully!`,
                secondaryButtonText: 'Cancel'
            },
            undefined,
            FeatureUsageActionEnum.EndPointsDeleteClick,
            FeatureUsageActionEnum.DeleteCustomEntityClick,
            FeatureUsageActionEnum.CancelDeleteCustomEntityClick
        );
    }
}
