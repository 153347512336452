export class UserPreferences {
    temperatureUnitId: number;
    pmUnitId: number;
    parameters: number[];
    selectedDevices: string[];
    fromDate: Date;
    toDate: Date;
    timeIntervalId: number;
    shiftStart: Date;
    shiftEnd: Date;
    showMap: boolean;
    isViewBySensor: boolean;
    notificationPreferences: number[];
    endPointId: number;
    defaultMapLocation: string;
}
