<body class="dashboard dashboard-bg">
    <app-header></app-header>

    <div class="body-container collapsed">
        <app-sidebar></app-sidebar>

        <div class="body-content">
            <div class="row">
                <div class="col-md-8 p-0">
                    <app-page-header title="Shadow Constraints" iconClass="fas fa-pencil-ruler"></app-page-header>
                </div>

                <div class="col-md-4 p-0">
                    <div class="text-right grid-button-container">
                        <button type="button" class="btn button-primary" (click)="add()" mat-button>
                            Add Constraint
                        </button>
                    </div>
                </div>
            </div>

            <app-progress-spinner [show]="isLoading || (dataSource.isLoading$ | async)"></app-progress-spinner>

            <div class="white-box">
                <mat-form-field>
                    <mat-label>Search</mat-label>
                    <input matInput #searchField />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-search"></i>
                    </mat-icon>
                </mat-form-field>

                <table mat-table [dataSource]="dataSource" matSort matSortActive="deviceVersion" matSortDirection="asc"
                    matSortDisableClear>
                    <ng-container matColumnDef="DeviceVersion.Version">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Device Version</th>
                        <td mat-cell *matCellDef="let shadowConstraint">{{shadowConstraint.deviceVersion}}</td>
                    </ng-container>

                    <ng-container matColumnDef="field">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Field</th>
                        <td mat-cell *matCellDef="let shadowConstraint">{{shadowConstraint.field}}</td>
                    </ng-container>

                    <ng-container matColumnDef="constraintType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Constraint Type</th>
                        <td mat-cell *matCellDef="let shadowConstraint">{{shadowConstraint.constraintType}}</td>
                    </ng-container>

                    <ng-container matColumnDef="operatorType">
                        <th mat-header-cell *matHeaderCellDef>Operator</th>
                        <td mat-cell *matCellDef="let shadowConstraint">{{shadowConstraint.constraintTypeId === ConstraintTypeEnum.Readonly || shadowConstraint.constraintTypeId === ConstraintTypeEnum.SuperAdminOnly || shadowConstraint.constraintTypeId === ConstraintTypeEnum.Rename ? 'N/A' : shadowConstraint.operatorType}}</td>
                    </ng-container>

                    <ng-container matColumnDef="operatorValue">
                        <th mat-header-cell *matHeaderCellDef>Value</th>
                        <td mat-cell *matCellDef="let shadowConstraint">{{shadowConstraint.constraintTypeId === ConstraintTypeEnum.Readonly || shadowConstraint.constraintTypeId === ConstraintTypeEnum.SuperAdminOnly ? 'N/A' : shadowConstraint.operatorValue}}</td>
                    </ng-container>

                    <ng-container matColumnDef="operatorType2">
                        <th mat-header-cell *matHeaderCellDef>Operator 2</th>
                        <td mat-cell *matCellDef="let shadowConstraint">{{shadowConstraint.constraintTypeId !== ConstraintTypeEnum.Bounded ? 'N/A' : shadowConstraint.operatorType2}}</td>
                    </ng-container>

                    <ng-container matColumnDef="operatorValue2">
                        <th mat-header-cell *matHeaderCellDef>Value 2</th>
                        <td mat-cell *matCellDef="let shadowConstraint">{{shadowConstraint.constraintTypeId !== ConstraintTypeEnum.Bounded ? 'N/A' : shadowConstraint.operatorValue2}}</td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th class="action-column" mat-header-cell *matHeaderCellDef>Action</th>
                        <td class="action-column" mat-cell *matCellDef="let shadowConstraint">
                            <mat-icon class="action-icon" title="Edit" (click)="edit(shadowConstraint)">
                                <i class="fas fa-edit"></i>
                            </mat-icon>

                            <mat-icon class="action-icon color-accent" title="Delete"
                                (click)="delete(shadowConstraint)">
                                <i class="fas fa-trash"></i>
                            </mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; let even = even; columns: displayedColumns;"
                        [class.even-row]="even"></tr>
                </table>

                <mat-paginator [length]="dataSource.count$ | async" [pageSizeOptions]="[5, 10, 20]" [pageSize]="10"
                    showFirstLastButtons></mat-paginator>
            </div>

            <div class="row">
                <div class="col-lg-3 offset-lg-9">
                    <mat-form-field>
                        <mat-label>Time Zone</mat-label>
                        <input matInput [(ngModel)]="timeZoneLabel" readonly />
                        <mat-icon matPrefix class="mr-3">
                            <i class="fas fa-globe"></i>
                        </mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div class="clearfix"></div>
            <app-footer></app-footer>
        </div>

        <div class="clearfix"></div>
    </div>
</body>
