<div class="login-bg d-flex justify-content-center align-items-center">
    <div class="login-area">
        <app-logo></app-logo>

        <app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

        <div class="row">
            <div class="col text-center">
                <h3> FORGOT PASSWORD </h3>
                <hr />
            </div>
        </div>

        <div class="row">
            <div class="col text-center">
                <p>Enter username to reset your password.</p>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <mat-form-field>
                    <mat-label>Username</mat-label>
                    <input matInput [(ngModel)]="username" type="email" [email]="true" #email="ngModel"
                        required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-user"></i>
                    </mat-icon>
                    <mat-error *ngIf="email.errors?.required"> Username is required. </mat-error>
                    <mat-error *ngIf="email.errors?.email"> Invalid email address. </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col" align="end">
                <button mat-button class="btn button-primary" (click)="submit()" [class.button-spinner]="isLoading"
                    [disabled]="email.invalid || isLoading">Reset</button>

                <button mat-button class="btn button-accent ml-2" [routerLink]="['/cancel']">Cancel</button>
            </div>
        </div>
    </div>
</div>
