import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { SignupComponent } from './components/signup/signup.component';
import { SensorComponent } from './components/sensor/sensor.component';

import { LoginComponent } from './components/login/login.component';
import { PreSignupUserListComponent } from './components/preSignup/preSignupUserList.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HeaderComponent } from './components/header/header.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LogoComponent } from './components/logo/logo.component';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UserListComponent } from './components/aspnetuserlist/aspnetuserlist.component';
import { ForgetpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { TokenInterceptor } from 'src/app/_helpers';
import { PageHeaderComponent } from './components/pageHeader/pageHeader.component';

import { AgmCoreModule } from '@agm/core';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { CreateSensorAssignmentDialogComponent } from './components/createSensorAssignmentDialog/createSensorAssignmentDialog.component';
import { CreateUserDialogComponent } from './components/createUserDialog/createUserDialog.component';
import { ConfirmDialogComponent } from './shared/confirmDialog/confirmDialog.component';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from './services/loader.service';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { FooterComponent } from './components/footer/footer.component';
import { UpdateUserPasswordComponent } from './components/updateuserpassword/updateuserpassword.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { UpdateuserprofileComponent } from './components/updateuserprofile/updateuserprofile.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ChangeuserpasswordComponent } from './components/changeuserpassword/changeuserpassword.component';
import { EndPointViewComponent } from './components/endPointView/endPointView.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LoaderComponent } from './shared/loader/loader.component';
import { MatTableModule } from '@angular/material/table';
import { MapClustererComponent } from './shared/map/map-clusterer.component';
import { DateFormatService } from './services/dateFormat.service';
import { ToastService } from './services/toast.service';
import { ProgressSpinnerComponent } from './components/progressSpinner/progressSpinner.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { OverlayService } from './services/overlay.service';
import { TenantListComponent } from './components/tenantList/tenantList.component';
import { EditUserDialogComponent } from './components/editUserDialog/editUserDialog.component';
import { EditSensorAssignmentDialogComponent } from './components/editSensorAssignmentDialog/editSensorAssignmentDialog.component';
import { SensorListComponent } from './components/sensorList/sensorList.component';
import { EditSensorDialogComponent } from './components/editSensorDialog/editSensorDialog.component';
import { TenantSensorListComponent } from './components/tenantSensorList/tenantSensorList.component';
import { CreateTenantSensorDialogComponent } from './components/createTenantSensorDialog/createTenantSensorDialog.component';
import { EditTenantSensorDialogComponent } from './components/editTenantSensorDialog/editTenantSensorDialog.component';
import { TenantUsersDialogComponent } from './components/tenantUsersDialog/tenantUsersDialog.component';
import { TimeZoneService } from './services/timeZone.service';
import { MustMatchDirective } from './shared/validators/must-match.directive';
import { ChartService } from './services/chart.service';
import { EditTenantPermissionsDialogComponent } from './components/editTenantPermissionsDialog/editTenantPermissionsDialog.component';
import { ShadowConstraintListComponent } from './components/shadowConstraintList/shadowConstraintList.component';
import { CreateShadowConstraintDialogComponent } from './components/createShadowConstraintDialog/createShadowConstraintDialog.component';
import { EditShadowConstraintDialogComponent } from './components/editShadowConstraintDialog/editShadowConstraintDialog.component';
import { DeviceVersionListComponent } from './components/deviceVersionList/deviceVersionList.component';
import { CreateDeviceVersionDialogComponent } from './components/createDeviceVersionDialog/createDeviceVersionDialog.component';
import { EditDeviceVersionDialogComponent } from './components/editDeviceVersionDialog/editDeviceVersionDialog.component';
import { ConfigureSensorDialogComponent } from './components/configureSensorDialog/configureSensorDialog.component';
import { UploadLogoDialogComponent } from './components/uploadLogoDialog/uploadLogoDialog.component';
import { DataAnalysisPageComponent } from './components/dataAnalysis/dataAnalysisPage.component';
import { TimeRangeDialogComponent } from './components/timeRangeDialog/timeRangeDialog.component';
import { ChartHeaderComponent } from './components/chartBase/chartHeader.component';
import { AngularResizedEventModule } from 'angular-resize-event';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { NotificationSettingsListComponent } from './components/notificationSettingsList/notificationSettingsList.component';
import { CreateNotificationSettingsDialogComponent } from './components/createNotificationSettingsDialog/createNotificationSettingsDialog.component';
import { EditNotificationSettingsDialogComponent } from './components/editNotificationSettingsDialog/editNotificationSettingsDialog.component';
import { CloneNotificationSettingsDialogComponent } from './components/cloneNotificationSettingsDialog/cloneNotificationSettingsDialog.component';
import { AssignNotificationSettingsDialogComponent } from './components/assignNotificationSettingsDialog/assignNotificationSettingsDialog.component';
import { NotificationListComponent } from './components/notificationList/notificationList.component';
import { ViewNotificationDialogComponent } from './components/viewNotificationDialog/viewNotificationDialog.component';
import { EndPointListComponent } from './components/endPointList/endPointList.component';
import { CreateCustomObjectDialogComponent } from './components/createCustomObjectDialog/createCustomObjectDialog.component';
import { CustomEntityDialogComponent } from './components/customEntityDialog/customEntityDialog.component';
import { CustomFieldDialogComponent } from './components/customFieldDialog/customFieldDialog.component';
import { EditCustomObjectDialogComponent } from './components/editCustomObjectDialog/editCustomObjectDialog.component';
import { InfoWindowComponent } from './components/info-window/info-window.component';
import { CreateEndPointDialogComponent } from './components/createEndPointDialog/createEndPointDialog.component';
import { MfaDialogComponent } from './components/mfaDialog/mfaDialog.component';

@NgModule({
    declarations: [
        AppComponent,
        CreateUserDialogComponent,
        CreateSensorAssignmentDialogComponent,
        ConfirmDialogComponent,
        EditUserDialogComponent,
        EditSensorAssignmentDialogComponent,
        EditSensorDialogComponent,
        CreateTenantSensorDialogComponent,
        EditTenantSensorDialogComponent,
        TenantUsersDialogComponent,
        EditTenantPermissionsDialogComponent,
        CreateShadowConstraintDialogComponent,
        EditShadowConstraintDialogComponent,
        UploadLogoDialogComponent,
        TimeRangeDialogComponent,
        SignupComponent,
        LoginComponent,
        PreSignupUserListComponent,
        HeaderComponent,
        PageHeaderComponent,
        DashboardComponent,
        SidebarComponent,
        LogoComponent,
        UserListComponent,
        ForgetpasswordComponent,
        DashboardComponent,
        SensorListComponent,
        ConfigurationComponent,
        FooterComponent,
        SensorComponent,
        UpdateUserPasswordComponent,
        UpdateuserprofileComponent,
        ChangeuserpasswordComponent,
        EndPointViewComponent,
        LoaderComponent,
        MapClustererComponent,
        ProgressSpinnerComponent,
        TenantListComponent,
        TenantSensorListComponent,
        MustMatchDirective,
        ShadowConstraintListComponent,
        DeviceVersionListComponent,
        CreateDeviceVersionDialogComponent,
        EditDeviceVersionDialogComponent,
        ConfigureSensorDialogComponent,
        DataAnalysisPageComponent,
        ChartHeaderComponent,
        NotificationSettingsListComponent,
        CreateNotificationSettingsDialogComponent,
        EditNotificationSettingsDialogComponent,
        CloneNotificationSettingsDialogComponent,
        AssignNotificationSettingsDialogComponent,
        NotificationListComponent,
        ViewNotificationDialogComponent,
        EndPointListComponent,
        CreateCustomObjectDialogComponent,
        EditCustomObjectDialogComponent,
        CustomEntityDialogComponent,
        CustomFieldDialogComponent,
        InfoWindowComponent,
        CreateEndPointDialogComponent,
        MfaDialogComponent,
    ],
    imports: [
        MatDialogModule,
        MatSelectModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatIconModule,
        RouterModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        ToastrModule.forRoot(),
        OverlayModule,
        MatProgressSpinnerModule,
        MatButtonModule, MatFormFieldModule, MatInputModule, MatRippleModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        MatExpansionModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBWobSTKrK52JCiL0eNqonS70wNjAL1LFo'
        }),
        AgmJsMarkerClustererModule,
        MatCheckboxModule,
        MatTabsModule,
        MatListModule,
        MatSlideToggleModule,
        AngularResizedEventModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
    ],
    providers: [
        LoaderService,
        [DatePipe],
        [DecimalPipe],
        // {provide: Window, useValue: window},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        DateFormatService,
        ToastService,
        OverlayService,
        TimeZoneService,
        ChartService,
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        CreateUserDialogComponent,
        CreateSensorAssignmentDialogComponent,
        ConfirmDialogComponent,
        EditUserDialogComponent,
        EditSensorAssignmentDialogComponent,
        EditSensorDialogComponent,
        CreateTenantSensorDialogComponent,
        EditTenantSensorDialogComponent,
        TenantUsersDialogComponent,
        EditTenantPermissionsDialogComponent,
        CreateShadowConstraintDialogComponent,
        EditShadowConstraintDialogComponent,
        CreateDeviceVersionDialogComponent,
        EditDeviceVersionDialogComponent,
        ConfigureSensorDialogComponent,
        UploadLogoDialogComponent,
        TimeRangeDialogComponent,
        CreateNotificationSettingsDialogComponent,
        EditNotificationSettingsDialogComponent,
        CloneNotificationSettingsDialogComponent,
        AssignNotificationSettingsDialogComponent,
        ViewNotificationDialogComponent,
        CreateCustomObjectDialogComponent,
        CustomEntityDialogComponent,
        CustomFieldDialogComponent,
        EditCustomObjectDialogComponent,
        InfoWindowComponent,
        CreateEndPointDialogComponent,
        MfaDialogComponent,
    ]
})
export class AppModule { }
