export enum ParameterTypeEnum {
    Pm1 = 0,
    Pm2_5 = 1,
    Pm10 = 2,
    RespirablePm = 3,
    Humidity = 4,
    Temperature = 5,
    Latitude = 6,
    Longitude = 7,
    Lavg = 8,
    Ozone = 9,
    SO2 = 10,
    NO2 = 11,
    CO = 12,
    CO2 = 13,
    TVOC = 14,
    Pm1_A = 15,
    Pm2_5_A = 16,
    Pm10_A = 17,
    RespirablePm_A = 18,
    BinMass = 19
}
