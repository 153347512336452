<h4 class="dialog-title text-center">Edit Device Version</h4>

<hr />

<app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Version</mat-label>
                    <input matInput [(ngModel)]="deviceVersion.version" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-code"></i>
                    </mat-icon>
                    <mat-error>Version is required.</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button class="btn button-primary" (click)="save()" [class.button-spinner]="isLoading"
            [disabled]="!deviceVersion.version || isLoading">
            Save
        </button>

        <button mat-button class="btn button-accent" mat-dialog-close>Cancel</button>
    </div>
</div>
