import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EndpointService } from './endpoint.service';
import { EditSensorDialogData } from '../components/editSensorDialog/editSensorDialog.component';
import { IGridService } from '../shared/grid/grid.service';
import { Sensor, SensorModel } from '../models/sensor.model';
import { GridModel } from '../shared/grid/grid.model';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root'
})
export class SensorService extends EndpointService implements IGridService<SensorModel> {

    constructor(http: HttpClient,
        private toastService: ToastService) {
        super(http);
    }

    get(filter: string, orderBy: string, sort: string, page: number, pageSize: number) {
        return this.http.get<GridModel<SensorModel>>(this.getApiUrl('Sensor/GetSensors'), {
            params: this.createParams(filter, orderBy, sort, page, pageSize)
        });
    }

    updateSensor(payload: EditSensorDialogData) {
        return this.http.post(this.getApiUrl('Sensor/UpdateSensor'), payload);
    }

    getSensorLookupList() {
        return this.http.get<Sensor[]>(this.getApiUrl('Sensor/GetSensorLookupList'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading sensors.');
                    return of([]);
                })
            );
    }

    getSensorType(sensorId: string) {
        return this.http.get<string[]>(this.getApiUrl(`Sensor/GetSensorType?sensorId=${sensorId}`))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading Sensor Type.');
                    return of([]);
                })
            );
    }

    getOrCreateSensorByAirNowSiteId(siteId: string) {
        return this.http.get<Sensor>(this.getApiUrl(`Sensor/GetOrCreateSensorByAirNowSiteId?siteId=${siteId}`));
    }
}
