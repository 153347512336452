export class SensorAssignment {
    id: number;
    startDate: Date;
    endDate: Date;
    sensorId: string;
    sensorName: string;
    endPointId: number;
    endPointName: string;
    assignedBy: string;
    createdDate: Date;
}
