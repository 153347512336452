import { Injectable } from '@angular/core';
import { EndpointService } from './endpoint.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ToastService } from './toast.service';
import { of } from 'rxjs';
import { DeviceShadow } from '../models/deviceShadow.model';

@Injectable({
    providedIn: 'root'
})
export class DeviceShadowService extends EndpointService {

    constructor(http: HttpClient,
        private toastService: ToastService) {
        super(http);
    }

    getDeviceShadow(id: string) {
        return this.http.get<DeviceShadow>(this.getApiUrl(`DeviceShadow/GetDeviceShadow?id=${id}`))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading device shadow.');
                    return of(new DeviceShadow());
                })
            );
    }

    updateDeviceShadow(deviceShadow: DeviceShadow) {
        return this.http.post(this.getApiUrl('DeviceShadow/UpdateDeviceShadow'), deviceShadow);
    }
}
