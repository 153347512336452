import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { ParameterTypeEnum } from 'src/app/enums/parameterType.enum';
import { DateRange, DateRangeModel } from 'src/app/models/dateRange.model';
import { Enum } from 'src/app/models/enum.model';
import { NotificationSettings, NotificationSettingsModel } from 'src/app/models/notificationSettings.model';
import { UserLookup } from 'src/app/models/userLookup.model';
import { UserPreferences } from 'src/app/models/userPreferences.model';
import { AuthService } from 'src/app/services';
import { ChartService } from 'src/app/services/chart.service';
import { DateFormatService } from 'src/app/services/dateFormat.service';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { NotificationSettingsService } from 'src/app/services/notificationSettings.service';
import { ToastService } from 'src/app/services/toast.service';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { TimeRangeDialogComponent } from '../timeRangeDialog/timeRangeDialog.component';

@Component({
    selector: 'app-create-notification-settings-dialog',
    templateUrl: 'createNotificationSettingsDialog.component.html'
})
export class CreateNotificationSettingsDialogComponent extends TrackableComponent implements OnInit, OnDestroy {
    isFormLoading = false;
    isLoading = false;
    parameterTypes: Enum[] = [];
    averagingTypes: Enum[] = [];
    shiftTypes: Enum[] = [];
    limitTypes: Enum[] = [];
    daysOfWeek: Enum[] = [];
    admins: UserLookup[];
    preferences: UserPreferences;

    constructor(public dialogRef: MatDialogRef<CreateNotificationSettingsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: NotificationSettings,
        private notificationSettingsService: NotificationSettingsService,
        private dateFormatService: DateFormatService,
        private toastService: ToastService,
        featureUsageService: FeatureUsageService,
        private dialog: MatDialog,
        private chartService: ChartService,
        authService: AuthService
    ) {
        super(featureUsageService, FeatureUsagePageEnum.CreateNotificationSettingsDialog);
        this.preferences = authService.getCurrentUser().preferences;
    }

    ngOnInit() {
        this.isFormLoading = true;
        this.notificationSettingsService.getNotificationSettingsData()
            .subscribe(
                notificationSettingsData => {
                    this.parameterTypes = notificationSettingsData.parameterTypes;
                    this.averagingTypes = notificationSettingsData.averagingTypes;
                    this.shiftTypes = notificationSettingsData.shiftTypes;
                    this.limitTypes = notificationSettingsData.limitTypes;
                    this.daysOfWeek = notificationSettingsData.daysOfWeek;
                    this.admins = notificationSettingsData.admins;
                    this.isFormLoading = false;
                },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isFormLoading = false;
                }
            );
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    addEditInterval(shiftInterval: DateRange = null) {
        const isAdd = shiftInterval === null;
        const timeRangeDialog = this.dialog.open(TimeRangeDialogComponent, {
            data: shiftInterval || new DateRange(undefined, undefined),
            width: '450px',
            disableClose: true
        });

        timeRangeDialog.afterClosed()
            .subscribe((dateRange: DateRange) => {
                if (dateRange && dateRange.startDate && dateRange.endDate) {
                    if (isAdd) {
                        if (!this.data.shifts) {
                            this.data.shifts = [];
                        }
                        this.data.shifts.push(dateRange);
                    } else {
                        shiftInterval = dateRange;
                    }
                }
            });
    }

    deleteInterval(shiftInterval: DateRange) {
        this.data.shifts.splice(this.data.shifts.indexOf(shiftInterval), 1);
    }

    getParameterUnit(parameterType: ParameterTypeEnum) {
        return parameterType !== undefined
            ? this.chartService.getParameterUnit(parameterType, this.preferences)
            : '';
    }

    areShiftsEmpty() {
        return !this.data.shifts || this.data.shifts.length < 1;
    }

    isDisabled() {
        return !this.data || !this.data.name || this.data.parameterType === undefined || this.data.averagingType === undefined ||
            this.data.threshold === undefined || !this.data.shifts || this.data.shifts.length < 1 ||
            !this.data.workingDays || this.data.workingDays.length < 1 ||
            (this.data.averagingType !== 0 && this.data.shiftType === undefined) ||
            this.data.limit === undefined || this.data.limitType === undefined ||
            !this.data.subscribers || this.data.subscribers.length < 1;
    }

    create() {
        this.isLoading = true;

        const payload = new NotificationSettingsModel();
        payload.name = this.data.name;
        payload.parameterType = this.data.parameterType;
        payload.averagingType = this.data.averagingType;
        payload.shiftType = this.data.shiftType;
        if (this.data.shifts) {
            payload.shifts = [];
            this.data.shifts.forEach(s => {
                const dateRangeModel = new DateRangeModel();
                dateRangeModel.startDate = this.dateFormatService.formatDateTimeToString(s.startDate);
                dateRangeModel.endDate = this.dateFormatService.formatDateTimeToString(s.endDate);
                payload.shifts.push(dateRangeModel);
            });
        }
        payload.limit = this.data.limit;
        payload.limitType = this.data.limitType;
        payload.threshold = this.data.threshold;
        payload.workingDays = this.data.workingDays;
        payload.subscribers = this.data.subscribers;
        payload.isEnabled = this.data.isEnabled;

        this.notificationSettingsService.addNotificationSettings(payload)
            .subscribe(response => {
                this.isLoading = false;
                this.toastService.showSuccess('Notification settings added successfully.');
                this.dialogRef.close(true);
            }, error => {
                this.isLoading = false;
                console.log(error);
                this.toastService.showError(error);
            });

        this.recordAction(FeatureUsageActionEnum.AddNotificationSettingsClick);
    }

    onClose() {
        this.recordAction(FeatureUsageActionEnum.CancelAddNotificationSettingsClick);
    }
}
