export class MonitoringSiteObservationModel {
    latitude: number;
    longitude: number;
    utc: Date;
    parameter: string;
    unit: string;
    value: number;
    rawConcentration: number;
    aqi: number;
    category: number;
    siteName: string;
    agencyName: string;
    fullAQSCode: string;
    intlAQSCode: string;
}

export class MonitoringSiteObservationDto {
    constructor(public bbox: number[]) { }
}
