import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { AuthService } from 'src/app/services';
import { catchError, switchMap, filter, take, finalize, mapTo } from 'rxjs/operators';
import { Tokens } from '../models/tokens.model';
import { Router } from '@angular/router';
import { ToastService } from '../services/toast.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private readonly UNAUTHORIZED = 'Unauthorized';
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private authService: AuthService,
        private router: Router,
        private toastService: ToastService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authService.isLoggedIn()) {
            request = this.addToken(request, this.authService.getAccessToken());
        }

        return next.handle(request).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 401 &&
                    (!(error.error instanceof Array) || error.error.length < 1 || error.error[0].code !== 'login_failure')) {
                    return this.handle401Error(request, next);
                } else {
                    return throwError(error);
                }
            })
        );
    }

    private addToken(request: HttpRequest<any>, token: string) {
        const update = { setHeaders: { Authorization: `Bearer ${token}` } };
        if (request.url.includes('Auth/Logout')) {
            update['body'] = { 'refreshToken': this.authService.getRefreshToken() };
        } else if (request.url.includes('Auth/ImpersonateUser')) {
            update['body'] = { 'refreshToken': this.authService.getRefreshToken(), userId: request.body.userId };
        }
        return request.clone(update);
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.refreshToken().pipe(
                switchMap((tokens: Tokens) => {
                    this.isRefreshing = false;

                    if (tokens !== null) {
                        this.refreshTokenSubject.next(tokens.accessToken);
                        return next.handle(this.addToken(request, tokens.accessToken));
                    } else {
                        return this.authService.revokeTokens()
                            .pipe(
                                mapTo(null),
                                finalize(() => this.refreshTokenSubject.next(this.UNAUTHORIZED))
                            );
                    }
                }),
                finalize(() => {
                    if (!this.authService.isLoggedIn()) {
                        this.router.navigate(['/login']);
                        this.toastService.showSuccess('Logged out');
                        const cdkOverlay = $('.cdk-overlay-container');
                        if (cdkOverlay) {
                            cdkOverlay.empty();
                        }
                    }
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(accessToken => {
                    if (accessToken === this.UNAUTHORIZED) {
                        throwError(accessToken);
                    }

                    return next.handle(this.addToken(request, accessToken));
                })
            );
        }
    }
}
