export class IconList {
    static icons = [
        {
            'tags': ['location'],
            'value': 'fas fa-map-marker'
        },
        {
            'tags': ['location'],
            'value': 'fas fa-map-marker-alt'
        },
        {
            'tags': ['location'],
            'value': 'fas fa-map-pin'
        },
        {
            'tags': ['user'],
            'value': 'fas fa-user'
        },
        {
            'tags': ['building'],
            'value': 'fas fa-building'
        },
        {
            'tags': ['building'],
            'value': 'fas fa-school'
        }
    ];
}
