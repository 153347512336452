import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';

export interface ConfirmDialogData {
    title: string;
    message: string;
    primaryButtonText: string;
    primaryCallback: (val?: any) => Observable<any>;
    successMessage: string;
    secondaryButtonText: string;
    errorMessage: string;
}

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirmDialog.component.html'
})
export class ConfirmDialogComponent {

    isLoading = false;

    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
        private toastService: ToastService) {
    }

    primaryClick() {
        if (this.data.primaryCallback) {
            this.isLoading = true;
            this.data.primaryCallback()
                .subscribe(
                    result => {
                        this.toastService.showSuccess(this.data.successMessage);
                        this.isLoading = false;
                        this.dialogRef.close(result);
                    },
                    error => {
                        console.log(error);
                        const message = this.data.errorMessage || 'An error has occurred.';
                        this.toastService.displayErrorMessage(message);
                        this.isLoading = false;
                    });
        } else {
            this.dialogRef.close(true);
        }
    }
}
