import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateRange } from 'src/app/models/dateRange.model';

@Component({
    selector: 'app-time-range-dialog',
    templateUrl: 'timeRangeDialog.component.html'
})
export class TimeRangeDialogComponent {

    constructor(public dialogRef: MatDialogRef<TimeRangeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DateRange) {
    }

    isDisabled() {
        return !this.data || !this.data.startDate || !this.data.endDate;
    }

    ok() {
        this.dialogRef.close(this.data);
    }
}
