<h4 class="dialog-title text-center">Assign Notification</h4>

<hr />

<app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12" *ngIf="false">
                <mat-form-field>
                    <mat-label>Sensor Assignments ({{data.assignedSensors.length}})</mat-label>
                    <mat-select [(ngModel)]="data.assignedSensors" multiple>
                        <mat-option #allSensorsSelected (click)="toggleAllSensorsSelection()" [value]="'0'">
                            Select All
                        </mat-option>
                        <mat-option *ngFor="let sensor of sensors" [value]="sensor.id"
                            (click)="toggleOneSensorSelected()">
                            {{sensor.name}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-broadcast-tower"></i>
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12" *ngIf="data">
                <mat-form-field>
                    <mat-label>End Point Assignments ({{data.assignedEndPoints.length}})</mat-label>
                    <mat-select [(ngModel)]="data.assignedEndPoints" multiple>
                        <mat-option #allEndPointsSelected (click)="toggleAllEndPointsSelection()" [value]="0">
                            Select All
                        </mat-option>
                        <mat-option *ngFor="let endPoint of endPoints" [value]="endPoint.id" (click)="toggleOneEndPointSelected()">
                            {{endPoint.name}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-desktop"></i>
                    </mat-icon>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button class="btn button-primary" mat-button (click)="save()" [class.button-spinner]="isLoading"
            [disabled]="isLoading">
            Save
        </button>

        <button class="btn button-accent" mat-button mat-dialog-close (click)="onClose()">Cancel</button>
    </div>
</div>
