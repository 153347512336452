import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { Lookup } from 'src/app/models/lookup.model';
import { NotificationAssignmentsModel } from 'src/app/models/notificationAssignments.model';
import { NotificationSettings } from 'src/app/models/notificationSettings.model';
import { Sensor } from 'src/app/models/sensor.model';
import { CustomEntityService } from 'src/app/services/customEntity.service';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { NotificationSettingsService } from 'src/app/services/notificationSettings.service';
import { SensorService } from 'src/app/services/sensor.service';
import { ToastService } from 'src/app/services/toast.service';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';

@Component({
    selector: 'app-assign-notification-settings-dialog',
    templateUrl: 'assignNotificationSettingsDialog.component.html'
})
export class AssignNotificationSettingsDialogComponent extends TrackableComponent implements OnInit, OnDestroy {

    isFormLoading = false;
    isLoading = false;
    endPoints: Lookup[];
    // sensors: Sensor[];
    data: NotificationAssignmentsModel;

    // @ViewChild('allSensorsSelected') private allSensorsSelected: MatOption;
    @ViewChild('allEndPointsSelected') private allEndPointsSelected: MatOption;

    constructor(public dialogRef: MatDialogRef<AssignNotificationSettingsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public inData: NotificationSettings,
        private notificationSettingsService: NotificationSettingsService,
        private toastService: ToastService,
        featureUsageService: FeatureUsageService,
        // private sensorService: SensorService,
        private customEntityService: CustomEntityService
    ) {
        super(featureUsageService, FeatureUsagePageEnum.AssignNotificationSettingsDialog);
    }

    ngOnInit() {
        this.isFormLoading = true;
        forkJoin({
            // sensors: this.sensorService.getSensorLookupList(),
            endPoints: this.customEntityService.getEndPointLookupList(),
            notificationAssignments: this.notificationSettingsService.getNotificationAssignments(this.inData.id)
        }).subscribe(
            result => {
                // this.sensors = result.sensors;
                this.endPoints = result.endPoints;
                this.data = result.notificationAssignments;
                this.isFormLoading = false;
            },
            error => {
                console.log(error);
                this.toastService.showError(error);
                this.isFormLoading = false;
            }
        );
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    // toggleAllSensorsSelection() {
    //     this.data.assignedSensors = [];
    //     if (this.allSensorsSelected.selected) {
    //         this.sensors.forEach(sensor => this.data.assignedSensors.push(sensor.id));
    //         this.data.assignedSensors.push('0');
    //     }
    // }

    // toggleOneSensorSelected() {
    //     if (this.allSensorsSelected.selected) {
    //         this.allSensorsSelected.deselect();
    //         return false;
    //     }

    //     if (this.data.assignedSensors.length === this.sensors.length) {
    //         this.allSensorsSelected.select();
    //     }
    // }

    toggleAllEndPointsSelection() {
        this.data.assignedEndPoints = [];
        if (this.allEndPointsSelected.selected) {
            this.endPoints.forEach(endPoint => this.data.assignedEndPoints.push(endPoint.id));
            this.data.assignedEndPoints.push(0);
        }
    }

    toggleOneEndPointSelected() {
        if (this.allEndPointsSelected.selected) {
            this.allEndPointsSelected.deselect();
            return false;
        }

        if (this.data.assignedEndPoints.length === this.endPoints.length) {
            this.allEndPointsSelected.select();
        }
    }

    save() {
        this.isLoading = true;

        const payload = new NotificationAssignmentsModel();
        payload.id = this.data.id;
        payload.assignedSensors = this.data.assignedSensors.filter(x => x !== '0');
        payload.assignedEndPoints = this.data.assignedEndPoints.filter(x => x !== 0);

        this.notificationSettingsService.saveNotificationAssignments(payload)
            .subscribe(response => {
                this.isLoading = false;
                this.toastService.showSuccess('Notification assignments saved successfully.');
                this.dialogRef.close(true);
            }, error => {
                this.isLoading = false;
                console.log(error);
                this.toastService.showError(error);
            });

        this.recordAction(FeatureUsageActionEnum.AssignNotificationSettingsClick);
    }

    onClose() {
        this.recordAction(FeatureUsageActionEnum.CancelAssignNotificationSettingsClick);
    }
}
