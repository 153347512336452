import { Injectable } from '@angular/core';
import { Configuration } from '../models/configuration.model';
import { HttpClient } from '@angular/common/http';
import { EndpointService } from './endpoint.service';
import { IGridService } from '../shared/grid/grid.service';
import { SensorAssignment } from '../models/sensorAssignment.model';
import { GridModel } from '../shared/grid/grid.model';
import { catchError } from 'rxjs/operators';
import { ToastService } from './toast.service';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService extends EndpointService implements IGridService<SensorAssignment> {

    constructor(http: HttpClient,
        private toastService: ToastService) {
        super(http);
    }

    get(filter: string, orderBy: string, sort: string, page: number, pageSize: number) {
        return this.http.get<GridModel<SensorAssignment>>(this.getApiUrl('Configuration/GetSensorAssignments'), {
            params: this.createParams(filter, orderBy, sort, page, pageSize)
        });
    }

    getSensorAssignment(id: number) {
        return this.http.get<SensorAssignment>(this.getApiUrl(`Configuration/GetSensorAssignment?id=${id}`))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading sensor assignment.');
                    return of(new SensorAssignment());
                })
            );
    }

    addSensorAssignment(payload: Configuration) {
        return this.http.post(this.getApiUrl('Configuration/AddSensorAssignment'), payload);
    }

    updateSensorAssignment(payload: Configuration) {
        return this.http.post(this.getApiUrl('Configuration/UpdateSensorAssignment'), payload);
    }

    deleteSensorAssignment(id) {
        return this.http.delete(this.getApiUrl(`Configuration/DeleteSensorAssignment?id=${id}`));
    }
}
