import { Component, OnInit, ViewChild, NgZone, AfterViewInit, OnDestroy } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_kelly from '@amcharts/amcharts4/themes/kelly';
import { SensorService } from '../../services/sensor.service';
import { MapClustererComponent } from '../../shared/map/map-clusterer.component';
import { DateFormatService } from '../../services/dateFormat.service';
import { ToastService } from '../../services/toast.service';
import { SensorRecordsRequestModel } from '../../models/sensorRecordsRequest.model';
import { SensorRecordService } from 'src/app/services/sensorRecord.service';
import { forkJoin, Subscription } from 'rxjs';
import { Sensor } from 'src/app/models/sensor.model';
import { SensorRecord } from 'src/app/models/sensorRecord.model';
import { ChartService } from 'src/app/services/chart.service';
import { AuthService } from 'src/app/services';
import { TenantPermissions } from 'src/app/models/tenantPermissions.model';
import { TimeZoneService } from 'src/app/services/timeZone.service';
import { UserPreferences } from 'src/app/models/userPreferences.model';
import { TemperatureUnitEnum } from 'src/app/enums/temperatureUnit.enum';
import { PmUnitEnum } from 'src/app/enums/pmUnit.enum';
import { Enum } from 'src/app/models/enum.model';
import { RecordDto } from 'src/app/models/recordDto.model';
import { DataAnalysisExportModel } from 'src/app/models/dataAnalysisExport.model';
import { DataAnalysisRequestModel } from 'src/app/models/dataAnalysisRequest.model';
import { TimeIntervalEnum } from 'src/app/enums/timeInterval.enum';
import { DeviceValue } from 'src/app/models/deviceValue.model';
import { OnZoom } from '../chartBase/onZoom.interface';
import { ResizedEvent } from 'angular-resize-event';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { IMapComponent } from '../chartBase/mapComponent.interface';
import { EventService } from 'src/app/services/event.service';
import { SensorRecordChartModel } from 'src/app/models/sensorRecordChart.model';
import { VideoResource } from 'src/app/models/videoResource.model';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { ParameterTypeEnum } from 'src/app/enums/parameterType.enum';
import { ActivatedRoute } from '@angular/router';

am4core.useTheme(am4themes_kelly);
am4core.options.animationsEnabled = false;
am4core.options.minPolylineStep = 10;

@Component({
    selector: 'app-sensor',
    templateUrl: './sensor.component.html',
    styleUrls: ['./sensor.component.css']
})
export class SensorComponent extends TrackableComponent implements OnInit, AfterViewInit, OnDestroy, OnZoom, IMapComponent {

    readonly CHART_DIV_ID = 'sensor-chart';
    readonly CHART_VIDEO_DIV_ID = 'sensor-video-chart';

    sensorId: string;
    sensorName: string;
    sensors: Sensor[];
    sensorRecord: RecordDto;
    isLoading = false;
    isMapLoading = false;
    fromDate: Date;
    toDate: Date;
    tenantPermissions: TenantPermissions;
    preferences: UserPreferences;
    timeZone: string;
    timeZoneLabel: string;
    chart: am4charts.XYChart;
    lastOnline: string;
    parameters: Enum[];
    selectedParams: number[];
    agmMapStyles: any;
    pmUnit: string;
    temperatureUnit: string;
    batteryPercentageClass: string;
    icon: string;
    deviceValue: DeviceValue;
    columnParameters: number[];
    disposer: am4core.IDisposer;
    initDisposer: am4core.IDisposer;
    analyticsDisposer: am4core.IDisposer;
    shouldRecordAnalyticsZoom = false;
    analyticsVerticalDisposer: am4core.IDisposer;
    shouldRecordVerticalZoom = false;
    shouldRecordMapInteraction = false;
    subscriptions: Subscription[] = [];
    intervalId: any;
    videoChart: am4charts.XYChart;
    videoChartSlider: am4charts.DateAxisDataItem;
    chartSlider: am4charts.DateAxisDataItem;
    vgApi: VgApiService;
    videoResources: VideoResource[];
    videoResource: VideoResource;
    shouldCreateSliderButtonEvents = false;
    videoDisposers: am4core.IDisposer[] = [];
    isVideoChartHidden = false;
    resumeVideoPlay = false;
    skipTrackingAction = false;
    skipRangeTimeUpdate = false;
    sensorType: string[];
    isNotification = false;
    isEmailLink = false;

    @ViewChild(MapClustererComponent) mapClustererComponent: MapClustererComponent;

    constructor(private sensorRecordService: SensorRecordService,
        private sensorService: SensorService,
        private timeZoneService: TimeZoneService,
        private toastService: ToastService,
        private dateFormatService: DateFormatService,
        private chartService: ChartService,
        private zone: NgZone,
        private eventService: EventService,
        private route: ActivatedRoute,
        private authService: AuthService,
        featureUsageService: FeatureUsageService) {

        super(featureUsageService, FeatureUsagePageEnum.DevicePage);

        const currentUser = this.authService.getCurrentUser();
        this.tenantPermissions = currentUser.tenantPermissions;
        this.timeZone = currentUser.userTimeZone;
        this.timeZoneLabel = this.timeZoneService.createTimeZoneLabel(this.timeZone);
        this.preferences = currentUser.preferences;
        this.lastOnline = 'Never';
        this.agmMapStyles = [{
            featureType: 'poi',
            stylers: [
                { visibility: 'off' }
            ]
        }];
        this.sensorRecord = new RecordDto();
        this.pmUnit = this.chartService.getPmUnit(this.preferences);
        this.temperatureUnit = this.chartService.getTemperatureUnit(this.preferences);
        this.batteryPercentageClass = this.chartService.getBatteryPercentage(null);
        this.icon = this.chartService.getWifiIcon(null);
        this.deviceValue = new DeviceValue();
        this.subscriptions.push(this.eventService.on('markerClick')
            .subscribe((action: FeatureUsageActionEnum) => this.recordAction(action)));
    }

    ngOnInit() {
        this.isNotification = this.route.snapshot.queryParams.notification === 'true';
        this.isEmailLink = this.route.snapshot.queryParams.id !== undefined;
        if (this.isEmailLink) {
            const encSensorId = window.btoa(this.route.snapshot.queryParams.id);
            localStorage.setItem('deviceName', encSensorId);
            const parameterType = parseInt(this.route.snapshot.queryParams.parameter, 10);
            this.fromDate = this.timeZoneService.convertTimestampToLocalDate(parseInt(this.route.snapshot.queryParams.start, 10) * 1000, this.timeZone);
            this.toDate = this.timeZoneService.convertTimestampToLocalDate(parseInt(this.route.snapshot.queryParams.end, 10) * 1000, this.timeZone);
            this.authService.setCurrentUserNotificationDetails(parameterType, this.fromDate, this.toDate);
            this.recordAction(FeatureUsageActionEnum.EmailNotificationLinkClick, null);
        }
        this.loadPage();
    }

    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            this.chart = this.chartService.createChart(this.CHART_DIV_ID, this);
            this.chartSlider = this.chartService.createChartRange(this.chart);
            this.videoChart = this.chartService.createChart(this.CHART_VIDEO_DIV_ID, this);
            this.videoChartSlider = this.chartService.createChartSlider(this.videoChart, this);
        });
    }

    ngOnDestroy() {
        clearInterval(this.intervalId);

        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });

        this.zone.runOutsideAngular(() => {
            this.chartService.disposeEvent(this.disposer);
            this.chartService.disposeEvent(this.initDisposer);
            this.chartService.disposeEvent(this.analyticsDisposer);
            this.chartService.disposeEvent(this.analyticsVerticalDisposer);
            if (this.chart) {
                this.chartService.disposeChartData(this.chart);
                this.chart.dispose();
            }
            if (this.videoChart) {
                this.chartService.disposeChartData(this.videoChart);
                this.videoChart.dispose();
                this.videoChartSlider = null;
                this.chartSlider = null;
            }
        });

        this.recordActivity();
    }

    switchSensor(sensorId) {
        const encSensorId = window.btoa(sensorId);
        localStorage.setItem('deviceName', encSensorId);
        this.recordAction(FeatureUsageActionEnum.DevicePageSensorClick);
        this.loadPage();
    }

    loadPage() {
        //clearInterval(this.intervalId);
        const encDeviceName = localStorage.getItem('deviceName');
        if (encDeviceName != null) {
            this.sensorId = window.atob(encDeviceName);
        }

        this.isLoading = true;
        this.sensorService.getSensorType(this.sensorId)
            .subscribe(sensorType => {
                this.sensorType = sensorType;
                if (!sensorType) {
                    this.isLoading = false;
                    return;
                }
                this.parameters = this.chartService.getSensorParameters(sensorType);
                const defaultParams = this.chartService.getDefaultParameters();
                this.selectedParams = defaultParams.filter(x => this.parameters.some(p => p.id === x));
                const payload = new SensorRecordsRequestModel();
                payload.sensorId = this.sensorId;
                payload.isViewByEndPoint = false;
                let option = '8h';
                if (this.isNotification && this.preferences.fromDate && this.preferences.toDate) {
                    option = undefined;
                    this.isNotification = false;
                    this.fromDate = this.preferences.fromDate;
                    this.toDate = this.preferences.toDate;
                } else if (this.isEmailLink) {
                    option = undefined;
                    this.isEmailLink = false;
                } else {
                    const dateRange = this.timeZoneService.createDateRange(option, this.timeZone);
                    this.fromDate = dateRange.startDate;
                    this.toDate = dateRange.endDate;
                }
                payload.startDate = this.dateFormatService.formatDateTimeToString(this.fromDate);
                payload.endDate = this.dateFormatService.formatDateTimeToString(this.toDate);
                payload.parameters = this.selectedParams;

                forkJoin({
                    sensorRecords: this.sensorRecordService.getSensorRecords(payload),
                    sensors: this.sensorService.getSensorLookupList()
                }).subscribe(
                    result => {
                        this.sensorName = result.sensors.find(x => x.id === this.sensorId)?.name ?? 'N/A';
                        this.sensors = result.sensors;
                        this.createChartZoomEvent();
                        this.drawMaps(result.sensorRecords);
                        this.updateActiveZoom(option);
                        // if (option !== undefined) {
                        //     this.setRefreshInterval(option, this.selectedParams);
                        // }
                        this.isLoading = false;
                    },
                    error => {
                        console.log(error);
                        this.toastService.showError(error);
                        this.isLoading = false;
                    }
                );

                this.sensorRecordService.getLatestSensorRecordBySensor(this.sensorId)
                    .subscribe(
                        sensorRecord => {
                            this.sensorRecord = sensorRecord || new RecordDto();
                            this.batteryPercentageClass = this.chartService.getBatteryPercentage(this.sensorRecord);
                            this.icon = this.chartService.getWifiIcon(this.sensorRecord);
                        },
                        error => {
                            console.log(error);
                            this.toastService.showError(error);
                        }
                    );
            }, error => {
                console.log(error);
                this.toastService.showError(error);
                this.isLoading = false;
            });
    }

    setRefreshInterval(option: string, inParams: number[]) {
        clearInterval(this.intervalId);
        const params = inParams.map(x => x);
        this.intervalId = setInterval(() => {
            this.refreshData(option, params);
        }, 60000);
    }

    refreshData(option: string, params: number[]) {
        this.isMapLoading = true;
        const payload = new SensorRecordsRequestModel();
        payload.sensorId = this.sensorId;
        payload.isViewByEndPoint = false;
        const dateRange = this.timeZoneService.createDateRange(option, this.timeZone);
        payload.startDate = this.dateFormatService.formatDateTimeToString(option !== 'all' ? dateRange.startDate : undefined);
        payload.endDate = this.dateFormatService.formatDateTimeToString(option !== 'all' ? dateRange.endDate : undefined);
        payload.parameters = params;

        forkJoin({
            sensorRecords: this.sensorRecordService.getSensorRecords(payload),
            sensorRecord: this.sensorRecordService.getLatestSensorRecordBySensor(this.sensorId)
        }).subscribe(
            result => {
                this.sensorRecord = result.sensorRecord || new RecordDto();
                this.batteryPercentageClass = this.chartService.getBatteryPercentage(this.sensorRecord);
                this.icon = this.chartService.getWifiIcon(this.sensorRecord);
                this.drawMaps(result.sensorRecords);
                this.isMapLoading = false;
            },
            error => {
                console.log(error);
                this.toastService.showError(error);
                this.isMapLoading = false;
            }
        );
    }

    createChartZoomEvent() {
        this.chartService.disposeEvent(this.disposer);
        this.disposer = this.zone.runOutsideAngular(() => {
            return this.chartService.createChartZoomEvent(this.chart, this);
        });
        this.chartService.disposeEvent(this.analyticsDisposer);
        this.analyticsDisposer = this.zone.runOutsideAngular(() => {
            return this.chartService.createChartAnalyticsEvent(this.chart, this);
        });
        this.chartService.disposeEvent(this.analyticsVerticalDisposer);
        this.analyticsVerticalDisposer = this.zone.runOutsideAngular(() => {
            return this.chartService.createChartAnalyticsVerticalZoomEvent(this.chart, this);
        });
    }

    onInitialZoom(sensorRecords: SensorRecord[]) {
        if (this.initDisposer && !this.initDisposer.isDisposed() && sensorRecords && sensorRecords.length > 0) {
            this.onDataZoom(sensorRecords);
        }
    }

    onZoom(sensorRecords: SensorRecord[]) {
        if (sensorRecords && sensorRecords.length > 0 && this.initDisposer && !this.initDisposer.isDisposed()) {
            this.initDisposer.dispose();
        }
        this.onDataZoom(sensorRecords);
    }

    onDataZoom(sensorRecords: SensorRecord[]) {
        this.zone.run(() => {
            this.deviceValue = this.chartService.createDeviceValue(sensorRecords, this.columnParameters);
        });

        this.updateMap(sensorRecords);

        const dateAxis = this.chart.xAxes.getIndex(0) as am4charts.DateAxis;
        if (this.videoResources && this.videoResources.length > 0 && dateAxis.minZoomed && dateAxis.maxZoomed) {
            const videoDateAxis = this.videoChart.xAxes.getIndex(0) as am4charts.DateAxis;
            videoDateAxis.min = dateAxis.minZoomed;
            videoDateAxis.max = dateAxis.maxZoomed;
        }
    }

    onAnalyticsHorizontalZoom() {
        if (!this.shouldRecordAnalyticsZoom) {
            this.shouldRecordAnalyticsZoom = true;
            return;
        }
        this.recordAction(FeatureUsageActionEnum.ChartHorizontalZoom);
    }

    onAnalyticsVerticalZoom() {
        if (!this.shouldRecordVerticalZoom) {
            this.shouldRecordVerticalZoom = true;
            return;
        }
        this.recordAction(FeatureUsageActionEnum.ChartVerticalZoom);
    }

    isDisabled() {
        return !this.fromDate || !this.toDate
            || !this.selectedParams || this.selectedParams.length < 1;
    }

    zoom(option: string) {
        if (option === 'all') {
            this.fromDate = undefined;
            this.toDate = undefined;
        } else {
            const dateRange = this.timeZoneService.createDateRange(option, this.timeZone);
            if (dateRange === null) {
                return;
            }

            this.fromDate = dateRange.startDate;
            this.toDate = dateRange.endDate;
        }

        this.search(option);

        switch (option) {
            case '8h':
                this.recordAction(FeatureUsageActionEnum.DevicePage8hClick);
                break;
            case '3d':
                this.recordAction(FeatureUsageActionEnum.DevicePage3dClick);
                break;
            case '1m':
                this.recordAction(FeatureUsageActionEnum.DevicePage1mClick);
                break;
            case 'all':
                this.recordAction(FeatureUsageActionEnum.DevicePageAllClick);
                break;
        }
    }

    search(option: string = null) {
        //clearInterval(this.intervalId);
        if (option !== 'all' && this.isDisabled() ||
            option === 'all' && (!this.selectedParams || this.selectedParams.length < 1) ||
            !this.chartService.validateDateRange(this.fromDate, this.toDate, this)) {
            return;
        }

        this.updateActiveZoom(option);

        this.isMapLoading = true;
        const payload = new SensorRecordsRequestModel();
        payload.sensorId = this.sensorId;
        payload.startDate = this.dateFormatService.formatDateTimeToString(this.fromDate);
        payload.endDate = this.dateFormatService.formatDateTimeToString(this.toDate);
        payload.parameters = this.selectedParams;
        payload.isViewByEndPoint = false;
        this.getSensorRecords(payload);

        if (!option) {
            this.recordAction(FeatureUsageActionEnum.DevicePagePlotClick);
        } else {
            //this.setRefreshInterval(option, this.selectedParams);
        }
    }

    updateActiveZoom(option: string) {
        const zoomButtons = document.getElementById('zoom-buttons');
        const activeButtons = zoomButtons.getElementsByClassName('active');
        if (activeButtons.length > 0) {
            const activeButton = activeButtons[0];
            activeButton.classList.remove('active', 'button-accent');
            activeButton.classList.add('button-primary');
        }

        if (!option) {
            return;
        }
        const newActiveButton = zoomButtons.getElementsByClassName('zoom-' + option)[0];
        newActiveButton.classList.remove('button-primary');
        newActiveButton.classList.add('active', 'button-accent');
    }

    getSensorRecords(payload: SensorRecordsRequestModel) {
        this.sensorRecordService.getSensorRecords(payload)
            .subscribe(
                sensorRecords => {
                    this.drawMaps(sensorRecords);
                    this.isLoading = false;
                    this.isMapLoading = false;
                },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isMapLoading = false;
                    this.isLoading = false;
                });
    }

    drawMaps(sensorRecordChartModel: SensorRecordChartModel) {
        const sensorRecords = sensorRecordChartModel.sensorRecords;
        const videoResources = sensorRecordChartModel.videoResources;
        const convertToMgPerM3 = this.preferences.pmUnitId === PmUnitEnum.MgPerM3;
        const convertToFahrenheit = this.preferences.temperatureUnitId === TemperatureUnitEnum.Fahrenheit;

        this.columnParameters = JSON.parse(JSON.stringify(this.selectedParams));

        sensorRecords.forEach(
            record => {
                record.timestamp *= 1000;
                if (convertToMgPerM3) {
                    record.pm1 = record.pm1 * 0.001;
                    record.pm2_5 = record.pm2_5 * 0.001;
                    record.pm10 = record.pm10 * 0.001;
                    record.respirablePm = record.respirablePm * 0.001;
                }
                if (convertToFahrenheit) {
                    record.tempC = record.tempC * 1.8 + 32;
                }
            });
        videoResources.forEach(
            resource => {
                resource.timestampStart *= 1000;
                resource.timestampEnd *= 1000;
            });

        this.chartService.disposeEvent(this.initDisposer);
        this.initDisposer = this.zone.runOutsideAngular(() => {
            return this.chartService.createChartInitialZoomEvent(this.chart, this, false);
        });
        this.shouldRecordAnalyticsZoom = false;
        this.shouldRecordVerticalZoom = false;

        this.videoResources = videoResources;
        this.isVideoChartHidden = false;
        this.zone.runOutsideAngular(() => {
            this.chartService.drawSensorRecordChart(this.chart, sensorRecords, this.selectedParams, this);
            this.chartService.drawVideoChart(this.videoChart, videoResources);
            if (videoResources && videoResources.length > 0) {
                this.videoResource = videoResources[0];
                if (this.vgApi) {
                    this.videoDisposers = this.chartService.createChartSliderButtonEvents(this.videoChartSlider, this.videoResource.timestampStart, this.videoChart, this.videoDisposers, this);
                    this.vgApi.currentTime = 0;
                } else {
                    this.shouldCreateSliderButtonEvents = true;
                }
            } else {
                this.videoResource = null;
            }
        });
        if (!videoResources || videoResources.length === 0) {
            this.isVideoChartHidden = true;
        }
    }

    updateMap(sensorRecords: SensorRecord[], shouldRecordBulletClick: boolean = false) {
        if (!this.preferences.showMap) {
            return;
        }
        this.shouldRecordMapInteraction = false;
        const sensors: Sensor[] = [{ id: this.sensorId, name: this.sensorName }];
        this.mapClustererComponent.updateMarkerClusterer(sensorRecords, sensors, FeatureUsagePageEnum.DevicePage);
        if (shouldRecordBulletClick) {
            this.recordAction(FeatureUsageActionEnum.ChartBulletClick);
        }
    }

    export() {
        if (!this.fromDate || !this.toDate ||
            !this.chartService.validateDateRange(this.fromDate, this.toDate, this)) {
            return;
        }

        this.isMapLoading = true;
        const payload = new DataAnalysisExportModel();
        const model = new DataAnalysisRequestModel();
        model.isViewByEndPoint = false;
        model.sensorId = this.sensorId;
        model.name = this.sensorName;
        model.startDate = this.dateFormatService.formatDateTimeToString(this.fromDate);
        model.endDate = this.dateFormatService.formatDateTimeToString(this.toDate);
        model.parameters = this.selectedParams;
        model.timeIntervalId = TimeIntervalEnum.None;
        model.selectedDevices = [`1+${this.sensorId}`];
        model.includeGps = true;
        payload.models = [model];

        this.sensorRecordService.exportParameters(payload)
            .subscribe(
                blob => {
                    this.chartService.downloadFile(blob);
                    this.isMapLoading = false;
                },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isMapLoading = false;
                });

        this.recordAction(FeatureUsageActionEnum.DevicePageExportClick);
    }

    getParamName(paramId: number) {
        return this.chartService.getParameterName(paramId);
    }

    getParamUnit() {
        return this.columnParameters && this.columnParameters.length > 0
            ? this.chartService.getParameterUnit(this.columnParameters[0], this.preferences)
            : '';
    }

    getValue(id: number, paramId: number) {
        return id === 0
            ? this.deviceValue.average[paramId]
            : this.deviceValue.peak[paramId];
    }

    onResized(event: ResizedEvent) {
        this.chartService.onResized(this.chart, event, this.CHART_DIV_ID);
    }

    onMapIdle() {
        if (!this.shouldRecordMapInteraction) {
            this.shouldRecordMapInteraction = true;
            return;
        }
        this.recordAction(FeatureUsageActionEnum.MapInteraction);
    }

    onDateFilterClick() {
        this.recordAction(FeatureUsageActionEnum.DateFilterClick);
    }

    onVideoResized(event: ResizedEvent) {
        this.chartService.onResized(this.videoChart, event, this.CHART_VIDEO_DIV_ID);
    }

    onPlayerReady(api: VgApiService) {
        this.vgApi = api;

        if (this.shouldCreateSliderButtonEvents) {
            this.zone.runOutsideAngular(() => {
                this.videoDisposers = this.chartService.createChartSliderButtonEvents(this.videoChartSlider, this.videoResource.timestampStart, this.videoChart, this.videoDisposers, this);
            });
        }

        this.subscriptions.push(api.subscriptions.timeUpdate.subscribe(event => {
            if (!this.videoResource || !this.videoResource.timestampStart) {
                return;
            }
            let currentTime = this.videoResource.timestampStart + event.srcElement.currentTime * 1000;
            currentTime = Math.max(this.videoResource.timestampStart, currentTime);
            currentTime = Math.min(this.videoResource.timestampEnd, currentTime);
            if (!this.skipRangeTimeUpdate) {
                this.videoChartSlider.value = currentTime;
                this.chartSlider.value = currentTime;
            }
            const videoDateAxis = this.videoChart.xAxes.getIndex(0) as am4charts.DateAxis;
            if (videoDateAxis.min && videoDateAxis.min > currentTime) {
                videoDateAxis.min = currentTime - 5000;
            }
            if (videoDateAxis.max && videoDateAxis.max < currentTime) {
                videoDateAxis.max = currentTime + 5000;
            }
        }));

        this.subscriptions.push(api.getDefaultMedia().subscriptions.ended.subscribe(
            this.nextVideo.bind(this)
        ));

        this.subscriptions.push(api.getDefaultMedia().subscriptions.play.subscribe(
            this.onVgApiAction.bind(this, FeatureUsageActionEnum.PlayVideoClick)
        ));

        this.subscriptions.push(api.getDefaultMedia().subscriptions.pause.subscribe(
            this.onVgApiAction.bind(this, FeatureUsageActionEnum.PauseVideoClick)
        ));
    }

    onVgApiAction(action) {
        if (this.skipTrackingAction) {
            return;
        }
        this.resumeVideoPlay = action === FeatureUsageActionEnum.PlayVideoClick;
        this.recordAction(action);
    }

    nextVideo() {
        const index = this.videoResources.indexOf(this.videoResource);

        if (index >= 0 && index < this.videoResources.length - 1) {
            this.videoResource = this.videoResources[index + 1];
            this.videoDisposers = this.chartService.createChartSliderButtonEvents(this.videoChartSlider, this.videoResource.timestampStart, this.videoChart, this.videoDisposers, this);
            setTimeout(() => this.playVideo(), 200);
        }
    }

    playVideo() {
        this.skipTrackingAction = true;
        this.vgApi.play();
        this.resumeVideoPlay = true;
        setTimeout(() => this.skipTrackingAction = false, 50);
    }

    jumpToVideoTime(timestamp: number) {
        if (timestamp === 0) {
            this.skipTrackingAction = true;
            this.vgApi.pause();
            return;
        }
        this.skipTrackingAction = false;

        this.recordAction(FeatureUsageActionEnum.DragVideoSlider);

        let resourceUpdated = false;
        if (!this.videoResource.url || timestamp < this.videoResource.timestampStart || timestamp > this.videoResource.timestampEnd) {
            const resource = this.videoResources.find(r => r.timestampStart <= timestamp && r.timestampEnd >= timestamp);

            if (!resource) {
                this.videoResource = new VideoResource();
                this.chartSlider.value = timestamp;
                return;
            }

            this.skipRangeTimeUpdate = true;
            this.videoResource = resource;
            this.videoDisposers = this.chartService.createChartSliderButtonEvents(this.videoChartSlider, this.videoResource.timestampStart, this.videoChart, this.videoDisposers, this, false);
            this.videoChartSlider.value = timestamp;
            this.chartSlider.value = timestamp;
            resourceUpdated = true;
        }

        if (resourceUpdated) {
            setTimeout(() => this.setVideoTime(timestamp), 200);
        } else {
            this.setVideoTime(timestamp);
        }
    }

    setVideoTime(timestamp: number) {
        const totalTime = this.videoResource.timestampEnd - this.videoResource.timestampStart;
        let timeElapsed = timestamp - this.videoResource.timestampStart;
        timeElapsed = Math.max(0, timeElapsed);
        timeElapsed = Math.min(totalTime, timeElapsed);
        this.vgApi.currentTime = Math.min(this.vgApi.time.total, timeElapsed / 1000);
        this.skipRangeTimeUpdate = false;

        if (this.resumeVideoPlay) {
            this.playVideo();
        }
    }
}
