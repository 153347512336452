<h4 class="dialog-title text-center">Edit Tenant Permissions</h4>

<hr />

<app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

<div class="mb-3">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canViewPm1_0">
                    Can View PM1.0
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canViewPm2_5">
                    Can View PM2.5
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canViewPm10_0">
                    Can View PM10.0
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canViewRespirablePm">
                    Can View Respirable PM
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canViewPm1_0_A">
                    Can View PM1.0_A
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canViewPm2_5_A">
                    Can View PM2.5_A
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canViewPm10_0_A">
                    Can View PM10.0_A
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canViewRespirablePm_A">
                    Can View Respirable PM_A
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canViewBinMass">
                    Can View Bin Mass
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canViewEnvironmentalData">
                    Can View Environmental Data
                </mat-checkbox>
            </div>
        </div>

        <hr />

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canExportData">
                    Can Export Data
                </mat-checkbox>
            </div>
        </div>

        <div class="row" *ngIf="tenantPermissions.canExportData">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canExportCounts">
                    Can Export Counts
                </mat-checkbox>
            </div>
        </div>

        <div class="row" *ngIf="tenantPermissions.canExportData">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canExportGeolocationData">
                    Can Export Geolocation Data
                </mat-checkbox>
            </div>
        </div>

        <div class="row" *ngIf="tenantPermissions.canExportData">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canExportEnvironmentalData">
                    Can Export Environmental Data
                </mat-checkbox>
            </div>
        </div>

        <hr />

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canCreateSubusers">
                    Can Create Subusers
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canUpdateDeviceShadow">
                    Can Update Device Shadow
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canViewDataAnalysisPage">
                    Can View Data Analysis Page
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canViewNotifications">
                    Can View Notifications
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canCreateCategories">
                    Can Create Categories
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.allowAirnowDevices">
                    Allow Airnow Devices
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canViewTVOC">
                    Can View TVOC
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-checkbox [(ngModel)]="tenantPermissions.canViewCO2">
                    Can View CO2
                </mat-checkbox>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end" class="mt-4">
        <button mat-button class="btn button-primary" (click)="save()" [class.button-spinner]="isLoading"
            [disabled]="isLoading">Save</button>

        <button mat-button class="btn button-accent" mat-dialog-close>Cancel</button>
    </div>
</div>
