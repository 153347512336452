<body class="dashboard dashboard-bg">
    <app-header></app-header>

    <div class="body-container collapsed">
        <app-sidebar></app-sidebar>

        <div class="body-content">
            <div class="row">
                <div class="col p-0">
                    <app-page-header title="Sensors" iconClass="fas fa-broadcast-tower"></app-page-header>
                </div>
            </div>

            <app-progress-spinner [show]="isLoading || (dataSource.isLoading$ | async)"></app-progress-spinner>

            <div class="white-box">
                <mat-form-field>
                    <mat-label>Search</mat-label>
                    <input matInput #searchField />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-search"></i>
                    </mat-icon>
                </mat-form-field>

                <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc"
                    matSortDisableClear>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Device Name</th>
                        <td mat-cell *matCellDef="let sensor">
                            <div class="grid-link" (click)="setSensorId(sensor)">
                                {{sensor.name}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Device ID</th>
                        <td mat-cell *matCellDef="let sensor">{{sensor.id}}</td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th class="action-column" mat-header-cell *matHeaderCellDef>Action</th>
                        <td class="action-column" mat-cell *matCellDef="let sensor">
                            <mat-icon class="action-icon" title="Edit" (click)="edit(sensor)">
                                <i class="fas fa-edit"></i>
                            </mat-icon>

                            <mat-icon *ngIf="canUpdateDeviceShadow && sensor.configurationCapability" class="action-icon color-primary-light" title="Configure" (click)="configure(sensor)">
                                <i class="fas fa-cog"></i>
                            </mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; let even = even; columns: displayedColumns;"
                        [class.even-row]="even"></tr>
                </table>

                <mat-paginator [length]="dataSource.count$ | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"
                    showFirstLastButtons></mat-paginator>
            </div>

            <div class="row">
                <div class="col-lg-3 offset-lg-9">
                    <mat-form-field>
                        <mat-label>Time Zone</mat-label>
                        <input matInput [(ngModel)]="timeZoneLabel" readonly />
                        <mat-icon matPrefix class="mr-3">
                            <i class="fas fa-globe"></i>
                        </mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div class="clearfix"></div>
            <app-footer></app-footer>
        </div>

        <div class="clearfix"></div>
    </div>
</body>
