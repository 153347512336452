import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services';
import { SecurityProfileEnum } from 'src/app/enums/securityProfile.enum';
import { Router } from '@angular/router';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent extends TrackableComponent implements OnInit {

    userType: number;
    canCreateSubusers = false;
    canViewDataAnalysisPage = false;
    canViewNotifications = false;
    FeatureUsageActionEnum = FeatureUsageActionEnum;

    constructor(private authService: AuthService,
        private router: Router,
        featureUsageService: FeatureUsageService) {

        super(featureUsageService, FeatureUsagePageEnum.Sidebar);
    }

    ngOnInit() {
        const currentUser = this.authService.getCurrentUser();
        this.userType = currentUser.userType;
        this.canCreateSubusers = currentUser.tenantPermissions.canCreateSubusers;
        this.canViewDataAnalysisPage = currentUser.tenantPermissions.canViewDataAnalysisPage;
        this.canViewNotifications = currentUser.tenantPermissions.canViewNotifications;
    }

    get isAdmin() { return this.userType === SecurityProfileEnum.Admin; }
    get isSuperAdmin() { return this.userType === SecurityProfileEnum.SuperAdmin; }
    get isUser() { return this.userType === SecurityProfileEnum.User; }

    toggleSideMenu() {
        $('.body-container').toggleClass('collapsed');
    }

    navigateTo(url: string, action: FeatureUsageActionEnum) {
        this.recordAction(action);
        this.recordActivity();

        this.router.navigate([url]);
    }
}
