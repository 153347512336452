export class ShadowConstraint {
    id: number;
    deviceVersion: string;
    deviceVersionId: number;
    field: string;
    constraintTypeId: number;
    constraintType: string;
    operatorTypeId: number;
    operatorType: string;
    operatorValue: string;
    operatorTypeId2: number;
    operatorType2: string;
    operatorValue2: string;
}
