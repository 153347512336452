export class TenantSensor {
    id: string;
    name: string;
    imeI_No: string;
    latitude: number;
    longitude: number;
    status: boolean;
    installationDate: Date;
    installDate: string;
    tenantId: number;
    tenantName: string;
    deviceVersionId?: number;
    deviceVersion: string;
    gpsCapability: boolean;
    tableNames: string[];
    videoCapability: boolean;
    density: number;
    airNowIntlAQSCode: string;
    configurationCapability: boolean;
}
