<h4 class="dialog-title text-center">Enter OTP Code</h4>

<hr />

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>OTP Code</mat-label>
                    <input matInput [(ngModel)]="otp" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-lock"></i>
                    </mat-icon>
                    <mat-error>OTP Code is required.</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button class="btn button-primary" (click)="ok()" [class.button-spinner]="isLoading"
            [disabled]="!otp || isLoading">OK</button>

        <button mat-button class="btn button-accent" mat-dialog-close (click)="onClose()">Cancel</button>
    </div>
</div>
