import { FeatureUsagePageEnum } from '../enums/featureUsagePage.enum';
import { ErrorLog } from './errorLog.model';
import { FeatureUsageAction } from './featureUsageAction.model';

export class FeatureUsageActivity {
    timestampEnd: number;

    constructor(public page: FeatureUsagePageEnum,
        public timestampStart: number,
        public actions: FeatureUsageAction[],
        public errors: ErrorLog[]) {

        this.timestampEnd = (new Date()).getTime();
    }
}
