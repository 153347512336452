import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services';
import { Router } from '@angular/router';
import { ToastService } from '../../services/toast.service';
import { environment } from 'src/environments/environment';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { NotificationService } from 'src/app/services/notification.service';
import { SecurityProfileEnum } from 'src/app/enums/securityProfile.enum';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent extends TrackableComponent implements OnInit {
    userEmail: string;
    tenantLogoUrl: string;
    FeatureUsageActionEnum = FeatureUsageActionEnum;
    newNotificationCount: number;
    isAdmin: boolean;
    canViewNotifications = false;

    constructor(private router: Router,
        private toastService: ToastService,
        private authService: AuthService,
        private notificationService: NotificationService,
        featureUsageService: FeatureUsageService
    ) {
        super(featureUsageService, FeatureUsagePageEnum.Header);
        const currentUser = this.authService.getCurrentUser();
        this.userEmail = currentUser.userEmail;
        if (currentUser.tenantLogoUrl) {
            this.tenantLogoUrl = environment.baseUrl + '/' + currentUser.tenantLogoUrl;
        }
        this.isAdmin = currentUser.userType === SecurityProfileEnum.Admin;
        this.canViewNotifications = currentUser.tenantPermissions.canViewNotifications;
    }

    ngOnInit() {
        if (!this.isAdmin || !this.canViewNotifications) {
            return;
        }
        this.notificationService.getNewNotificationCount()
            .subscribe(count => {
                this.newNotificationCount = count;
            }, error => {
                console.log(error);
                this.toastService.showError(error);
            });
    }

    onHeaderButtonClick(action: FeatureUsageActionEnum) {
        this.recordAction(action);
        this.recordActivity();
    }

    logout() {
        this.recordAction(FeatureUsageActionEnum.HeaderLogoutClick);
        this.recordActivity();

        this.authService.logout()
            .subscribe(
                response => {
                    this.toastService.showSuccess('Logged out');
                    this.router.navigate(['/login']);
                },
                error => {
                    console.log(error);
                });
    }
}
