import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EndpointService } from './endpoint.service';
import { IGridService } from '../shared/grid/grid.service';
import { GridModel } from '../shared/grid/grid.model';
import { CustomObject, CustomObjectModel } from '../models/customObject.model';
import { ToastService } from './toast.service';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CustomField } from '../models/customField.model';
import { CustomObjectTypeEnum } from '../enums/customObjectType.enum';
import { Enum } from '../models/enum.model';
import { CustomObjectLookup } from '../models/customObjectLookup.model';

@Injectable({
    providedIn: 'root'
})
export class CustomObjectService extends EndpointService implements IGridService<CustomObject> {

    constructor(http: HttpClient,
        private toastService: ToastService) {
        super(http);
    }

    get(filter: string, orderBy: string, sort: string, page: number, pageSize: number, headers?: HttpHeaders) {
        return this.http.get<GridModel<CustomObject>>(this.getApiUrl('CustomObject/GetCustomObjects'), {
            params: this.createParams(filter, orderBy, sort, page, pageSize),
            headers: headers
        });
    }

    getCustomObject(id: number): Observable<CustomObject> {
        return this.http.get<CustomObject>(this.getApiUrl(`CustomObject/GetCustomObject?id=${id}`))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading custom object.');
                    return of(new CustomObject());
                })
            );
    }

    create(payload: CustomObjectModel): Observable<number> {
        return this.http.post<number>(this.getApiUrl('CustomObject/CreateCustomObject'), payload);
    }

    update(payload: CustomObject) {
        return this.http.post(this.getApiUrl('CustomObject/UpdateCustomObject'), payload);
    }

    delete(id: number) {
        return this.http.delete(this.getApiUrl(`CustomObject/DeleteCustomObject?id=${id}`));
    }

    getTypeLookup(type: CustomObjectTypeEnum): Observable<CustomObjectLookup[]> {
        return this.http.get<CustomObjectLookup[]>(this.getApiUrl(`CustomObject/GetTypeLookup?type=${type}`))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading types.');
                    return of([]);
                })
            );
    }

    getCustomObjectFields(id: number): Observable<CustomField[]> {
        return this.http.get<CustomField[]>(this.getApiUrl(`CustomObject/GetCustomObjectFields?id=${id}`))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading custom fields.');
                    return of([]);
                })
            );
    }

    getCustomObjectTypes(): Observable<Enum[]> {
        return this.http.get<Enum[]>(this.getApiUrl('CustomObject/GetCustomObjectTypes'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading custom object types.');
                    return of([]);
                })
            );
    }

    getCustomFieldTypes(): Observable<Enum[]> {
        return this.http.get<Enum[]>(this.getApiUrl('CustomObject/GetCustomFieldTypes'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading custom field types.');
                    return of([]);
                })
            );
    }
}
