import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/toast.service';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { CustomField } from 'src/app/models/customField.model';
import { CustomObjectService } from 'src/app/services/customObject.service';
import { CustomEntityModel } from 'src/app/models/customEntity.model';
import { CustomFieldDialogComponent } from '../customFieldDialog/customFieldDialog.component';
import { CustomFieldTypeEnum } from 'src/app/enums/customFieldType.enum';
import { CustomEntityService } from 'src/app/services/customEntity.service';
import { forkJoin } from 'rxjs';
import { UserLookup } from 'src/app/models/userLookup.model';
import { UserService } from 'src/app/services/user.service';
import { CustomObjectLookup } from 'src/app/models/customObjectLookup.model';
import { CustomObjectTypeEnum } from 'src/app/enums/customObjectType.enum';

export interface CreateEndPointDialogData {
    label: string;
}

@Component({
    selector: 'app-create-end-point-dialog',
    templateUrl: 'createEndPointDialog.component.html'
})
export class CreateEndPointDialogComponent extends TrackableComponent implements OnInit, OnDestroy {

    isFormLoading = false;
    isLoading = false;
    customEntity: CustomEntityModel;
    categories: CustomObjectLookup[] = [];
    customFields: CustomField[];
    CustomFieldTypeEnum = CustomFieldTypeEnum;
    users: UserLookup[];
    propertyId = 0;

    constructor(public dialogRef: MatDialogRef<CreateEndPointDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CreateEndPointDialogData,
        private customObjectService: CustomObjectService,
        private customEntityService: CustomEntityService,
        private toastService: ToastService,
        featureUsageService: FeatureUsageService,
        private dialog: MatDialog,
        private userService: UserService
    ) {
        super(featureUsageService, FeatureUsagePageEnum.CreateEndPointDialog);
        this.customEntity = new CustomEntityModel();
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    ngOnInit() {
        this.isFormLoading = true;
        forkJoin({
            categories: this.customObjectService.getTypeLookup(CustomObjectTypeEnum.EndPoint),
            users: this.userService.getUserLookupList()
        }).subscribe(
            result => {
                this.categories = result.categories;
                this.users = result.users;
                this.isFormLoading = false;
            },
            error => {
                console.log(error);
                this.toastService.showError(error);
                this.isFormLoading = false;
            }
        );
    }

    onCategoryChanged() {
        this.isFormLoading = true;
        this.customObjectService.getCustomObjectFields(this.customEntity.customObjectId)
            .subscribe(
                result => {
                    this.customEntity.customFields = [];
                    this.customFields = this.mapCustomFields(result);
                    this.isFormLoading = false;
                },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isFormLoading = false;
                }
            );
    }

    addEditField(field: CustomField = null, event: MouseEvent) {
        const isAdd = field === null;

        const position = {
            top: `${event.y + 400 > window.innerHeight ? event.y - 370 : event.y}px`,
            left: `${event.x}px`
        };

        const names: string[] = [];
        names.push(...this.customFields.filter(x => x.name !== field?.name).map(x => x.name));
        names.push('id', 'name', 'userId', 'user', 'customObjectId', 'customFields');

        const customFieldDialog = this.dialog.open(CustomFieldDialogComponent, {
            data: {
                names: names,
                customField: field ? JSON.parse(JSON.stringify(field)) : new CustomField(),
            },
            width: '450px',
            disableClose: true,
            position: position
        });

        customFieldDialog.afterClosed()
            .subscribe((customField: CustomField) => {
                if (customField && customField.name && customField.type !== undefined) {
                    if (isAdd) {
                        if (!this.customFields) {
                            this.customFields = [];
                        }
                        customField.property = `new_field_${this.propertyId++}`;
                        this.customFields.push(...this.mapCustomFields([customField]));
                    } else {
                        field.name = customField.name;
                        field.type = customField.type;
                        field.data = customField.data;
                        this.mapCustomFields([field]);
                    }
                }
            });

        if (!isAdd) {
            event.stopPropagation();
        }
    }

    mapCustomFields(customFields: CustomField[]) {
        return customFields.map(field => {
            if (field.type === CustomFieldTypeEnum.SingleSelect) {
                field['values'] = field.data.split(',').map(x => x.trim());
            }
            return field;
        });
    }

    deleteField(field: CustomField, event: MouseEvent = null) {
        this.customFields.splice(this.customFields.indexOf(field), 1);

        if (event) {
            event.stopPropagation();
        }
    }

    isDisabled(): boolean {
        if (!this.customEntity.name || !this.customEntity.customObjectId) {
            return true;
        }
        let disabled = false;
        this.customFields.forEach(field => {
            if (this.customEntity[field.property] === undefined) {
                disabled = true;
            }
        });
        return disabled;
    }

    save() {
        this.isLoading = true;
        this.customEntity.customFields = this.customFields;

        this.customEntityService.create(this.customEntity)
            .subscribe(endPointId => {
                this.isLoading = false;
                this.toastService.showSuccess(`${this.data.label} created successfully.`);
                this.customEntity.customFields = undefined;
                this.dialogRef.close(endPointId);
            }, error => {
                this.isLoading = false;
                console.log(error);
                this.toastService.showError(error);
                this.customEntity.customFields = undefined;
            });

        this.recordAction(FeatureUsageActionEnum.CreateCustomEntityClick);
    }

    onClose() {
        this.recordAction(FeatureUsageActionEnum.CancelCreateCustomEntityClick);
    }
}
