import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { UserService } from '../../services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateUserDialogComponent } from '../createUserDialog/createUserDialog.component';
import { ToastService } from 'src/app/services/toast.service';
import { ConfirmDialogComponent } from 'src/app/shared/confirmDialog/confirmDialog.component';
import { User } from 'src/app/models/user.model';
import { EditUserDialogComponent } from '../editUserDialog/editUserDialog.component';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { AuthService } from 'src/app/services';
import { TimeZoneService } from 'src/app/services/timeZone.service';
import { TrackableGridBaseComponent } from 'src/app/shared/grid/trackableGrid.component';

@Component({
    selector: 'app-aspnetuserlist',
    templateUrl: './aspnetuserlist.component.html',
    styleUrls: ['./aspnetuserlist.component.css']
})
export class UserListComponent extends TrackableGridBaseComponent<User> implements OnInit, AfterViewInit, OnDestroy {

    constructor(protected userService: UserService,
        toastService: ToastService,
        authService: AuthService,
        timeZoneService: TimeZoneService,
        featureUsageService: FeatureUsageService,
        dialog: MatDialog
    ) {
        super(toastService, authService, timeZoneService, dialog, featureUsageService, FeatureUsagePageEnum.UsersPage);
        this.gridService = userService;
        this.displayedColumns = ['userName', 'fullName', 'securityProfileId', 'phoneNumber', 'jobTitle', 'action'];
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    createUser() {
        this.openDialog(CreateUserDialogComponent, {}, undefined, FeatureUsageActionEnum.UsersAddClick);
    }

    editUser(user: User) {
        this.openDialog(EditUserDialogComponent, { id: user.id }, undefined, FeatureUsageActionEnum.UsersEditClick);
    }

    deleteUser(selectedUser: User) {
        this.openTrackableDeleteDialog(
            ConfirmDialogComponent,
            {
                title: 'Delete User',
                message: `Are you sure you want to delete user ${selectedUser.userName}?`,
                primaryButtonText: 'Delete',
                primaryCallback: () => this.userService.deleteUser(selectedUser.id),
                successMessage: 'User deleted successfully!',
                secondaryButtonText: 'Cancel'
            },
            undefined,
            FeatureUsageActionEnum.UsersDeleteClick,
            FeatureUsageActionEnum.DeleteUserClick,
            FeatureUsageActionEnum.CancelDeleteUserClick
        );
    }
}
