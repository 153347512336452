import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ToastService } from '../../services/toast.service';
import { CreateSensorAssignmentDialogComponent } from '../createSensorAssignmentDialog/createSensorAssignmentDialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/confirmDialog/confirmDialog.component';
import { EditSensorAssignmentDialogComponent } from '../editSensorAssignmentDialog/editSensorAssignmentDialog.component';
import { SensorAssignment } from 'src/app/models/sensorAssignment.model';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { AuthService } from 'src/app/services';
import { TimeZoneService } from 'src/app/services/timeZone.service';
import { TrackableGridBaseComponent } from 'src/app/shared/grid/trackableGrid.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent extends TrackableGridBaseComponent<SensorAssignment> implements OnInit, AfterViewInit, OnDestroy {

    constructor(protected sensorAssignmentService: ConfigurationService,
        toastService: ToastService,
        authService: AuthService,
        timeZoneService: TimeZoneService,
        featureUsageService: FeatureUsageService,
        dialog: MatDialog,
        private router: Router
    ) {
        super(toastService, authService, timeZoneService, dialog, featureUsageService, FeatureUsagePageEnum.SensorAssignmentsPage);
        this.gridService = sensorAssignmentService;
        this.displayedColumns = ['EndPoint.Name', 'Sensor.Name', 'startDate', 'endDate', 'AssignedByUser.UserName', 'createdDate', 'action'];
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    setSensorId(sensorAssignment: SensorAssignment) {
        const encSensorId = window.btoa(sensorAssignment.sensorId);
        localStorage.setItem('deviceName', encSensorId);
        this.recordAction(FeatureUsageActionEnum.SensorsSensorClick);
        this.router.navigate(['/sensor']);
    }

    viewEndPoint(sensorAssignment: SensorAssignment) {
        this.recordAction(FeatureUsageActionEnum.UsersUserClick);
        this.router.navigate([`/end-point-view/${sensorAssignment.endPointId}`]);
    }

    add() {
        this.openDialog(CreateSensorAssignmentDialogComponent, {}, undefined, FeatureUsageActionEnum.SensorAssignmentsAddClick);
    }

    edit(sensorAssignment: SensorAssignment) {
        this.openDialog(EditSensorAssignmentDialogComponent, { id: sensorAssignment.id }, undefined, FeatureUsageActionEnum.SensorAssignmentsEditClick);
    }

    deleteSensorAssignment(id) {
        this.openTrackableDeleteDialog(
            ConfirmDialogComponent,
            {
                title: 'Delete Sensor Assignment',
                message: 'Are you sure you want to delete this sensor assignment?',
                primaryButtonText: 'Delete',
                primaryCallback: () => this.sensorAssignmentService.deleteSensorAssignment(id),
                successMessage: 'Sensor Assignment deleted successfully!',
                secondaryButtonText: 'Cancel'
            },
            undefined,
            FeatureUsageActionEnum.SensorAssignmentsDeleteClick,
            FeatureUsageActionEnum.DeleteSensorAssignmentClick,
            FeatureUsageActionEnum.CancelDeleteSensorAssignmentClick
        );
    }
}
