import { ErrorCodeEnum } from '../enums/errorCode.enum';

export class ErrorLog {
    timestamp: number;

    constructor(public errorCode: ErrorCodeEnum,
        public message: string) {
        this.timestamp = (new Date()).getTime();
    }
}
