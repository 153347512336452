import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MonitoringSiteObservationDto, MonitoringSiteObservationModel } from 'src/app/models/monitoringSiteObservation.model';
import { AirNowService } from 'src/app/services/airNow.service';
import { SensorService } from 'src/app/services/sensor.service';
import { ToastService } from 'src/app/services/toast.service';
import { CreateSensorAssignmentDialogComponent } from '../createSensorAssignmentDialog/createSensorAssignmentDialog.component';

@Component({
    selector: 'app-info-window',
    templateUrl: './info-window.component.html',
    styleUrls: ['./info-window.component.css']
})
export class InfoWindowComponent implements OnInit {

    @Input() siteId: string;
    @Input() latitude: number;
    @Input() longitude: number;

    observations: MonitoringSiteObservationModel[];
    observation: MonitoringSiteObservationModel;
    isFormLoading = false;
    isLoading = false;

    constructor(private airNowService: AirNowService,
        private sensorService: SensorService,
        private dialog: MatDialog,
        private toastService: ToastService) { }

    ngOnInit(): void {
        const payload = new MonitoringSiteObservationDto(
            [this.longitude - 0.01, this.latitude - 0.01, this.longitude + 0.01, this.latitude + 0.01]
        );
        this.isFormLoading = true;
        this.airNowService.getObservationsByMonitoringSite(payload)
            .subscribe(result => {
                const observations = result.filter(x => x.intlAQSCode === this.siteId);
                if (observations.length > 0) {
                    this.observation = observations[0];
                }
                this.observations = observations;
                this.isFormLoading = false;
            }, error => {
                console.log(error);
                this.toastService.showError(error);
                this.isFormLoading = false;
            });
    }

    assign() {
        this.isLoading = true;
        this.sensorService.getOrCreateSensorByAirNowSiteId(this.siteId)
            .subscribe(
                sensor => {
                    this.dialog.open(CreateSensorAssignmentDialogComponent, {
                        data: {sensorId: sensor.id},
                        width: '450px',
                        disableClose: true
                    });
                    this.isLoading = false;
                }, error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isLoading = false;
                }
            );
    }
}
