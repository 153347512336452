<h4 class="dialog-title text-center">Device Configuration</h4>

<hr />

<app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Device ID</mat-label>
                    <input matInput [(ngModel)]="deviceShadow.id" readonly class="cursor-not-allowed"
                        title="Device ID cannot be changed." />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-broadcast-tower"></i>
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12" *ngFor="let shadowField of deviceShadow.fields">
                <div *ngIf="isSuperAdmin || shadowField.statusId !== FieldStatusEnum.Hidden">
                    <div *ngIf="shadowField.fieldTypeId === FieldTypeEnum.Boolean" class="padding-y-10"
                    [class.cursor-not-allowed]="shadowField.statusId === FieldStatusEnum.Readonly"
                    [title]="shadowField.statusId === FieldStatusEnum.Readonly
                    ? shadowField.label + ' cannot be changed.'
                    : ''">
                        <mat-icon class="mr-3">
                            <i class="fas fa-check-circle"></i>
                        </mat-icon>
                        <mat-label class="mr-3">{{shadowField.label + (isSuperAdmin ? ' (' + shadowField.name + ')' : '')}}</mat-label>
                        <mat-slide-toggle color="primary" [(ngModel)]="shadowField.value"
                        [disabled]="shadowField.statusId === FieldStatusEnum.Readonly"></mat-slide-toggle>
                        <hr class="field-underline" />
                    </div>

                    <mat-form-field *ngIf="shadowField.fieldTypeId !== FieldTypeEnum.Boolean">
                        <mat-label>{{shadowField.label + (isSuperAdmin ? ' (' + shadowField.name + ')' : '')}}</mat-label>
                        <input matInput [(ngModel)]="shadowField.value"
                        [type]="shadowField.fieldTypeId === FieldTypeEnum.Integer || shadowField.fieldTypeId === FieldTypeEnum.Float
                        ? 'number'
                        : 'text'"
                        [readonly]="shadowField.statusId === FieldStatusEnum.Readonly"
                        [class.cursor-not-allowed]="shadowField.statusId === FieldStatusEnum.Readonly"
                        [title]="shadowField.statusId === FieldStatusEnum.Readonly
                        ? shadowField.label + ' cannot be changed.'
                        : ''" />
                        <mat-icon matPrefix class="mr-3">
                            <i [class]="shadowField.fieldTypeId === FieldTypeEnum.Integer || shadowField.fieldTypeId === FieldTypeEnum.Float ? 'fas fa-list-ol' : 'fas fa-file-alt'"></i>
                        </mat-icon>
                    </mat-form-field>

                    <div *ngIf="shadowField.statusId !== FieldStatusEnum.Readonly" class="reported-label mb-3">
                        <label class="ml-4 pl-3">Reported: {{shadowField.reportedValue}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button class="btn button-primary" (click)="save()" [class.button-spinner]="isLoading"
            [disabled]="isLoading">
            Save
        </button>

        <button mat-button class="btn button-accent" mat-dialog-close (click)="onClose()">Cancel</button>
    </div>
</div>
