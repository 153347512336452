import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/services/toast.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserImpersonationModel } from 'src/app/models/userImpersonation.model';
import { AuthService } from 'src/app/services';
import { Router } from '@angular/router';
import { SecurityProfileEnum } from 'src/app/enums/securityProfile.enum';

export interface TenantUsersData {
    tenantId: number;
    tenantName: string;
}

@Component({
    selector: 'app-tenant-users-dialog',
    templateUrl: 'tenantUsersDialog.component.html',
    styleUrls: ['tenantUsersDialog.component.css']
})
export class TenantUsersDialogComponent implements OnInit {

    isLoading = false;
    dataSource: MatTableDataSource<UserImpersonationModel>;
    displayedColumns = ['userName', 'securityProfile', 'action'];

    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(public dialogRef: MatDialogRef<TenantUsersDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: TenantUsersData,
        private userService: UserService,
        private authService: AuthService,
        private router: Router,
        private toastService: ToastService) {
    }

    ngOnInit() {
        this.isLoading = true;
        this.dataSource = new MatTableDataSource<UserImpersonationModel>();
        this.dataSource.sort = this.sort;

        this.userService.getUserImpersonationList(this.data.tenantId)
            .subscribe(
                users => {
                    this.dataSource.data = users;
                    this.isLoading = false;
                },
                error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading users.');
                    this.isLoading = false;
                });
    }

    filterUsers(text: string) {
        this.dataSource.filter = text.trim().toLocaleLowerCase();
    }

    impersonateUser(id: string) {
        if (this.authService.getCurrentUser().userType !== SecurityProfileEnum.SuperAdmin) {
            return;
        }

        this.authService.impersonateUser(id)
            .subscribe(
                response => {
                    const currentUrl = this.router.url;
                    const targetUrl = '/dashboard';
                    this.router.navigate([targetUrl]);
                    this.toastService.showSuccess('Logged in.');
                    if (targetUrl === currentUrl) {
                        location.reload();
                    }
                    this.dialogRef.close();
                },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                }
            );
    }
}
