import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-page-header',
    templateUrl: './pageHeader.component.html'
})
export class PageHeaderComponent {

    @Input() title: string;
    @Input() iconClass: string;
    @Input() iconText: string;

    constructor() {
    }
}
