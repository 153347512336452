import { ErrorCodeEnum } from 'src/app/enums/errorCode.enum';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { ErrorLog } from 'src/app/models/errorLog.model';
import { FeatureUsageAction } from 'src/app/models/featureUsageAction.model';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { ITrackableComponent } from './trackableComponent.interface';

export class TrackableComponent implements ITrackableComponent {
    private actions: FeatureUsageAction[] = [];
    private errors: ErrorLog[] = [];
    private timestampStart: number;

    constructor(private featureUsageService: FeatureUsageService,
        private page: FeatureUsagePageEnum) {

        this.timestampStart = (new Date()).getTime();
    }

    public recordAction(action: FeatureUsageActionEnum, ipAddress: string = null) {
        this.actions.push(new FeatureUsageAction(action, ipAddress));
    }

    public recordError(errorCode: ErrorCodeEnum, message: string) {
        this.errors.push(new ErrorLog(errorCode, message));
    }

    public recordActivity() {
        this.featureUsageService.recordActivity(this.page, this.timestampStart, this.actions, this.errors)
            .subscribe();

        this.timestampStart = (new Date()).getTime();
        this.actions = [];
        this.errors = [];
    }
}
