import { SensorRecordsRequestModel } from './sensorRecordsRequest.model';

export class DataAnalysisRequestModel extends SensorRecordsRequestModel {
    timeIntervalId: number;
    startTime: string;
    endTime: string;
    name: string;
    selectedDevices: string[];
    includeGps: boolean;
}
