<h4 class="dialog-title text-center">Select Time Interval</h4>

<hr />

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <mat-label>Start Time</mat-label>
                    <input matInput [(ngModel)]="data.startDate" class="clickable" [owlDateTime]="tStart"
                        [owlDateTimeTrigger]="tStart" required />
                    <mat-icon matPrefix class="clickable mr-3" [owlDateTimeTrigger]="tStart">
                        <i class="fas fa-clock"></i>
                    </mat-icon>
                    <mat-error>Start Time is required.</mat-error>
                    <owl-date-time #tStart pickerType="timer"></owl-date-time>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <mat-form-field>
                    <mat-label>End Time</mat-label>
                    <input matInput [(ngModel)]="data.endDate" class="clickable" [owlDateTime]="tEnd"
                        [owlDateTimeTrigger]="tEnd" required />
                    <mat-icon matPrefix class="clickable mr-3" [owlDateTimeTrigger]="tEnd">
                        <i class="fas fa-clock"></i>
                    </mat-icon>
                    <mat-error>End Time is required.</mat-error>
                    <owl-date-time #tEnd pickerType="timer"></owl-date-time>
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-4" *ngIf="data.startDate > data.endDate">
            <div class="col">
                <mat-icon class="mr-2 color-primary-light">
                    <i class="fas fa-info-circle"></i>
                </mat-icon>
                <mat-label>End Time will be carried over to the next day.</mat-label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button class="btn button-primary" (click)="ok()" [disabled]="isDisabled()">
            Ok
        </button>

        <button mat-button class="btn button-accent" mat-dialog-close>
            Cancel
        </button>
    </div>
</div>
