import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class ToastService {
    constructor(private toastrService: ToastrService) {
        this.setToastrConfig();
    }

    private setToastrConfig() {
        this.toastrService.toastrConfig.enableHtml = true;
        this.toastrService.toastrConfig.closeButton = true;
        this.toastrService.toastrConfig.extendedTimeOut = 2000;
        this.toastrService.toastrConfig.tapToDismiss = false;
        this.toastrService.toastrConfig.newestOnTop = false;
    }

    showSuccess(message: string) {
        this.toastrService.success(message);
    }

    showWarning(message: string) {
        this.toastrService.warning(message);
    }

    showMessage(message: string) {
        this.toastrService.show(message);
    }

    showError(error: any, message: string = 'An error has occurred.') {
        const isShown = this.displayError(error);

        if (!isShown) {
            this.displayErrorMessage(message);
        }
    }

    showErrors(error: any, properties: string[], message = 'An error has occurred') {
        let isShown = this.displayError(error);

        if (!isShown && error.error && error.error.errors) {
            const errors = error.error.errors;
            properties.forEach(property => {
                if (errors[property]) {
                    isShown = true;
                    this.displayErrorMessage(errors[property]);
                }
            });
        }

        if (!isShown) {
            this.displayErrorMessage(message);
        }
    }

    private displayError(error: any): boolean {
        let isShown = false;

        if (error.error && error.error.message) {
            isShown = true;
            this.displayErrorMessage(error.error.message);
        } else if (error.error && typeof error.error === 'string') {
            isShown = true;
            this.displayErrorMessage(error.error);
        }

        return isShown;
    }

    displayErrorMessage(message: string) {
        this.toastrService.error(message);
    }
}
