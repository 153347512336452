import { ComponentType } from '@angular/cdk/portal';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fromEvent, merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services';
import { TimeZoneService } from 'src/app/services/timeZone.service';
import { ToastService } from 'src/app/services/toast.service';
import { GridDataSource } from './grid.dataSource';
import { IGridService } from './grid.service';

@Component({
    selector: 'app-grid-base',
    template: '<div>GridBaseComponent</div>'
})
export abstract class GridBaseComponent<T> implements OnInit, AfterViewInit {

    dataSource: GridDataSource<T>;
    protected gridService: IGridService<T>;
    displayedColumns: string[];
    defaultOrderBy = 'asc';
    timeZoneLabel: string;
    isLoading = false;
    coId: number;
    subscriptions: Subscription[] = [];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('searchField', { static: true }) searchField: ElementRef;

    constructor(protected toastService: ToastService,
        authService: AuthService,
        timeZoneService: TimeZoneService,
        protected dialog: MatDialog
    ) {
        this.timeZoneLabel = timeZoneService.createTimeZoneLabel(authService.getCurrentUser().userTimeZone);
        this.isLoading = true;
    }

    ngOnInit(): void {
        this.isLoading = false;
        this.dataSource = new GridDataSource<T>(this.gridService, this.toastService);
        this.dataSource.load('', this.displayedColumns[0], this.defaultOrderBy, 0, 10, this.coId);
    }

    ngAfterViewInit(): void {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
        this.subscriptions = [];

        this.subscriptions.push(
            this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0),
            fromEvent(this.searchField.nativeElement, 'keyup')
                .pipe(
                    debounceTime(200),
                    distinctUntilChanged(),
                    tap(() => {
                        this.paginator.pageIndex = 0;
                        this.loadPage();
                    })
                )
                .subscribe(),
            merge(this.sort.sortChange, this.paginator.page)
                .pipe(
                    tap(() => this.loadPage())
                )
                .subscribe());
    }

    loadPage() {
        this.dataSource.load(
            this.searchField.nativeElement.value,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.coId
        );
    }

    openDialog<T1>(dialogComponent: ComponentType<T1>, data: any, reloadAction?: (val: any) => any) {
        const dialogRef = this.dialog.open(dialogComponent, {
            data: data,
            width: '450px',
            disableClose: true
        });

        dialogRef.afterClosed()
            .subscribe((result: any) => {
                if (result) {
                    reloadAction ? reloadAction(result) : this.loadPage();
                }
            });
    }

    openDeleteDialog<T1>(dialogComponent: ComponentType<T1>, data: any, reloadAction?: (val?: any) => any, successAction?: (val?: any) => any, cancelAction?: (val?: any) => any) {
        const dialogRef = this.dialog.open(dialogComponent, {
            data: data,
            width: '450px',
            disableClose: true
        });

        dialogRef.afterClosed()
            .subscribe(result => {
                if (result !== '') {
                    reloadAction ? reloadAction() : this.loadPage();
                    if (successAction) {
                        successAction();
                    }
                } else {
                    if (cancelAction) {
                        cancelAction();
                    }
                }
            });
    }
}
