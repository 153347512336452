<div class="login-bg d-flex justify-content-center align-items-center">
    <div class="login-area registration-area">
        <app-logo></app-logo>

        <app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

        <div class="row">
            <div class="col text-center">
                <h3>Registration Page</h3>
                <hr />
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>First Name</mat-label>
                    <input matInput [(ngModel)]="user.firstName" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-address-card"></i>
                    </mat-icon>
                    <mat-error>First Name is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Last Name</mat-label>
                    <input matInput [(ngModel)]="user.lastName" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-address-card"></i>
                    </mat-icon>
                    <mat-error>Last Name is required.</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input matInput [(ngModel)]="user.email" type="email" [email]="true" #email="ngModel" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-envelope"></i>
                    </mat-icon>
                    <mat-error *ngIf="email.errors?.required">Email is required.</mat-error>
                    <mat-error *ngIf="email.errors?.email">Invalid email address.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Company Name</mat-label>
                    <input matInput [(ngModel)]="user.companyName" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-building"></i>
                    </mat-icon>
                    <mat-error>Company Name is required.</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label>Mobile Number</mat-label>
                    <input matInput [(ngModel)]="user.mobileNumber" />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-mobile-alt"></i>
                    </mat-icon>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col" align="end">
                <button mat-button class="btn button-primary" (click)="submit()" [class.button-spinner]="isLoading"
                    [disabled]="!user.firstName || !user.lastName || !user.companyName || email.invalid || isLoading">
                    Submit</button>

                <button mat-button class="btn button-accent ml-2" [routerLink]="['/cancel']">Cancel</button>
            </div>
        </div>
    </div>
</div>
