import { DayOfWeekEnum } from '../enums/dayOfWeek.enum';
import { NotificationAveragingEnum } from '../enums/notificationAveraging.enum';
import { NotificationLimitTypeEnum } from '../enums/notificationLimitType.enum';
import { ParameterTypeEnum } from '../enums/parameterType.enum';
import { ShiftTypeEnum } from '../enums/shiftType.enum';
import { DateRange, DateRangeModel } from './dateRange.model';
import { Enum } from './enum.model';
import { UserLookup } from './userLookup.model';

export class NotificationSettings {
    id: number;
    isEnabled: boolean;
    name: string;
    parameterType: ParameterTypeEnum;
    averagingType: NotificationAveragingEnum;
    shiftType: ShiftTypeEnum;
    workingDays: DayOfWeekEnum[];
    shifts: DateRange[];
    limit: number;
    limitType: NotificationLimitTypeEnum;
    threshold: number;
    createdBy: string;
    subscribers: string[];
    subscribedEmails: string[];
}

export class NotificationSettingsModel {
    id: number;
    isEnabled: boolean;
    name: string;
    parameterType: ParameterTypeEnum;
    averagingType: NotificationAveragingEnum;
    shiftType: ShiftTypeEnum;
    workingDays: DayOfWeekEnum[];
    shifts: DateRangeModel[];
    limit: number;
    limitType: NotificationLimitTypeEnum;
    threshold: number;
    subscribers: string[];
}

export class NotificationSettingsData {
    parameterTypes: Enum[];
    averagingTypes: Enum[];
    shiftTypes:  Enum[];
    limitTypes: Enum[];
    daysOfWeek: Enum[];
    admins: UserLookup[];
}
