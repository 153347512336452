import { CustomField } from './customField.model';

export class CustomEntity {
    id: number;
    name: string;
    userId: string;
    customObjectId: number;
}

export class CustomEntityModel {
    id: number;
    name: string;
    userId: string;
    customObjectId: number;
    customFields: CustomField[];
}
