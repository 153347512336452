<div class="login-bg d-flex justify-content-center align-items-center">
    <div class="login-area">
        <app-logo></app-logo>

        <div class="row">
            <div class="col text-center">
                <h3>LOGIN</h3>
                <hr />
            </div>
        </div>

        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="off">
            <div class="row">
                <div class="col-md">
                    <mat-form-field>
                        <mat-label>Username</mat-label>
                        <input matInput formControlName="username" type="email" [email]="true" required />
                        <mat-icon matPrefix class="mr-3">
                            <i class="fas fa-user"></i>
                        </mat-icon>
                        <mat-error *ngIf="loginForm.controls.username.hasError('required')">
                            Username is required.
                        </mat-error>
                        <mat-error *ngIf="loginForm.controls.username.hasError('email')">
                            Invalid email address.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md">
                    <mat-form-field>
                        <mat-label>Passsword</mat-label>
                        <input matInput formControlName="password" [type]="show ? 'text' : 'password'" required />
                        <mat-icon matPrefix class="mr-3">
                            <i class="fas fa-lock"></i>
                        </mat-icon>
                        <button type="button" mat-icon-button matSuffix (click)="show = !show">
                            <mat-icon>{{show ? 'visibility' : 'visibility_off'}}</mat-icon>
                        </button>
                        <mat-error>Password is required.</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <mat-checkbox [(ngModel)]="rememberMe" [ngModelOptions]="{standalone: true}">
                        Remember me
                    </mat-checkbox>
                </div>

                <div class="col text-right">
                    <a [routerLink]="['/forgotpassword']">
                        FORGOT PASSWORD
                    </a>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col">
                    <button mat-button class="btn button-primary" type="button" [routerLink]="['/sign-up']"
                        [disabled]="isLoading">
                        SIGN UP
                    </button>
                </div>

                <div class="col text-right">
                    <button mat-button class="btn button-accent" type="submit" [class.button-spinner]="isLoading"
                        [disabled]="isLoading">
                        SIGN IN
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
