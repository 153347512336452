<h4 class="dialog-title text-center">Edit Sensor</h4>

<hr />

<app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Device ID</mat-label>
                    <input matInput [(ngModel)]="sensor.id" readonly class="cursor-not-allowed"
                        title="Device ID cannot be changed." />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-broadcast-tower"></i>
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Device Name</mat-label>
                    <input matInput [(ngModel)]="sensor.name" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-tag"></i>
                    </mat-icon>
                    <mat-error> Device Name is required. </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Device Version</mat-label>
                    <mat-select [(ngModel)]="sensor.deviceVersionId" required>
                        <mat-option *ngFor="let deviceVersion of deviceVersions" [value]="deviceVersion.id">
                            {{deviceVersion.version}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-laptop-code"></i>
                    </mat-icon>
                    <mat-error>Device Version is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12 padding-y-10">
                <mat-icon class="mr-3">
                    <i class="fas fa-check-circle"></i>
                </mat-icon>
                <mat-label class="mr-3">Status</mat-label>
                <mat-slide-toggle color="primary" [(ngModel)]="sensor.status"></mat-slide-toggle>
                <hr class="field-underline" />
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Tenant</mat-label>
                    <mat-select [(ngModel)]="sensor.tenantId" required>
                        <mat-option *ngFor="let tenant of tenants" [value]="tenant.id">
                            {{tenant.name}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-building"></i>
                    </mat-icon>
                    <mat-error>Tenant is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>IMEI No</mat-label>
                    <input matInput [(ngModel)]="sensor.imeI_No" />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-barcode"></i>
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Latitude</mat-label>
                    <input type="number" matInput [(ngModel)]="sensor.latitude" min="-90" max="90" />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-map-marker-alt"></i>
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Longitude</mat-label>
                    <input type="number" matInput [(ngModel)]="sensor.longitude" min="-180" max="180" />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-map-marker-alt"></i>
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Installation Date</mat-label>
                    <input matInput [(ngModel)]="sensor.installationDate" [owlDateTime]="installDate"
                        [owlDateTimeTrigger]="installDate" class="clickable" required />
                    <mat-icon matPrefix class="clickable mr-3" [owlDateTimeTrigger]="installDate">
                        <i class="fas fa-calendar-alt"></i>
                    </mat-icon>
                    <mat-error>Installation Date is required.</mat-error>
                    <owl-date-time #installDate></owl-date-time>
                </mat-form-field>
            </div>

            <div class="col-md-12 padding-y-10">
                <mat-icon class="mr-3">
                    <i class="fas fa-location-arrow"></i>
                </mat-icon>
                <mat-label class="mr-3">GPS Capability</mat-label>
                <mat-slide-toggle color="primary" [(ngModel)]="sensor.gpsCapability"></mat-slide-toggle>
                <hr class="field-underline" />
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Table Names</mat-label>
                    <mat-select [(ngModel)]="sensor.tableNames" multiple required>
                        <mat-option *ngFor="let tableName of tableNames" [value]="tableName">
                            {{tableName}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-table"></i>
                    </mat-icon>
                    <mat-error>Table Names are required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12 padding-y-10">
                <mat-icon class="mr-3">
                    <i class="fas fa-video"></i>
                </mat-icon>
                <mat-label class="mr-3">Video Capability</mat-label>
                <mat-slide-toggle color="primary" [(ngModel)]="sensor.videoCapability"></mat-slide-toggle>
                <hr class="field-underline" />
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Density</mat-label>
                    <input type="number" matInput [(ngModel)]="sensor.density" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-braille"></i>
                    </mat-icon>
                    <mat-error>Density is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>AirNow IntlAQSCode</mat-label>
                    <input matInput [(ngModel)]="sensor.airNowIntlAQSCode" />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-wind"></i>
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12 padding-y-10">
                <mat-icon class="mr-3">
                    <i class="fas fa-cog"></i>
                </mat-icon>
                <mat-label class="mr-3">Configuration Capability</mat-label>
                <mat-slide-toggle color="primary" [(ngModel)]="sensor.configurationCapability"></mat-slide-toggle>
                <hr class="field-underline" />
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button class="btn button-primary" (click)="save()" [class.button-spinner]="isLoading"
            [disabled]="!sensor.name || !sensor.deviceVersionId || !sensor.tenantId || !sensor.installationDate || !sensor.tableNames || isLoading">
            Save
        </button>

        <button mat-button class="btn button-accent" mat-dialog-close>Cancel</button>
    </div>
</div>
