export class TenantPermissions {
    tenantId: number;

    canViewPm1_0: boolean;
    canViewPm2_5: boolean;
    canViewPm10_0: boolean;
    canViewRespirablePm: boolean;
    canViewEnvironmentalData: boolean;

    canViewPm1_0_A: boolean;
    canViewPm2_5_A: boolean;
    canViewPm10_0_A: boolean;
    canViewRespirablePm_A: boolean;
    canViewBinMass: boolean;

    canExportData: boolean;
    canExportCounts: boolean;
    canExportGeolocationData: boolean;
    canExportEnvironmentalData: boolean;

    canCreateSubusers: boolean;
    canUpdateDeviceShadow: boolean;
    canViewDataAnalysisPage: boolean;
    canViewNotifications: boolean;
    canCreateCategories: boolean;
    allowAirnowDevices: boolean;

    canViewTVOC: boolean;
    canViewCO2: boolean;
}
