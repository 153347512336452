import { Injectable } from '@angular/core';
import { EndpointService } from './endpoint.service';
import { HttpClient } from '@angular/common/http';
import { SensorRecordsRequestModel } from '../models/sensorRecordsRequest.model';
import { SensorRecord } from '../models/sensorRecord.model';
import { DateRangeModel } from '../models/dateRange.model';
import { catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { ToastService } from './toast.service';
import { SensorStatusModel } from '../models/sensorStatus.model';
import { DataAnalysisRequestModel } from '../models/dataAnalysisRequest.model';
import { SensorAnalysisRecord } from '../models/sensorAnalysisRecord.model';
import { DataAnalysisExportModel } from '../models/dataAnalysisExport.model';
import { AuthService } from './auth.service';
import { RecordDto } from '../models/recordDto.model';
import { SensorRecordChartModel } from '../models/sensorRecordChart.model';
import { EndPointStatusModel } from '../models/endPointStatus.model';

@Injectable({
    providedIn: 'root'
})
export class SensorRecordService extends EndpointService {

    constructor(http: HttpClient,
        private toastService: ToastService,
        private authService: AuthService) {
        super(http);
    }

    getLatestSensorRecordsBySensor(dateRange: DateRangeModel) {
        return this.http.post<SensorRecord[]>(this.getApiUrl('SensorRecord/GetLatestSensorRecordsBySensor'), dateRange)
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading markers.');
                    return of([]);
                })
            );
    }

    getLatestSensorRecordsByEndPoint(dateRange: DateRangeModel) {
        return this.http.post<SensorRecord[]>(this.getApiUrl('SensorRecord/GetLatestSensorRecordsByEndPoint'), dateRange)
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading markers.');
                    return of([]);
                })
            );
    }

    getSensorStatusModels() {
        return this.http.get<SensorStatusModel[]>(this.getApiUrl('SensorRecord/GetSensorStatusModels'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading sensors.');
                    return of([]);
                })
            );
    }

    getEndPointStatusModels() {
        return this.http.get<EndPointStatusModel[]>(this.getApiUrl('SensorRecord/GetEndPointStatusModels'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading endPoints.');
                    return of([]);
                })
            );
    }

    getLatestSensorRecordBySensor(sensorId: string) {
        return this.http.get<RecordDto>(this.getApiUrl(`SensorRecord/GetLatestSensorRecordBySensor?sensorId=${sensorId}`))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading latest sensor data.');
                    return of(new RecordDto());
                })
            );
    }

    getLatestSensorRecordByEndPoint(endPointId: number) {
        return this.http.get<RecordDto>(this.getApiUrl(`SensorRecord/GetLatestSensorRecordByEndPoint?endPointId=${endPointId}`))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading latest sensor data.');
                    return of(new RecordDto());
                })
            );
    }

    getParameters(payload: DataAnalysisRequestModel): Observable<SensorAnalysisRecord[]> {
        this.authService.setCurrentUserAnalysisParameters(payload);
        return this.http.post<SensorAnalysisRecord[]>(this.getApiUrl('SensorRecord/GetParameters'), payload)
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading sensor data.');
                    return of([]);
                })
            );
    }

    exportParameters(payload: DataAnalysisExportModel): Observable<Blob> {
        this.authService.setCurrentUserAnalysisParameters(payload.models[0]);
        return this.http.post(this.getApiUrl('SensorRecord/ExportParameters'), payload, { responseType: 'blob' });
    }

    getSensorRecords(payload: SensorRecordsRequestModel) {
        this.authService.setCurrentUserParameters(payload.parameters);
        return this.http.post<SensorRecordChartModel>(this.getApiUrl('SensorRecord/GetSensorRecords'), payload)
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading sensor data.');
                    return of(new SensorRecordChartModel());
                })
            );
    }
}
