import { Component, Input, ViewContainerRef, OnInit, DoCheck, ViewChild, TemplateRef } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { OverlayService } from 'src/app/services/overlay.service';
import { OverlayConfig, OverlayRef } from '@angular/cdk/overlay';

@Component({
    selector: 'app-progress-spinner',
    templateUrl: './progressSpinner.component.html'
})
export class ProgressSpinnerComponent implements OnInit, DoCheck {

    @Input() color?: ThemePalette;
    @Input() diameter = 100;
    @Input() mode?: ProgressSpinnerMode = 'indeterminate';
    @Input() strokeWidth?: number;
    @Input() value?: number;
    @Input() backdropEnabled = true;
    @Input() positionGloballyCenter = true;
    @Input() show: boolean;

    @ViewChild('progressSpinnerRef', { static: true })
    private progressSpinnerRef: TemplateRef<any>;
    private overlayRef: OverlayRef;

    constructor(private overlayService: OverlayService,
        private vcRef: ViewContainerRef) {
    }

    ngOnInit() {
        const overlayConfig: OverlayConfig = {
            hasBackdrop: this.backdropEnabled
        };

        if (this.positionGloballyCenter) {
            overlayConfig.positionStrategy = this.overlayService.positionGloballyCenter();
        }

        this.overlayRef = this.overlayService.createOverlay(overlayConfig);
    }

    ngDoCheck() {
        if (this.show && !this.overlayRef.hasAttached()) {
            this.overlayService.attachTemplatePortal(this.overlayRef, this.progressSpinnerRef, this.vcRef);
        } else if (!this.show && this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        }
    }
}
