import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/services/toast.service';
import { Enum } from 'src/app/models/enum.model';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';

export interface CreateUserDialogData {
    firstName: string;
    lastName: string;
    createAccount: boolean;
    username: string;
    securityProfileId: number;
}

@Component({
    selector: 'app-create-user-dialog',
    templateUrl: 'createUserDialog.component.html',
})
export class CreateUserDialogComponent extends TrackableComponent implements OnInit, OnDestroy {

    isFormLoading = false;
    isLoading = false;
    securityProfiles: Enum[];
    createAccountInfo = 'If selected, enter user\'s email address as Username.';

    constructor(public dialogRef: MatDialogRef<CreateUserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CreateUserDialogData,
        private userService: UserService,
        private toastService: ToastService,
        featureUsageSevice: FeatureUsageService) {

        super(featureUsageSevice, FeatureUsagePageEnum.CreateUserDialog);
    }

    ngOnInit() {
        this.isFormLoading = true;
        this.securityProfiles = [];
        this.userService.getSecurityProfileLookupList()
            .subscribe(
                result => {
                    this.securityProfiles = result;
                    this.isFormLoading = false;
                },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isFormLoading = false;
                }
            );
    }

    ngOnDestroy() {
        this.recordActivity();
    }

    create() {
        this.isLoading = true;
        this.userService.createUser(this.data)
            .subscribe((response: any) => {
                this.isLoading = false;
                this.toastService.showSuccess('User added successfully.');
                this.dialogRef.close(true);
            }, error => {
                this.isLoading = false;
                console.log(error);
                this.toastService.showErrors(error, ['FirstName', 'LastName', 'Username', 'SecurityProfileId']);
            });

        this.recordAction(FeatureUsageActionEnum.AddUserClick);
    }

    onClose() {
        this.recordAction(FeatureUsageActionEnum.CancelAddUserClick);
    }
}
