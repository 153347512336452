<h4 class="dialog-title text-center">Edit Sensor</h4>

<hr />

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Device ID</mat-label>
                    <input matInput [(ngModel)]="data.id" readonly class="cursor-not-allowed"
                        title="Device ID cannot be changed." />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-broadcast-tower"></i>
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Device Name</mat-label>
                    <input matInput [(ngModel)]="data.name" required />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-tag"></i>
                    </mat-icon>
                    <mat-error>Device Name is required.</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button class="btn button-primary" (click)="save()" [class.button-spinner]="isLoading"
            [disabled]="!data.name || isLoading">Save</button>

        <button mat-button class="btn button-accent" mat-dialog-close (click)="onClose()">Cancel</button>
    </div>
</div>
