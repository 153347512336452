<div class="nav-left">
    <ul>
        <li>
            <a (click)="toggleSideMenu()" title="Menu">
                <mat-icon class="app-menu-icon">
                    <i class="fas fa-bars fa-sm"></i>
                </mat-icon>
            </a>
            <hr class="app-menu-h-rule" />
        </li>

        <li *ngIf="isAdmin || isSuperAdmin || isUser">
            <a (click)="navigateTo('/dashboard', FeatureUsageActionEnum.SidebarDashboardClick)" title="Dashboard">
                <mat-icon class="side-menu-icon-mat">
                    <i class="material-icons side-menu-icon">dashboard</i>
                </mat-icon>
                <span class="ml-3">Dashboard</span>
            </a>
        </li>

        <li *ngIf="isAdmin">
            <a (click)="navigateTo('/configuration', FeatureUsageActionEnum.SidebarSensorAssignmentsClick)" title="Sensor Assignments">
                <mat-icon class="mx-auto">
                    <i class="fas fa-user-cog fa-sm"></i>
                </mat-icon>
                <span class="ml-3">Sensor Assignments</span>
            </a>
        </li>

        <li *ngIf="isAdmin">
            <a (click)="navigateTo('/end-points', FeatureUsageActionEnum.SidebarEndPointsClick)" title="End Points">
                <mat-icon class="mx-auto">
                    <i class="fas fa-desktop fa-sm side-menu-icon"></i>
                </mat-icon>
                <span class="ml-3">End Points</span>
            </a>
        </li>

        <li *ngIf="isAdmin && canCreateSubusers">
            <a (click)="navigateTo('/user', FeatureUsageActionEnum.SidebarUsersClick)" title="Users">
                <mat-icon class="mx-auto">
                    <i class="fas fa-users fa-sm side-menu-icon"></i>
                </mat-icon>
                <span class="ml-3">Users</span>
            </a>
        </li>

        <li *ngIf="isAdmin">
            <a (click)="navigateTo('/sensorlist', FeatureUsageActionEnum.SidebarSensorsClick)" title="Sensors">
                <mat-icon class="mx-auto">
                    <i class="fas fa-broadcast-tower fa-sm side-menu-icon"></i>
                </mat-icon>
                <span class="ml-3">Sensors</span>
            </a>
        </li>

        <li *ngIf="isAdmin && canViewNotifications">
            <a (click)="navigateTo('/notification-settings', FeatureUsageActionEnum.SidebarNotificationSettingsClick)" title="Notification Settings">
                <mat-icon class="mx-auto">
                    <i class="fas fa-bell fa-sm side-menu-icon"></i>
                </mat-icon>
                <span class="ml-3">Notification Settings</span>
            </a>
        </li>

        <li *ngIf="isSuperAdmin">
            <a [routerLink]="['/tenantSensorList']" routerLinkActive="active" title="Sensors">
                <mat-icon class="mx-auto">
                    <i class="fas fa-broadcast-tower fa-sm side-menu-icon"></i>
                </mat-icon>
                <span class="ml-3">Sensors</span>
            </a>
        </li>

        <li *ngIf="(isAdmin || isSuperAdmin) && canViewDataAnalysisPage">
            <a (click)="navigateTo('/dataAnalysis', FeatureUsageActionEnum.SidebarDataAnalysisClick)" title="Data Analysis">
                <mat-icon class="mx-auto">
                    <i class="fas fa-chart-bar fa-sm side-menu-icon"></i>
                </mat-icon>
                <span class="ml-3">Data Analysis</span>
            </a>
        </li>

        <li *ngIf="isSuperAdmin">
            <a type [routerLink]="['/userList']" routerLinkActive="active" title="Pre-Signup Users">
                <mat-icon class="mx-auto">
                    <i class="fas fa-users fa-sm side-menu-icon"></i>
                </mat-icon>
                <span class="ml-3">Pre-Signup Users</span>
            </a>
        </li>

        <li *ngIf="isSuperAdmin">
            <a type [routerLink]="['/tenants']" routerLinkActive="active" title="Tenants">
                <mat-icon class="mx-auto">
                    <i class="fas fa-building"></i>
                </mat-icon>
                <span class="ml-3">Tenants</span>
            </a>
        </li>

        <li *ngIf="isSuperAdmin">
            <a type [routerLink]="['/deviceVersionList']" routerLinkActive="active" title="Device Versions">
                <mat-icon class="mx-auto">
                    <i class="fas fa-laptop-code fa-sm side-menu-icon"></i>
                </mat-icon>
                <span class="ml-3">Device Versions</span>
            </a>
        </li>

        <li *ngIf="isSuperAdmin">
            <a type [routerLink]="['/shadowConstraintList']" routerLinkActive="active" title="Shadow Constraints">
                <mat-icon class="mx-auto">
                    <i class="fas fa-pencil-ruler fa-sm"></i>
                </mat-icon>
                <span class="ml-3">Shadow Constraints</span>
            </a>
        </li>
    </ul>
</div>
