<body class="dashboard dashboard-bg">
    <app-header></app-header>

    <div class="body-container collapsed">
        <app-sidebar></app-sidebar>

        <div class="body-content">
            <div class="row">
                <div class="col p-0">
                    <app-page-header title="Tenants" iconClass="fas fa-building"></app-page-header>
                </div>
            </div>

            <app-progress-spinner [show]="isLoading || (dataSource.isLoading$ | async)"></app-progress-spinner>

            <div class="white-box">
                <mat-form-field>
                    <mat-label>Search</mat-label>
                    <input matInput #searchField />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-search"></i>
                    </mat-icon>
                </mat-form-field>

                <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc"
                    matSortDisableClear>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                        <td mat-cell *matCellDef="let tenant">
                            <div class="grid-link" (click)="showUsers(tenant)">
                                {{tenant.name}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                        <td mat-cell *matCellDef="let tenant">
                            <mat-icon *ngIf="tenant.status" title="Enabled">
                                <i class="fas fa-check-circle color-secondary"></i>
                            </mat-icon>

                            <mat-icon *ngIf="!tenant.status" title="Disabled">
                                <i class="fas fa-minus-circle color-accent"></i>
                            </mat-icon>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="dateCreated">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Created </th>
                        <td mat-cell *matCellDef="let tenant"> {{tenant.dateCreated | date:"medium" }} </td>
                    </ng-container>

                    <ng-container matColumnDef="dateUpdated">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Updated </th>
                        <td mat-cell *matCellDef="let tenant"> {{tenant.dateUpdated | date:"medium" }} </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th class="action-column" mat-header-cell *matHeaderCellDef> Action </th>
                        <td class="action-column" mat-cell *matCellDef="let tenant">
                            <mat-icon class="action-icon" title="Edit" (click)="editPermissions(tenant)">
                                <i class="fas fa-edit"></i>
                            </mat-icon>

                            <mat-icon class="action-icon" title="Edit Logo" (click)="editLogo(tenant)">
                                <i class="fas fa-image"></i>
                            </mat-icon>

                            <mat-icon class="action-icon" *ngIf="tenant.status" title="Disable"
                                (click)="changeStatus(tenant, false)">
                                <i class="fas fa-toggle-on"></i>
                            </mat-icon>

                            <mat-icon class="action-icon" *ngIf="!tenant.status" title="Enable"
                                (click)="changeStatus(tenant, true)">
                                <i class="fas fa-toggle-off"></i>
                            </mat-icon>

                            <mat-icon class="action-icon color-accent" title="Delete" (click)="delete(tenant)">
                                <i class="fas fa-trash"></i>
                            </mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; let even = even; columns: displayedColumns;"
                        [class.even-row]="even"></tr>
                </table>

                <mat-paginator [length]="dataSource.count$ | async" [pageSizeOptions]="[5, 10, 20]" [pageSize]="10"
                    showFirstLastButtons></mat-paginator>
            </div>

            <div class="row">
                <div class="col-lg-3 offset-lg-9">
                    <mat-form-field>
                        <mat-label>Time Zone</mat-label>
                        <input matInput [(ngModel)]="timeZoneLabel" readonly />
                        <mat-icon matPrefix class="mr-3">
                            <i class="fas fa-globe"></i>
                        </mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div class="clearfix"></div>
            <app-footer></app-footer>
        </div>

        <div class="clearfix"></div>
    </div>
</body>
