export enum TimeIntervalEnum {
    None = 0,
    Sliding15Minute = 1,
    Sliding4Hour = 2,
    Sliding8Hour = 3,
    Daily = 4,
    DailyShift = 5,
    Sliding1Hour = 6,
    Hourly = 7,
}
