import { ComponentType } from '@angular/cdk/portal';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorCodeEnum } from 'src/app/enums/errorCode.enum';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { ErrorLog } from 'src/app/models/errorLog.model';
import { FeatureUsageAction } from 'src/app/models/featureUsageAction.model';
import { AuthService } from 'src/app/services';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { TimeZoneService } from 'src/app/services/timeZone.service';
import { ToastService } from 'src/app/services/toast.service';
import { ITrackableComponent } from '../featureUsage/trackableComponent.interface';
import { GridBaseComponent } from './grid.component';

@Component({
    selector: 'app-trackable-grid-base',
    template: '<div>TrackableGridBaseComponent</div>'
})
export class TrackableGridBaseComponent<T> extends GridBaseComponent<T> implements ITrackableComponent, OnInit, AfterViewInit {
    private actions: FeatureUsageAction[] = [];
    private errors: ErrorLog[] = [];
    private timestampStart: number;

    constructor(toastService: ToastService,
        authService: AuthService,
        timeZoneService: TimeZoneService,
        dialog: MatDialog,
        private featureUsageService: FeatureUsageService,
        private page: FeatureUsagePageEnum
    ) {
        super(toastService, authService, timeZoneService, dialog);
        this.timestampStart = (new Date()).getTime();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    openDialog<T1>(dialogComponent: ComponentType<T1>, data: any, reloadAction?: (val: any) => any, action?: FeatureUsageActionEnum) {
        super.openDialog(dialogComponent, data, reloadAction);
        this.recordAction(action);
    }

    openTrackableDeleteDialog<T1>(dialogComponent: ComponentType<T1>, data: any, reloadAction?: (val?: any) => any, action?: FeatureUsageActionEnum,
        deleteAction?: FeatureUsageActionEnum, cancelDeleteAction?: FeatureUsageActionEnum) {
        super.openDeleteDialog(dialogComponent, data, reloadAction, () => this.recordAction(deleteAction), () => this.recordAction(cancelDeleteAction));
        this.recordAction(action);
    }

    public recordAction(action: FeatureUsageActionEnum, ipAddress: string = null) {
        this.actions.push(new FeatureUsageAction(action, ipAddress));
    }

    public recordError(errorCode: ErrorCodeEnum, message: string) {
        this.errors.push(new ErrorLog(errorCode, message));
    }

    public recordActivity() {
        this.featureUsageService.recordActivity(this.page, this.timestampStart, this.actions, this.errors)
            .subscribe();

        this.timestampStart = (new Date()).getTime();
        this.actions = [];
        this.errors = [];
    }
}
