import { FeatureUsageActionEnum } from '../enums/featureUsageAction.enum';

export class FeatureUsageAction {
    timestamp: number;

    constructor(public action: FeatureUsageActionEnum,
        public ipAddress: string) {
        this.timestamp = (new Date()).getTime();
    }
}
