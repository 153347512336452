import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
import { IGridService } from './grid.service';
import { finalize, catchError } from 'rxjs/operators';
import { GridModel } from './grid.model';
import { HttpHeaders } from '@angular/common/http';

export class GridDataSource<T> implements DataSource<T> {

    private entitySubject = new BehaviorSubject<T[]>([]);
    private countSubject = new BehaviorSubject<number>(0);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public count$ = this.countSubject.asObservable();
    public isLoading$ = this.loadingSubject.asObservable();

    constructor(private dataSourceService: IGridService<T>,
        private toastService: ToastService) { }

    connect(collectionViewer: CollectionViewer): Observable<T[]> {
        return this.entitySubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.entitySubject.complete();
        this.countSubject.complete();
        this.loadingSubject.complete();
    }

    load(filter = '', orderBy = '', sort = 'desc', page = 0, pageSize = 10, coId?: number) {
        this.loadingSubject.next(true);
        const headers = coId ? new HttpHeaders().set('coId', coId.toString()) : undefined;
        this.dataSourceService.get(filter, orderBy, sort, page, pageSize, headers)
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading data.');
                    return of(new GridModel<T>([], 0));
                }),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(
                dataSourceModel => {
                    this.entitySubject.next(dataSourceModel.data);
                    this.countSubject.next(dataSourceModel.count);
                });
    }
}
