import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { TenantService } from 'src/app/services/tenant.service';
import { MatDialog } from '@angular/material/dialog';
import { Tenant } from 'src/app/models/tenant.model';
import { ConfirmDialogComponent } from 'src/app/shared/confirmDialog/confirmDialog.component';
import { TenantUsersDialogComponent } from '../tenantUsersDialog/tenantUsersDialog.component';
import { EditTenantPermissionsDialogComponent } from '../editTenantPermissionsDialog/editTenantPermissionsDialog.component';
import { UploadLogoDialogComponent } from '../uploadLogoDialog/uploadLogoDialog.component';
import { AuthService } from 'src/app/services';
import { TimeZoneService } from 'src/app/services/timeZone.service';
import { GridBaseComponent } from 'src/app/shared/grid/grid.component';

@Component({
    selector: 'app-tenant-list',
    templateUrl: './tenantList.component.html',
    styleUrls: ['./tenantList.component.css']
})
export class TenantListComponent extends GridBaseComponent<Tenant> implements OnInit, AfterViewInit {

    constructor(protected tenantService: TenantService,
        toastService: ToastService,
        authService: AuthService,
        timeZoneService: TimeZoneService,
        dialog: MatDialog
    ) {
        super(toastService, authService, timeZoneService, dialog);
        this.gridService = tenantService;
        this.displayedColumns = ['name', 'status', 'dateCreated', 'dateUpdated', 'action'];
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    showUsers(tenant: Tenant) {
        this.openDialog(TenantUsersDialogComponent, {
            tenantId: tenant.id,
            tenantName: tenant.name
        });
    }

    editPermissions(tenant: Tenant) {
        this.openDialog(EditTenantPermissionsDialogComponent, { tenantId: tenant.id });
    }

    editLogo(tenant: Tenant) {
        this.openDialog(UploadLogoDialogComponent, { id: tenant.id });
    }

    changeStatus(tenant: Tenant, status: boolean) {
        this.isLoading = true;
        this.tenantService.changeStatus(tenant.id, status)
            .subscribe(
                response => {
                    this.isLoading = false;
                    this.loadPage();
                },
                error => {
                    console.log(error);
                    this.isLoading = false;
                    this.toastService.displayErrorMessage('Error changing tenant status.');
                }
            );
    }

    delete(tenant: Tenant) {
        this.openDeleteDialog(
            ConfirmDialogComponent,
            {
                title: 'Delete Tenant',
                message: `Are you sure you want to delete tenant ${tenant.name}?`,
                primaryButtonText: 'Delete',
                primaryCallback: () => this.tenantService.deleteTenant(tenant.id),
                successMessage: 'Tenant deleted successfully!',
                secondaryButtonText: 'Cancel',
                errorMessage: 'Not implemented!'
            }
        );
    }
}
