<h4 class="dialog-title text-center">Add Sensor Assignment</h4>

<hr />

<app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-9">
                <mat-form-field>
                    <mat-label>End Point</mat-label>
                    <mat-select [(ngModel)]="data.endPointId" required>
                        <mat-option *ngFor="let endPoint of endPoints" [value]="endPoint.id">
                            {{endPoint.name}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-desktop"></i>
                    </mat-icon>
                    <mat-error>
                        End Point is required.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-3 padding-y-10 row-icon clickable" (click)="addEndpoint()">
                <mat-icon class="mr-3 color-secondary">
                    <i class="fas fa-plus"></i>
                </mat-icon>
                <mat-label>Add</mat-label>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Sensor</mat-label>
                    <mat-select [(ngModel)]="data.sensorId" required>
                        <mat-option *ngFor="let sensor of sensors" [value]="sensor.id">
                            {{sensor.name}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-broadcast-tower"></i>
                    </mat-icon>
                    <mat-error>
                        Sensor is required.
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>From Date</mat-label>
                    <input matInput [(ngModel)]="data.fromDate" class="clickable" [owlDateTime]="dtstart"
                        [owlDateTimeTrigger]="dtstart" required />
                    <mat-icon class="clickable mr-3" matPrefix [owlDateTimeTrigger]="dtstart">
                        <i class="fas fa-calendar-alt"></i>
                    </mat-icon>
                    <mat-error>
                        From Date is required.
                    </mat-error>
                    <owl-date-time #dtstart></owl-date-time>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>To Date</mat-label>
                    <input matInput [(ngModel)]="data.toDate" class="clickable" [owlDateTime]="dtend"
                        [owlDateTimeTrigger]="dtend" required />
                    <mat-icon class="clickable mr-3" matPrefix [owlDateTimeTrigger]="dtend">
                        <i class="fas fa-calendar-alt"></i>
                    </mat-icon>
                    <mat-error>
                        To Date is required.
                    </mat-error>
                    <owl-date-time #dtend></owl-date-time>
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Time Zone</mat-label>
                    <input matInput [(ngModel)]="timeZone" readonly />
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-globe"></i>
                    </mat-icon>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button class="btn button-primary" mat-button (click)="create()" [class.button-spinner]="isLoading"
            [disabled]="!data.sensorId || !data.endPointId || !data.fromDate || !data.toDate || isLoading">
            Add
        </button>

        <button class="btn button-accent" mat-button mat-dialog-close (click)="onClose()">Cancel</button>
    </div>
</div>
