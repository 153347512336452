import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserProfile } from 'src/app/models/userProfile.model';
import { TimeZoneModel } from 'src/app/models/timezone.model';
import { ToastService } from '../../services/toast.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/services';
import { SecurityProfileEnum } from 'src/app/enums/securityProfile.enum';
import { Enum } from 'src/app/models/enum.model';
import { TemperatureUnitEnum } from 'src/app/enums/temperatureUnit.enum';
import { PmUnitEnum } from 'src/app/enums/pmUnit.enum';
import { UserPreferences } from 'src/app/models/userPreferences.model';
import { FeatureUsageService } from 'src/app/services/featureUsage.service';
import { FeatureUsageActionEnum } from 'src/app/enums/featureUsageAction.enum';
import { TrackableComponent } from 'src/app/shared/featureUsage/trackable.component';
import { FeatureUsagePageEnum } from 'src/app/enums/featureUsagePage.enum';
import { NotificationPreferencesEnum } from 'src/app/enums/notificationPreferences.enum';
import { AccountService } from 'src/app/services/account.service';
import { MatDialog } from '@angular/material/dialog';
import { MfaDialogComponent } from '../mfaDialog/mfaDialog.component';

@Component({
    selector: 'app-updateuserprofile',
    templateUrl: './updateuserprofile.component.html',
    styleUrls: ['./updateuserprofile.component.css']
})
export class UpdateuserprofileComponent extends TrackableComponent implements OnInit, OnDestroy {

    isFormLoading: boolean;
    isLoading: boolean;
    timezones: TimeZoneModel[];
    user: UserProfile;
    userType: number;
    now: number;
    zone: string;
    temperatureUnits: Enum[];
    pmUnits: Enum[];
    intervalId: any;
    notificationPreferences: Enum[];

    constructor(private userService: UserService,
        private authService: AuthService,
        private accountService: AccountService,
        private router: Router,
        private dialog: MatDialog,
        private toastService: ToastService,
        featureUsageService: FeatureUsageService) {

        super(featureUsageService, FeatureUsagePageEnum.EditProfilePage);

        this.isFormLoading = true;
        this.user = new UserProfile();
        this.timezones = [];
        this.zone = 'UTC';
        this.intervalId = setInterval(() => {
            this.now = Date.now();
        }, 60);
        this.temperatureUnits = [
            new Enum(TemperatureUnitEnum.Celsius, '°C'),
            new Enum(TemperatureUnitEnum.Fahrenheit, '°F')
        ];
        this.pmUnits = [
            new Enum(PmUnitEnum.UgPerM3, 'µg/m³'),
            new Enum(PmUnitEnum.MgPerM3, 'mg/m³')
        ];
    }

    ngOnInit() {
        forkJoin({
            timezones: this.userService.getAllTimeZones(),
            user: this.userService.getCurrentUserProfile(),
            notificationPreferences: this.userService.getNotificationPreferences()
        }).subscribe(
            result => {
                this.timezones = result.timezones;
                this.notificationPreferences = result.notificationPreferences.filter(x => x.id === NotificationPreferencesEnum.Email); // TODO: show SMS
                this.user = result.user;
                this.onTimezoneChange(this.user.timeZoneId);
                this.isFormLoading = false;
            },
            error => {
                console.log(error);
                this.toastService.showError(error);
                this.isFormLoading = false;
            }
        );

        this.userType = this.authService.getCurrentUser().userType;
    }

    ngOnDestroy() {
        clearInterval(this.intervalId);

        this.recordActivity();
    }

    get isUser() { return this.userType === SecurityProfileEnum.User; }

    onTimezoneChange(timezoneId: number) {
        if (timezoneId && this.timezones && this.timezones.length > 0) {
            const id = Number(timezoneId);
            const timezone = this.timezones.find(tz => tz.id === id);
            if (id && timezone) {
                this.zone = timezone.utcOffset;
            }
        }
    }

    isPhoneNumberRequired(preferences: number[]): boolean {
        return preferences && preferences.includes(NotificationPreferencesEnum.SMS);
    }

    cancel() {
        this.recordAction(FeatureUsageActionEnum.EditProfileCancelClick);
        this.router.navigate(['/dashboard']);
    }

    save() {
        this.isLoading = true;
        this.userService.updateCurrentUserProfile(this.user)
            .subscribe(response => {
                this.toastService.showSuccess('Profile saved successfully.');
                const preferences = new UserPreferences();
                preferences.pmUnitId = this.user.pmUnitId;
                preferences.temperatureUnitId = this.user.temperatureUnitId;
                preferences.showMap = this.user.showMap;
                preferences.defaultMapLocation = this.user.defaultMapLocation;
                this.authService.setCurrentUserPreferences(preferences);
                const timeZone = this.timezones.find(tz => tz.id === this.user.timeZoneId);
                if (timeZone) {
                    this.authService.setCurrentUserTimeZone(timeZone.name);
                }
                this.isLoading = false;
            },
            error => {
                console.log(error);
                this.toastService.displayErrorMessage('Error updating profile.');
                this.isLoading = false;
            });

        this.recordAction(FeatureUsageActionEnum.EditProfileSaveClick);
    }

    toggleMfa(event: any) {
        this.isLoading = true;
        this.accountService.enableMfa(this.user.twoFactorEnabled)
            .subscribe(response => {
                if (!this.user.twoFactorEnabled) {
                    this.toastService.showSuccess('MFA disabled successfully.');
                } else {
                    const dialogRef = this.dialog.open(MfaDialogComponent, {
                        data: {
                            username: null,
                            isLogin: false
                        },
                        width: '450px',
                        disableClose: true
                    });
            
                    dialogRef.afterClosed()
                        .subscribe((result: any) => {
                            if (result) {
                                this.toastService.showSuccess('MFA enabled successfully.');
                            }
                        });
                }
                this.isLoading = false;
            },
            error => {
                console.log(error);
                this.toastService.displayErrorMessage('Error enabling MFA.');
                this.isLoading = false;
            });
    }
}
