import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/toast.service';
import { TenantPermissions } from 'src/app/models/tenantPermissions.model';
import { TenantPermissionsService } from 'src/app/services/tenantPermissions.service';

export interface EditTenantPermissionsDialogData {
    tenantId: number;
}

@Component({
    selector: 'app-edit-tenant-permissions-dialog',
    templateUrl: 'editTenantPermissionsDialog.component.html'
})
export class EditTenantPermissionsDialogComponent implements OnInit {

    isFormLoading = false;
    isLoading = false;
    tenantPermissions: TenantPermissions;

    constructor(public dialogRef: MatDialogRef<EditTenantPermissionsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EditTenantPermissionsDialogData,
        private tenantPermissionsService: TenantPermissionsService,
        private toastService: ToastService) {
        this.tenantPermissions = new TenantPermissions();
    }

    ngOnInit() {
        this.isFormLoading = true;
        this.tenantPermissionsService.getTenantPermissions(this.data.tenantId)
            .subscribe(
                tenantPermissions => {
                    this.tenantPermissions = tenantPermissions;
                    this.isFormLoading = false;
                },
                error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading tenant permissions.');
                    this.isFormLoading = false;
                });
    }

    save() {
        this.isLoading = true;
        this.tenantPermissionsService.saveTenantPermissions(this.tenantPermissions)
            .subscribe(response => {
                this.toastService.showSuccess('Tenant permissions saved successfully.');
                this.isLoading = false;
                this.dialogRef.close();
            },
                error => {
                    console.log(error);
                    this.toastService.showError(error);
                    this.isLoading = false;
                }
            );
    }
}
