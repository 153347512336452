export enum ErrorCodeEnum {
    None = 0,

    Error = 1,
    Validation = 2,
    SaveUserLastSeenNotificationDate = 3,
    GetDeviceShadow = 4,
    SendNewSignupEmail = 5,
    SendNewUserEmail = 6,
    Login = 7,
    RecordUserActivity = 8,
    ImpersonateUser = 9,
    RemoveRefreshToken = 10,
    Logout = 11,
    RefreshToken = 12,
    RevokeTokens = 13,
    GetGridResponse = 14,
    GetCurrentUserDateTimeZone = 15,
    GetUserTimeZone = 16,
    CreateUserFromSignup = 17,
    SafeDeleteTenant = 18,
    CreateUser = 19,
    DeleteUser = 20,
}
