<body class="dashboard dashboard-bg">
    <app-header></app-header>

    <div class="body-container collapsed">
        <app-sidebar></app-sidebar>

        <div class="body-content">
            <div class="row">
                <div class="col p-0">
                    <app-page-header title="Change password" iconClass="fas fa-user-lock"></app-page-header>
                </div>
            </div>

            <app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

            <div class="white-box" *ngIf="!isFormLoading">
                <form [mustMatch]="['newPassword', 'confirmPassword']" autocomplete="off">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-8">
                            <mat-form-field>
                                <mat-label>Old Password</mat-label>
                                <input matInput [(ngModel)]="userInfo.oldPassword" [type]="show ? 'text' : 'password'"
                                    required #oldPassword="ngModel" name="oldPassword" />
                                <mat-icon class="mr-3" matPrefix>
                                    <i class="fas fa-lock"></i>
                                </mat-icon>
                                <button mat-icon-button matSuffix (click)="show = !show">
                                    <mat-icon>{{show ? 'visibility' : 'visibility_off'}}</mat-icon>
                                </button>
                                <mat-error> Old Password is required. </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-8">
                            <mat-label>Please select at least one lower, one upper, one digit, one
                                special and minimum 6 and maximum 15 characters.</mat-label>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-8">
                            <mat-form-field>
                                <mat-label>New Password</mat-label>
                                <input matInput [(ngModel)]="userInfo.newPassword" [type]="show1 ? 'text' : 'password'"
                                    pattern="((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15})" required #newPassword="ngModel"
                                    name="newPassword" />
                                <mat-icon class="mr-3" matPrefix>
                                    <i class="fas fa-lock"></i>
                                </mat-icon>
                                <button mat-icon-button matSuffix (click)="show1 = !show1">
                                    <mat-icon>{{show1 ? 'visibility' : 'visibility_off'}}</mat-icon>
                                </button>
                                <mat-error *ngIf="newPassword.errors?.required"> New Password is required. </mat-error>
                                <mat-error *ngIf="newPassword.errors?.pattern"> New Password is invalid. </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-8">
                            <mat-form-field>
                                <mat-label>Confirm Password</mat-label>
                                <input matInput [(ngModel)]="userInfo.confirmPassword" #confirmPassword="ngModel"
                                    required [type]="show2 ? 'text' : 'password'" name="confirmPassword" />
                                <mat-icon class="mr-3" matPrefix>
                                    <i class="fas fa-lock"></i>
                                </mat-icon>
                                <button mat-icon-button matSuffix (click)="show2 =! show2">
                                    <mat-icon>{{show2 ? 'visibility' : 'visibility_off'}}</mat-icon>
                                </button>
                                <mat-error *ngIf="confirmPassword.errors?.required">
                                    Confirm Password is required.
                                </mat-error>
                                <mat-error *ngIf="confirmPassword.errors?.mustMatch">
                                    Passwords do not match.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </form>

                <div class="row mt-4">
                    <div class="col" align="end">
                        <button class="btn button-primary" mat-button (click)="save()"
                            [class.button-spinner]="isLoading"
                            [disabled]="oldPassword.invalid || newPassword.invalid || confirmPassword.invalid || isLoading">
                            SAVE
                        </button>

                        <button class="btn button-accent ml-2" mat-button (click)="cancel()">CANCEL</button>
                    </div>
                </div>
            </div>

            <div class="clearfix"></div>
            <app-footer> </app-footer>
        </div>

        <div class="clearfix"></div>
    </div>
</body>
