import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserProfile } from '../models/userProfile.model';
import { EndpointService } from './endpoint.service';
import { UserImpersonationModel } from '../models/userImpersonation.model';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { TimeZoneModel } from '../models/timezone.model';
import { UserUpdateModel } from '../models/userUpdateModel';
import { Enum } from '../models/enum.model';
import { IGridService } from '../shared/grid/grid.service';
import { User } from '../models/user.model';
import { GridModel } from '../shared/grid/grid.model';
import { UserLookup } from '../models/userLookup.model';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root'
})
export class UserService extends EndpointService implements IGridService<User> {

    constructor(http: HttpClient,
        private toastService: ToastService) {
        super(http);
    }

    get(filter: string, orderBy: string, sort: string, page: number, pageSize: number) {
        return this.http.get<GridModel<User>>(this.getApiUrl('User/GetUsers'), {
            params: this.createParams(filter, orderBy, sort, page, pageSize)
        });
    }

    deleteUser(id: string) {
        return this.http.delete(this.getApiUrl(`User/Delete?id=${id}`));
    }

    createUser(user: any) {
        return this.http.post(this.getApiUrl('User/CreateUser'), user);
    }

    getUser(userId: string) {
        return this.http.get<UserUpdateModel>(this.getApiUrl(`User/GetUser?userId=${userId}`))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading user.');
                    return of(new UserUpdateModel());
                })
            );
    }

    updateUser(user: UserUpdateModel) {
        return this.http.put(this.getApiUrl('User/UpdateUser'), user);
    }

    getUserLookupList() {
        return this.http.get<UserLookup[]>(this.getApiUrl('User/GetUserLookupList'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading users.');
                    return of([]);
                })
            );
    }

    getUserName(userId: string) {
        return this.http.get<string>(this.getApiUrl(`User/GetUserName?userId=${userId}`))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading username.');
                    return of('N/A');
                })
            );
    }

    getSecurityProfileLookupList() {
        return this.http.get<Enum[]>(this.getApiUrl('User/GetSecurityProfileLookupList'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading security profiles.');
                    return of([]);
                })
            );
    }

    getAllTimeZones() {
        return this.http.get<TimeZoneModel[]>(this.getApiUrl('User/GetAllTimeZones'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading time zones.');
                    return of([]);
                })
            );
    }

    getCurrentUserProfile() {
        return this.http.get<UserProfile>(this.getApiUrl('User/GetCurrentUserProfile'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading user profile.');
                    return of(new UserProfile());
                })
            );
    }

    updateCurrentUserProfile(user: UserProfile) {
        return this.http.put(this.getApiUrl('User/UpdateCurrentUserProfile'), user);
    }

    getUserImpersonationList(id: number) {
        return this.http.get<UserImpersonationModel[]>(this.getApiUrl(`User/GetUserImpersonationList?tenantId=${id}`));
    }

    getNotificationPreferences() {
        return this.http.get<Enum[]>(this.getApiUrl('User/GetNotificationPreferences'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading notification preferences.');
                    return of([]);
                })
            );
    }
}
