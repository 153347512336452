import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EndpointService } from './endpoint.service';
import { TenantLookup } from '../models/tenantLookup.model';
import { IGridService } from '../shared/grid/grid.service';
import { GridModel } from '../shared/grid/grid.model';
import { Tenant } from '../models/tenant.model';
import { catchError } from 'rxjs/operators';
import { ToastService } from './toast.service';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TenantService extends EndpointService implements IGridService<Tenant> {

    constructor(http: HttpClient,
        private toastService: ToastService) {
        super(http);
    }

    get(filter: string, orderBy: string, sort: string, page: number, pageSize: number) {
        return this.http.get<GridModel<Tenant>>(this.getApiUrl('Tenant/GetTenants'), {
            params: this.createParams(filter, orderBy, sort, page, pageSize)
        });
    }

    changeStatus(id: number, status: boolean) {
        return this.http.put(this.getApiUrl('Tenant/ChangeStatus'), { id: id, status: status });
    }

    deleteTenant(id: number) {
        return this.http.delete(this.getApiUrl(`Tenant/Delete?id=${id}`));
    }

    getTenantLookupList() {
        return this.http.get<TenantLookup[]>(this.getApiUrl('Tenant/GetTenantLookupList'))
            .pipe(
                catchError(error => {
                    console.log(error);
                    this.toastService.displayErrorMessage('Error loading tenants.');
                    return of([]);
                })
            );
    }

    getTenantLogoUrl(id: number) {
        return this.http.get<string>(this.getApiUrl(`Tenant/GetTenantLogoUrl?id=${id}`));
    }

    uploadLogoImage(id: number, logo: File) {
        const formData = new FormData();
        formData.append('logo', logo);
        return this.http.post(this.getApiUrl(`Tenant/UploadLogoImage?id=${id}`), formData);
    }
}
