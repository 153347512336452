import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { PreSignupUserListComponent } from './components/preSignup/preSignupUserList.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UserListComponent } from './components/aspnetuserlist/aspnetuserlist.component';
import { ForgetpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { AuthGuard } from 'src/app/_guards';
import { SensorComponent } from './components/sensor/sensor.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { UpdateUserPasswordComponent } from './components/updateuserpassword/updateuserpassword.component';
import { UpdateuserprofileComponent } from './components/updateuserprofile/updateuserprofile.component';
import { ChangeuserpasswordComponent } from './components/changeuserpassword/changeuserpassword.component';
import { EndPointViewComponent } from './components/endPointView/endPointView.component';
import { TenantListComponent } from './components/tenantList/tenantList.component';
import { SensorListComponent } from './components/sensorList/sensorList.component';
import { TenantSensorListComponent } from './components/tenantSensorList/tenantSensorList.component';
import { AdminGuard } from './_guards/admin.guard';
import { CanCreateSubusersGuard } from './_guards/canCreateSubusers.guard';
import { SuperAdminGuard } from './_guards/superAdmin.guard';
import { AdminOrSuperAdminGuard } from './_guards/adminOrSuperAdmin.guard';
import { ShadowConstraintListComponent } from './components/shadowConstraintList/shadowConstraintList.component';
import { DeviceVersionListComponent } from './components/deviceVersionList/deviceVersionList.component';
import { DataAnalysisPageComponent } from './components/dataAnalysis/dataAnalysisPage.component';
import { CanViewDataAnalysisPageGuard } from './_guards/canViewDataAnalysisPage.guard';
import { NotificationSettingsListComponent } from './components/notificationSettingsList/notificationSettingsList.component';
import { NotificationListComponent } from './components/notificationList/notificationList.component';
import { CanViewNotificationsGuard } from './_guards/canViewNotifications.guard';
import { EndPointListComponent } from './components/endPointList/endPointList.component';

const appRoutes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'sign-up', component: SignupComponent },
    { path: 'cancel', redirectTo: '/login', pathMatch: 'full' },
    { path: 'forgotpassword', component: ForgetpasswordComponent },
    { path: 'updateuserpassword/:id', component: UpdateUserPasswordComponent },

    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'sensor', component: SensorComponent, canActivate: [AdminOrSuperAdminGuard, AuthGuard] },
    { path: 'end-point-view/:id', component: EndPointViewComponent, canActivate: [AuthGuard] },

    { path: 'configuration', component: ConfigurationComponent, canActivate: [AdminGuard, AuthGuard] },
    { path: 'end-points', component: EndPointListComponent, canActivate: [AdminGuard, AuthGuard]},
    { path: 'user', component: UserListComponent, canActivate: [AdminGuard, CanCreateSubusersGuard, AuthGuard] },
    { path: 'sensorlist', component: SensorListComponent, canActivate: [AdminGuard, AuthGuard] },
    { path: 'notifications', component: NotificationListComponent, canActivate: [AdminGuard, CanViewNotificationsGuard, AuthGuard]},
    { path: 'notification-settings', component: NotificationSettingsListComponent, canActivate: [AdminGuard, CanViewNotificationsGuard, AuthGuard]},

    { path: 'dataAnalysis', component: DataAnalysisPageComponent, canActivate: [AdminOrSuperAdminGuard, CanViewDataAnalysisPageGuard, AuthGuard] },

    { path: 'userList', component: PreSignupUserListComponent, canActivate: [SuperAdminGuard, AuthGuard] },
    { path: 'tenants', component: TenantListComponent, canActivate: [SuperAdminGuard, AuthGuard] },
    { path: 'tenantSensorList', component: TenantSensorListComponent, canActivate: [SuperAdminGuard, AuthGuard] },
    { path: 'shadowConstraintList', component: ShadowConstraintListComponent, canActivate: [SuperAdminGuard, AuthGuard] },
    { path: 'deviceVersionList', component: DeviceVersionListComponent, canActivate: [SuperAdminGuard, AuthGuard] },

    { path: 'updateprofile', component: UpdateuserprofileComponent, canActivate: [AuthGuard] },
    { path: 'changepassword', component: ChangeuserpasswordComponent, canActivate: [AuthGuard] },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
