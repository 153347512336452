import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { EndpointService } from './endpoint.service';

@Injectable({
    providedIn: 'root'
})
export class UserPreferencesService extends EndpointService {

    constructor(httpClient: HttpClient,
        private authService: AuthService) {
        super(httpClient);
    }

    setViewBySensor(isViewBySensor: boolean) {
        this.authService.setCurrentUserViewBySensor(isViewBySensor);
        return this.http.post(this.getApiUrl(`UserPreferences/SetViewBySensor?isViewBySensor=${isViewBySensor}`), null)
            .pipe(
                catchError(error => {
                    console.log(error);
                    return of();
                })
            );
    }

    setEndPointId(endPointId: number) {
        this.authService.setCurrentUserEndPoint(endPointId);
        return this.http.post(this.getApiUrl(`UserPreferences/SetEndPointId?endPointId=${endPointId}`), null)
            .pipe(
                catchError(error => {
                    console.log(error);
                    return of();
                })
            );
    }
}
