<h4 class="dialog-title text-center">Create {{customObject.typeName}}</h4>

<hr />

<app-progress-spinner [show]="isFormLoading"></app-progress-spinner>

<div class="mb-4">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>Icon</mat-label>
                    <mat-select [(ngModel)]="customObject.icon" required>
                        <mat-option *ngFor="let icon of icons" [value]="icon">
                            <mat-icon>
                                <i [class]="icon"></i>
                            </mat-icon>
                        </mat-option>
                    </mat-select>
                    <mat-icon *ngIf="customObject.icon" matPrefix>
                        <i [class]="customObject.icon"></i>
                    </mat-icon>
                    <mat-error>Icon is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-9">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput [(ngModel)]="customObject.name" required />
                    <mat-error>Name is required.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-12" *ngIf="!isTypePreSet">
                <mat-form-field>
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="customObject.type" required>
                        <mat-option *ngFor="let customObjectType of customObjectTypes" [value]="customObjectType.id">
                            {{customObjectType.value}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matPrefix class="mr-3">
                        <i class="fas fa-file-alt"></i>
                    </mat-icon>
                    <mat-error>Type is required.</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button class="btn button-primary" mat-button (click)="create()" [class.button-spinner]="isLoading"
            [disabled]="!customObject.name || !customObject.icon || customObject.type === undefined || isLoading">
            Create
        </button>

        <button class="btn button-accent" mat-button mat-dialog-close (click)="onClose()">Cancel</button>
    </div>
</div>
